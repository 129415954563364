import {useMutation} from "@apollo/client"
import {gql} from "~/__generated__"
import {GET_PLAYSTORM_OVERVIEW_QUERY} from "./usePlaystormOverviewQuery"

export const SURVEY_ANSWER_CREATE_MUTATION = gql(`
  mutation SurveyAnswerCreate($input: SurveyAnswerCreateInput!) {
    surveyAnswerCreate(input: $input) {
      surveyAnswer {
        ...SurveyAnswerFragment
      }
    }
  }
`)

const useSurveyAnswerCreate = (playstormId?: string) => {
  return useMutation(SURVEY_ANSWER_CREATE_MUTATION, {
    refetchQueries: playstormId ? [GET_PLAYSTORM_OVERVIEW_QUERY] : [],
    awaitRefetchQueries: true,
  })
}

export default useSurveyAnswerCreate
