import {useField} from "formik"
import {useEffect, useRef, useState} from "react"
import toast from "react-hot-toast"
import useFileUpload from "~/hooks/useFileUpload"
import Button from "./Button"
import {FormikFieldType} from "./FormikField"

const FormikFileUpload: FormikFieldType = ({
  buttonTitle,
  uploadedImageUrl,
  ...props
}) => {
  const [fileName, setFileName] = useState("")
  const [_field, _meta, {setValue}] = useField(props)
  const [isUploading, setIsUploading] = useState(false)
  const hiddenFileInput = useRef<HTMLInputElement>(null)

  const uploadFile = useFileUpload()

  const openUploadDialog = () => hiddenFileInput?.current?.click()

  const onChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsUploading(true)
    const file = e?.target?.files?.[0]
    if (!file) {
      toast.error("Missing file, please try again")
      setIsUploading(false)
      return
    }

    try {
      const signedId = await uploadFile(file)
      setFileName(file.name)
      setValue(signedId)
    } catch (error) {
      console.error(error)
      toast.error("File upload failed, please try again")
    } finally {
      setIsUploading(false)
    }
  }

  useEffect(() => {
    setFileName("")
  }, [uploadedImageUrl])

  return (
    <div className="rounded-md bg-black/40">
      <div className="flex items-center gap-8 px-8 py-10">
        <div>
          <Button
            onClick={openUploadDialog}
            inline={true}
            disabled={isUploading}
            color="outline"
            size="sm"
            rounding="default"
            type="button"
          >
            {buttonTitle || "Upload File"}
          </Button>
        </div>
        <div className="flex flex-1 items-center justify-center">
          {fileName ? (
            <div className="font-mono text-xs">{fileName}</div>
          ) : uploadedImageUrl ? (
            <img
              src={uploadedImageUrl}
              alt={props.name}
              className="max-w-full"
            />
          ) : null}
        </div>
      </div>
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={onChange}
        className="hidden"
      />
    </div>
  )
}

export default FormikFileUpload
