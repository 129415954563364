import {useState} from "react"
import toast from "react-hot-toast"
import invariant from "tiny-invariant"
import {adminTtsPostPath} from "~/paths"
import fetchWrapper from "~/util/fetchWrapper"
import useFileUpload from "./useFileUpload"

const useTTSUpload = () => {
  const [audioBlob, setAudioBlob] = useState<Blob | null>(null)
  const [isGeneratingAudio, setIsGeneratingAudio] = useState(false)

  const uploadFile = useFileUpload()

  const generateAudio = async (text: string) => {
    try {
      setIsGeneratingAudio(true)
      const resp = await fetchWrapper(adminTtsPostPath, {
        method: "POST",
        body: JSON.stringify({text}),
      })
      const blob = await resp.blob()
      setAudioBlob(blob)
      return blob
    } finally {
      setIsGeneratingAudio(false)
    }
  }

  const playGeneratedAudio = (newBlob?: Blob) => {
    const playingBlob = newBlob || audioBlob
    if (playingBlob instanceof Blob) {
      const url = URL.createObjectURL(playingBlob)
      new Audio(url).play()
    } else {
      toast.error("Missing audio")
    }
  }

  const generateAndPlayAudio = async (text: string) => {
    try {
      const newBlob = await generateAudio(text)
      playGeneratedAudio(newBlob)
    } catch (e) {
      console.error(e)
      toast.error("Audio generation failed, please try again")
    }
  }

  const uploadAudio = async (text: string) => {
    let uploadingBlob = audioBlob

    // Generate the audio if user hasn't done it manually
    if (!audioBlob) {
      uploadingBlob = await generateAudio(text)
    }

    invariant(uploadingBlob, "Missing audio")
    const file = new File([uploadingBlob], "audio")
    const signedAudioId = await uploadFile(file)
    return signedAudioId
  }

  const hasGeneratedAudio = !!audioBlob

  return {
    generateAndPlayAudio,
    playGeneratedAudio,
    isGeneratingAudio,
    audioBlob,
    uploadAudio,
    hasGeneratedAudio,
  }
}

export default useTTSUpload
