import {useQuery} from "@apollo/client"
import {getFragmentData, gql} from "~/__generated__"

export const ANSWER_FRAGMENT = gql(`
  fragment AnswerFragment on Answer {
    id
    text
    transcription
    transcriptionSummary
    nextQuestionId
    questionId
    playstormId
    answerType
    questionType
    isFavorite
  }
`)

const GET_ANSWER_QUERY = gql(`
  query Answer($id: ID!) {
    answer(id: $id) {
      ...AnswerFragment
    }
  }
`)

const useAnswerQuery = (id: string) => {
  const {data, loading, error} = useQuery(GET_ANSWER_QUERY, {
    variables: {
      id,
    },
  })

  const answer = getFragmentData(ANSWER_FRAGMENT, data?.answer)

  return {answer, data, loading, error}
}

export default useAnswerQuery
