import clsx from "clsx"
import {useState} from "react"
import {Link, useNavigate} from "react-router-dom"
import LogoLg from "~/images/logo-lg.svg"
import {trackEvent} from "~/util/analytics"
import ConfirmModal from "./ui/ConfirmModal"

const CloseButton = ({
  onClick,
  confirmClose,
  "data-test": dataTest,
  children,
}: {
  onClick?: () => void
  confirmClose?: boolean
  "data-test"?: string
  children: React.ReactNode
}) => {
  if (onClick && confirmClose) {
    return (
      <button onClick={onClick} data-test={dataTest}>
        {children}
      </button>
    )
  }

  return (
    <Link to="/" className="hover:opacity-75" data-test={dataTest}>
      {children}
    </Link>
  )
}

const PlaystormHeader = ({
  canClose = true,
  confirmClose = true,
}: {
  canClose?: boolean
  confirmClose?: boolean
}) => {
  const navigate = useNavigate()
  const [showCloseConfirmation, setShowCloseConfirmation] = useState(false)
  const toggleRevertConfirmation = () => setShowCloseConfirmation(prev => !prev)

  const close = () => {
    navigate("/")
  }

  const onCloseClick = () => {
    if (confirmClose) {
      trackEvent("Click Exit Playstorm")
      toggleRevertConfirmation()
    } else {
      trackEvent("Confirm Exit Playstorm")
      close()
    }
  }

  return (
    <>
      <div
        className={clsx(
          "relative z-10 mx-auto mb-12 mt-10 flex w-full max-w-[476px] items-center justify-center px-4",
          "px-8 md:mb-24"
        )}
      >
        {canClose ? (
          <CloseButton
            onClick={onCloseClick}
            confirmClose={confirmClose}
            data-test="exit-playstorm-logo"
          >
            <LogoLg />
          </CloseButton>
        ) : (
          <Link
            to="/"
            onClick={() => {
              trackEvent("Click Playstorming Logo")
            }}
          >
            <LogoLg />
          </Link>
        )}
      </div>
      {showCloseConfirmation && (
        <ConfirmModal
          onClose={toggleRevertConfirmation}
          onConfirm={close}
          data-test="selection-update-warning"
        >
          Are you sure you want to leave this Playstorm?
        </ConfirmModal>
      )}
    </>
  )
}

export default PlaystormHeader
