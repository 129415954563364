import {Link} from "react-router-dom"
import {TemplateFragmentFragment} from "~/__generated__/graphql"
import {newPlaystormPath} from "~/paths"
import {trackEvent} from "~/util/analytics"
import {ButtonLink} from "./ui/Button"

const CreativeTemplateListItem = ({
  template,
}: {
  template: TemplateFragmentFragment
}) => {
  const path = newPlaystormPath({templateId: template.id})
  return (
    <div className="flex flex-col overflow-hidden rounded bg-white">
      <div>
        <Link to={path}>
          {!!template.heroImageListItemUrl && (
            <img
              src={template.heroImageListItemUrl}
              alt={template.title}
              className="w-full object-cover"
              width={388}
              height={286}
            />
          )}
        </Link>
      </div>
      <div className="flex flex-1 flex-col justify-between p-4">
        <div>
          <div className="mb-2 text-sm font-bold text-gray9">
            <Link to={path}>{template.title}</Link>
          </div>
          <div className="mb-4 min-h-[56px] text-2xs tracking-wide text-gray-400">
            {template.description}
          </div>
        </div>
        <div className="flex items-center">
          <ButtonLink
            to={path}
            size="xs"
            inline={true}
            rounding="default"
            onClick={() => {
              trackEvent("Click Dashboard Start Playstorming", {
                templateId: template.id,
              })
            }}
          >
            Start Playstorming
          </ButtonLink>
          <div className="ml-6 text-2xs text-gray-400">{template.duration}</div>
        </div>
      </div>
    </div>
  )
}

export default CreativeTemplateListItem
