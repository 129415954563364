import {useMutation} from "@apollo/client"
import {gql} from "~/__generated__"

export const QUESTION_UPDATE_MUTATION = gql(`
  mutation QuestionUpdate($input: QuestionUpdateInput!) {
    questionUpdate(input: $input) {
      question {
        ...QuestionFragment
      }
    }
  }
`)

const useQuestionUpdate = () => {
  return useMutation(QUESTION_UPDATE_MUTATION)
}

export default useQuestionUpdate
