import clsx from "clsx"

const PlaystormOverviewActionButton = ({
  onClick,
  disabled,
  transparent,
  isPressed,
  icon,
  "data-test": dataTest,
  children,
}: {
  onClick: () => void
  disabled?: boolean
  transparent?: boolean
  isPressed?: boolean
  icon?: React.ReactNode
  "data-test"?: string
  children: React.ReactNode
}) => (
  <button
    className={clsx(
      "flex min-h-[40px] flex-1 items-center justify-center gap-2 text-2xs font-semibold",
      "sm:text-xs",
      "md:min-h-[80px] md:gap-4 md:text-lg",
      transparent ? "disabled:opacity-40" : "disabled:opacity-75",
      "hover:opacity-75",
      {
        "bg-black/40": isPressed,
      }
    )}
    onClick={onClick}
    disabled={disabled}
    data-test={dataTest}
  >
    {icon && (
      <div className={clsx("-mr-2 scale-50", "md:m-0 md:scale-100")}>
        {icon}
      </div>
    )}
    {children}
  </button>
)

export default PlaystormOverviewActionButton
