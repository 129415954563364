import clsx from "clsx"
import {Field, useFormikContext} from "formik"
import {
  SurveyLocationEnum,
  SurveyOption,
  SurveyQuestionFragmentFragment,
  SurveyQuestionTypeEnum,
} from "~/__generated__/graphql"
import {accountSurveyQuestionPath} from "~/paths"
import {trackEvent} from "~/util/analytics"
import SurveyAllSrc from "../images/survey_all.png"
import SurveyIdeaSrc from "../images/survey_idea.png"
import SurveyOtherSrc from "../images/survey_other.png"
import SurveyOtherBSrc from "../images/survey_other_b.png"
import SurveyProblemSrc from "../images/survey_problem.png"
import SurveyTalkSrc from "../images/survey_talk.png"
import SurveyThoughtsSrc from "../images/survey_thoughts.png"
import SurveyThoughtsBSrc from "../images/survey_thoughts_b.png"
import SurveyWriteSrc from "../images/survey_write.png"
import Button, {ButtonLink} from "./ui/Button"
import FormikField from "./ui/FormikField"

const SURVEY_ICONS: Record<string, string> = {
  survey_write: SurveyWriteSrc,
  survey_talk: SurveyTalkSrc,
  survey_thoughts: SurveyThoughtsSrc,
  survey_idea: SurveyIdeaSrc,
  survey_other: SurveyOtherSrc,
  survey_other_b: SurveyOtherBSrc,
  survey_thoughts_b: SurveyThoughtsBSrc,
  survey_all: SurveyAllSrc,
  survey_problem: SurveyProblemSrc,
}

export enum AnswerType {
  Text,
  Audio,
}

export type SurveyAnswerFormValues = {
  answers: {
    id: string | null
    value: number | null
    surveyOptionId: string | null
    playstormId: string | null
  }[]
}

export const getAnsweredAnswers = (
  values: SurveyAnswerFormValues,
  surveyQuestion: SurveyQuestionFragmentFragment
) =>
  values.answers.filter(answer =>
    answer.surveyOptionId &&
    surveyQuestion.surveyQuestionType === SurveyQuestionTypeEnum.Choice
      ? true
      : !!answer.value
  )

export const getNextSurveyQuestionPath = (
  surveyQuestion: SurveyQuestionFragmentFragment
) => {
  if (
    surveyQuestion.surveyLocation === SurveyLocationEnum.Onboarding &&
    surveyQuestion.nextSurveyQuestionId
  ) {
    return accountSurveyQuestionPath({id: surveyQuestion.nextSurveyQuestionId})
  }

  return surveyQuestion.surveyLocation === SurveyLocationEnum.Onboarding
    ? "/?onboarding=true"
    : "/"
}

// This creates a single SurveyAnswer
const ChoiceSurveyOptionInput = ({
  surveyOption,
  location,
  playstormId,
  playstormTemplateId,
}: {
  surveyOption: SurveyOption
  location: SurveyLocationEnum
  playstormId?: string
  playstormTemplateId?: string
}) => {
  const {values, setFieldValue} = useFormikContext<SurveyAnswerFormValues>()
  const fieldName = "answers[0].surveyOptionId"
  return (
    <label
      className={clsx(
        "flex w-full cursor-pointer flex-col items-center justify-center",
        "hover:opacity-75"
      )}
      data-test="survey-option-label"
      data-test-id={surveyOption.id}
    >
      <div className={clsx("mb-2 flex h-[140px] items-center justify-center")}>
        {surveyOption.iconName && (
          <img
            src={SURVEY_ICONS[surveyOption.iconName]}
            alt=""
            className="w-full"
          />
        )}
      </div>
      <div
        className={clsx(
          "white flex w-full flex-1 flex-col items-center justify-center rounded-lg pb-4 pt-3",
          values.answers[0].surveyOptionId === surveyOption.id
            ? "bg-white"
            : "bg-white/25",
          "md:pb-6 md:pt-5"
        )}
      >
        <div
          className={clsx(
            "px-2 py-1 text-center text-xs text-gray9",
            "md:text-base"
          )}
        >
          {surveyOption.title}
        </div>
        <FormikField
          as="radio"
          skipLabelNesting={true}
          name={fieldName}
          value={surveyOption.id}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setFieldValue(fieldName, e.target.value)
            trackEvent("Answer Survey Question", {
              questionId: surveyOption.id,
              surveyOptionId: surveyOption.id,
              surveyLocation: location,
              playstormId: playstormId || "",
              playstormTemplateId: playstormTemplateId || "",
              type: "choice",
            })
          }}
        />
      </div>
    </label>
  )
}

// This creates a SurveyAnswer for each SurveyOption
const ValueSurveyOptionInput = ({
  surveyOption,
  index,
  location,
  playstormId,
  playstormTemplateId,
}: {
  surveyOption: SurveyOption
  index: number
  location: SurveyLocationEnum
  playstormId?: string
  playstormTemplateId?: string
}) => {
  const {setFieldValue} = useFormikContext<SurveyAnswerFormValues>()
  const valueFieldName = `answers[${index}].value`
  return (
    <div
      className="flex flex-col rounded-lg bg-white px-2 py-6 text-gray9"
      data-test="survey-option-fields"
      data-test-id={surveyOption.id}
    >
      <div className="hidden">
        <Field
          type="text"
          name={`answers[${index}].surveyOptionId`}
          value={surveyOption.id}
        />
      </div>
      <div className="mb-4 text-center font-semibold text-black">
        {surveyOption.title}
      </div>
      <div className="flex justify-between">
        <button
          className={clsx(
            "-mt-1 hidden items-start px-1 text-xs",
            "md:flex md:px-5",
            "hover:opacity-75"
          )}
          onClick={() => setFieldValue(valueFieldName, 1)}
          type="button"
        >
          Low
        </button>
        <div className="flex-1">
          <div
            className={clsx("mb-0 flex flex-col px-2", "xs:px-4", "md:px-3")}
          >
            <Field
              type="range"
              min="1"
              max="10"
              name={valueFieldName}
              className="slider"
            />
          </div>
          <div className="flex justify-around text-xs">
            {[...Array(10).keys()].map(i => (
              <button
                key={i}
                type="button"
                onClick={() => {
                  setFieldValue(valueFieldName, i + 1)
                  trackEvent("Answer Survey Question", {
                    index: index,
                    questionId: surveyOption.id,
                    surveyOptionId: surveyOption.id,
                    surveyLocation: location,
                    playstormId: playstormId || "",
                    playstormTemplateId: playstormTemplateId || "",
                    type: "range",
                  })
                }}
                className="flex-1 cursor-pointer py-3 text-center hover:opacity-75"
              >
                {i + 1}
              </button>
            ))}
          </div>
        </div>
        <button
          className={clsx(
            "-mt-1 hidden items-start px-1 text-xs",
            "md:flex md:px-5",
            "hover:opacity-75"
          )}
          onClick={() => setFieldValue(valueFieldName, 10)}
          type="button"
        >
          High
        </button>
      </div>
    </div>
  )
}

const SurveyAnswerFields = ({
  surveyQuestion,
  playstormId,
  playstormTemplateId,
}: {
  surveyQuestion: SurveyQuestionFragmentFragment
  playstormId?: string
  playstormTemplateId?: string
}) => {
  return (
    <div>
      {surveyQuestion.surveyQuestionType === SurveyQuestionTypeEnum.Choice && (
        <div
          className={clsx(
            "mx-auto flex flex-col justify-evenly gap-4",
            "md:gap-6 md:px-8",
            "lg:flex-row"
          )}
        >
          {surveyQuestion.surveyOptions.nodes.map(surveyOption => (
            <ChoiceSurveyOptionInput
              key={surveyOption.id}
              surveyOption={surveyOption}
              location={surveyQuestion.surveyLocation}
              playstormId={playstormId}
              playstormTemplateId={playstormTemplateId}
            />
          ))}
        </div>
      )}
      <div className="flex flex-1 flex-col gap-5">
        {surveyQuestion.surveyQuestionType === SurveyQuestionTypeEnum.Value &&
          surveyQuestion.surveyOptions.nodes.map((surveyOption, index) => {
            return (
              <ValueSurveyOptionInput
                key={surveyOption.id}
                surveyOption={surveyOption}
                index={index}
                location={surveyQuestion.surveyLocation}
                playstormId={playstormId}
                playstormTemplateId={playstormTemplateId}
              />
            )
          })}
      </div>
      <div className="mt-10 flex flex-col items-center">
        {surveyQuestion.surveyLocation === SurveyLocationEnum.Onboarding ? (
          <div className="flex flex-col items-center justify-center gap-2">
            <Button
              type="submit"
              rounding="full"
              inline={true}
              onClick={() => {
                trackEvent("Click Continue Survey Question", {
                  position: surveyQuestion.position?.toString() || "",
                  question: surveyQuestion.title,
                  questionId: surveyQuestion.id,
                  surveyLocation: surveyQuestion.surveyLocation,
                  playstormId: playstormId || "",
                  playstormTemplateId: playstormTemplateId || "",
                })
              }}
            >
              Continue
            </Button>
            <ButtonLink
              to={getNextSurveyQuestionPath(surveyQuestion)}
              color="ghost"
              inline={true}
              onClick={() => {
                trackEvent("Click Skip Survey Question", {
                  position: surveyQuestion.position?.toString() || "",
                  questionId: surveyQuestion.id,
                  surveyLocation: surveyQuestion.surveyLocation,
                  playstormId: playstormId || "",
                })
              }}
            >
              Skip
            </ButtonLink>
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center gap-2">
            <Button
              type="submit"
              rounding="full"
              inline={true}
              onClick={() => {
                trackEvent("Click Complete Post Playstorm Survey", {
                  position: surveyQuestion.position?.toString() || "",
                  questionId: surveyQuestion.id,
                  surveyLocation: surveyQuestion.surveyLocation,
                  playstormId: playstormId || "",
                  playstormTemplateId: playstormTemplateId || "",
                })
              }}
            >
              Back to Dashboard
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

export default SurveyAnswerFields
