import {PlaystormStepAnswerFragmentFragment} from "~/__generated__/graphql"
import {
  AnswerControlButtonsContainer,
  ContinueButton,
  ContinueRecordingButton,
  NextButton,
  RecordButton,
  RestartRecordingButton,
  StopRecordingButton,
  TypeButton,
} from "./AnswerControlButtons"
import {useRecordingMachine} from "./RecordingMachine"

const AnswerRecorderControls = ({
  answer,
  start,
  pause,
  resume,
  stop,
  reset,
  toggleAnswerType,
}: {
  answer: PlaystormStepAnswerFragmentFragment
  start: () => void
  pause: () => void
  resume: () => void
  stop: () => void
  reset: () => void
  toggleAnswerType: () => void
}) => {
  const {machine} = useRecordingMachine()
  const [state] = machine

  return (
    <div>
      <AnswerControlButtonsContainer>
        {state.can({type: "RESUME"}) ? (
          <ContinueRecordingButton onClick={resume} />
        ) : (
          <TypeButton
            onClick={toggleAnswerType}
            disabled={!state.matches("idle")}
          />
        )}

        {state.matches("idle") ? (
          <RecordButton onClick={start} />
        ) : state.matches("recording") ? (
          <StopRecordingButton onClick={pause} />
        ) : state.matches("paused") ? (
          <ContinueButton type="button" onClick={stop} />
        ) : (
          <div className="animate-pulse">
            <ContinueButton type="button" disabled={true} />
          </div>
        )}

        {state.can({type: "RESET"}) ? (
          <RestartRecordingButton
            onClick={reset}
            disabled={!state.matches("paused")}
          />
        ) : (
          <NextButton answer={answer} disabled={!state.matches("idle")} />
        )}
      </AnswerControlButtonsContainer>
    </div>
  )
}

export default AnswerRecorderControls
