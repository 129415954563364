import * as Sentry from "@sentry/react"
import React, {StrictMode} from "react"
import {createRoot} from "react-dom/client"
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom"
import invariant from "tiny-invariant"
import {App} from "~/app"
import env from "~/env"

const rootEl = document.getElementById("react-root")
invariant(rootEl, "missing root")

if (env.SENTRY_DSN) {
  Sentry.init({
    dsn: env.SENTRY_DSN,
    tracesSampleRate: 0,
    environment: env.ENVIRONMENT,
    enabled: env.ENABLE_SENTRY === "true",
    integrations: [
      new Sentry.BrowserTracing({
        // See docs for support of different versions of variation of react router
        // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
      new Sentry.Replay({
        networkDetailAllowUrls: ["/graphql"],
      }),
    ],
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
  })
}

const root = createRoot(rootEl)
root.render(
  <StrictMode>
    <App />
  </StrictMode>
)
