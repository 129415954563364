import {useQuery} from "@apollo/client"
import {getFragmentData, gql} from "~/__generated__"
import {QUESTION_FRAGMENT} from "./useQuestionQuery"

export const GET_QUESTIONS_QUERY = gql(`
  query Questions($templateId: ID!) {
    questions(templateId: $templateId, first: 500) {
      nodes {
        ...QuestionFragment
      }
    }
  }
`)

const useQuestionsQuery = (templateId: string) => {
  const {data, loading, error} = useQuery(GET_QUESTIONS_QUERY, {
    variables: {
      templateId,
    },
  })

  const questions = data?.questions.nodes?.map(question =>
    getFragmentData(QUESTION_FRAGMENT, question)
  )

  return {questions, data, loading, error}
}

export default useQuestionsQuery
