import {useMutation} from "@apollo/client"
import invariant from "tiny-invariant"
import {getFragmentData, gql} from "~/__generated__"
import {QUESTION_FRAGMENT} from "./useQuestionQuery"

export const QUESTION_CREATE_MUTATION = gql(`
  mutation QuestionCreate($input: QuestionCreateInput!) {
    questionCreate(input: $input) {
      question {
        ...QuestionFragment
      }
    }
  }
`)

const useQuestionCreate = () => {
  return useMutation(QUESTION_CREATE_MUTATION, {
    update(cache, {data}) {
      invariant(
        data?.questionCreate?.question,
        "Missing question create response"
      )
      cache.modify({
        fields: {
          questions(existing = {}) {
            const question = getFragmentData(
              QUESTION_FRAGMENT,
              data.questionCreate?.question
            )
            const newQuestionRef = cache.writeFragment({
              data: question,
              fragment: QUESTION_FRAGMENT,
            })
            return {
              ...existing,
              nodes: [...existing.nodes, newQuestionRef],
            }
          },
        },
      })
    },
  })
}

export default useQuestionCreate
