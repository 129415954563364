import {Form, Formik, FormikHelpers, FormikProps} from "formik"
import toast from "react-hot-toast"
import * as Yup from "yup"
import {
  AdminUserFragmentFragment,
  ViewerFragmentFragment,
} from "~/__generated__/graphql"
import Button, {ButtonLink} from "~/components/ui/Button"
import FormikField from "~/components/ui/FormikField"
import useUserUpdate from "~/hooks/useUserUpdate"
import {accountPath} from "~/paths"
import {displayErrors} from "~/util/validations"
import FieldGroup from "./ui/FieldGroup"
import {gql} from "~/__generated__"
import {useQuery} from "@apollo/client"

type UserFormFields = {
  firstName: string
  lastName: string
  email: string
  organization: string
  organizationId: string
  organizationRole: string
}

const validationSchema = Yup.object({
  email: Yup.string().email("Email is invalid").required("Email is required"),
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  organization: Yup.string().required("Organization is required"),
  organizationRole: Yup.string().required("Role is required"),
})

const query = gql(/* GraphQL */ `
  query EditAccountFormQuery {
    organizations(first: 1000) {
      edges {
        node {
          value: id
          label: name
        }
      }
    }
  }
`)

const EditAccountForm = ({
  user,
}: {
  user: ViewerFragmentFragment | AdminUserFragmentFragment
}) => {
  const [mutate] = useUserUpdate()
  const result = useQuery(query)
  const organizations =
    result.data?.organizations.edges.map(edge => edge.node) ?? []

  const onSubmit = async (
    values: UserFormFields,
    {setFieldError}: FormikHelpers<UserFormFields>
  ) => {
    try {
      await mutate({
        variables: {
          input: {
            id: user.id,
            userInput: {
              ...values,
              organizationId:
                values.organizationId === "" ? null : values.organizationId,
            },
          },
        },
      })

      toast.success("Account details updated!")
    } catch (error: any) {
      console.error(error)
      displayErrors(error?.graphQLErrors, setFieldError)
    }
  }

  return (
    <Formik
      initialValues={{
        firstName: user.firstName || "",
        lastName: user.lastName || "",
        email: user.email || "",
        organization: user.organization || "",
        organizationRole: user.organizationRole || "",
        organizationId: user.structuredOrganization
          ? user.structuredOrganization.id
          : "",
      }}
      validationSchema={validationSchema}
      validateOnBlur={false}
      onSubmit={onSubmit}
    >
      {({isSubmitting}: FormikProps<UserFormFields>) => (
        <Form data-test="edit-account-form">
          <FieldGroup row={true}>
            <FormikField
              name="firstName"
              type="text"
              label="First Name"
              autoFocus={true}
              light={true}
            />
            <FormikField
              name="lastName"
              type="text"
              label="Last Name"
              light={true}
            />
          </FieldGroup>
          <FieldGroup>
            <FormikField
              name="email"
              type="email"
              label="Email Address"
              light={true}
            />
            <FormikField
              name="organizationId"
              as="select"
              label="Organization"
              options={[{value: "", label: "None"}, ...organizations]}
            />
            <FormikField
              name="organization"
              type="text"
              label="Organization (Legacy)"
              light={true}
            />
            <FormikField
              name="organizationRole"
              type="text"
              label="Role"
              light={true}
            />
            <Button type="submit" disabled={isSubmitting}>
              Save
            </Button>
            <ButtonLink to={accountPath} disabled={isSubmitting} color="ghost">
              Cancel
            </ButtonLink>
          </FieldGroup>
        </Form>
      )}
    </Formik>
  )
}

export default EditAccountForm
