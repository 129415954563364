import {Helmet} from "react-helmet"
import ResetPasswordForm from "~/components/ResetPasswordForm"
import Container from "~/components/ui/Container"

const ResetPasswordScreen = () => {
  return (
    <Container className="pt-28">
      <Helmet>
        <title>Reset Your Password</title>
      </Helmet>
      <ResetPasswordForm />
    </Container>
  )
}

export default ResetPasswordScreen
