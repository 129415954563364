import {useParams} from "react-router-dom"
import invariant from "tiny-invariant"
import {getFragmentData} from "~/__generated__"
import AnswerCreateForm from "~/components/AnswerCreateForm"
import AnswerEditForm from "~/components/AnswerEditForm"
import PlaystormHeader from "~/components/PlaystormHeader"
import Container from "~/components/ui/Container"
import Error from "~/components/ui/Error"
import usePlaystormStepQuery, {
  PLAYSTORM_STEP_ANSWER_FRAGMENT,
} from "~/hooks/usePlaystormStepQuery"
import useTrackPage from "~/hooks/useTrackPage"

const CreativePlaystormQuestionScreen = () => {
  const {id, questionId} = useParams()
  invariant(id, "Expected playstorm id to be defined")
  invariant(questionId, "Expected question id to be defined")
  useTrackPage("Playstorm Question Page")

  const {playstormStep, loading, error} = usePlaystormStepQuery(id, questionId)

  if (loading) return <div className="min-h-full" />
  if (error || !playstormStep)
    return <Error message="Error loading playstorm" />
  invariant(playstormStep, "Expected playstorm step to be defined")

  const answer = getFragmentData(
    PLAYSTORM_STEP_ANSWER_FRAGMENT,
    playstormStep.answer
  )

  return (
    <Container size="md" className="pb-20">
      <PlaystormHeader />
      {answer.id ? (
        <AnswerEditForm answer={answer} />
      ) : (
        <AnswerCreateForm answer={answer} />
      )}
    </Container>
  )
}

export default CreativePlaystormQuestionScreen
