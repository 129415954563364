import {useQuery} from "@apollo/client"
import {getFragmentData, gql} from "~/__generated__"

export const PLAYSTORM_STEP_QUESTION_FRAGMENT = gql(`
  fragment PlaystormStepQuestionFragment on Question {
    id
    text
    questionType
    audioUrl
    position {
      index
      total
    }
  }
`)

export const PLAYSTORM_STEP_ANSWER_FRAGMENT = gql(`
  fragment PlaystormStepAnswerFragment on Answer {
    id
    text
    transcription
    transcriptionSummary
    nextQuestionId
    questionId
    playstormId
    answerType
    questionType
    recordingUrl
    question {
      ...QuestionFragment
    }
    affirmationMessage {
      ...MessageFragment
    }
    strengtheningMessages {
      nodes {
        ...MessageFragment
      }
    }
    playstorm {
      ...PlaystormFragment
    }
    question {
      ...PlaystormStepQuestionFragment
    }
    isFavorite
  }
`)

export const PLAYSTORM_STEP_FRAGMENT = gql(`
  fragment PlaystormStepFragment on PlaystormStep {
    answer {
      ...PlaystormStepAnswerFragment
    }
  }
`)

const GET_PLAYSTORM_STEP_QUERY = gql(`
  query PlaystormStep($playstormId: ID!, $questionId: ID!) {
    playstormStep(playstormId: $playstormId, questionId: $questionId) {
      ...PlaystormStepFragment
    }
  }
`)

const usePlaystormStepQuery = (playstormId: string, questionId: string) => {
  const {data, loading, error} = useQuery(GET_PLAYSTORM_STEP_QUERY, {
    variables: {
      playstormId,
      questionId,
    },
  })

  const playstormStep = getFragmentData(
    PLAYSTORM_STEP_FRAGMENT,
    data?.playstormStep
  )

  return {playstormStep, data, loading, error}
}

export default usePlaystormStepQuery
