import {useMutation} from "@apollo/client"
import {gql} from "~/__generated__"

export const TOGGLE_USER_ARCHIVED_MUTATION = gql(`
  mutation ToggleUserArchived($input: ToggleUserArchivedInput!) {
    toggleUserArchived(input: $input) {
      user {
        ...AdminUserFragment
      }
    }
  }
`)

const useToggleUserArchived = () => {
  return useMutation(TOGGLE_USER_ARCHIVED_MUTATION)
}

export default useToggleUserArchived
