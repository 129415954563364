import {useState} from "react"

const useResetKey = () => {
  const [resetKey, setResetKey] = useState(0)
  const reset = () => {
    setResetKey(prev => prev + 1)
  }
  return {resetKey, reset}
}

export default useResetKey
