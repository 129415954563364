import {useMutation} from "@apollo/client"
import {gql} from "~/__generated__"

export const ANSWER_UPDATE_MUTATION = gql(`
  mutation AnswerUpdate($input: AnswerUpdateInput!) {
    answerUpdate(input: $input) {
      answer {
        ...AnswerFragment
      }
    }
  }
`)

const useAnswerUpdate = () => {
  return useMutation(ANSWER_UPDATE_MUTATION)
}

export default useAnswerUpdate
