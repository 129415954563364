import * as amplitude from "@amplitude/analytics-browser"
import {ApolloProvider} from "@apollo/client"
import {useEffect} from "react"
import {Toaster} from "react-hot-toast"
import {RouterProvider} from "react-router-dom"
import env from "~/env"
import {ViewerProvider} from "./contexts/ViewerContext"
import router from "./router"
import apolloClient from "./util/apolloClient"

export const App = () => {
  useEffect(() => {
    if (env.AMPLITUDE_ENABLED === "true") {
      amplitude.init(env.AMPLITUDE_API_KEY, {
        logLevel:
          env.ENVIRONMENT === "development"
            ? amplitude.Types.LogLevel.Debug
            : undefined,
        defaultTracking: true,
      })
    }
  }, [])

  return (
    <ApolloProvider client={apolloClient}>
      <ViewerProvider>
        <Toaster />
        <RouterProvider router={router} />
      </ViewerProvider>
    </ApolloProvider>
  )
}
