import Markdown from "react-markdown"
import {Link} from "react-router-dom"
import {
  AnswerFavoriteFragmentFragment,
  QuestionTypeEnum,
} from "~/__generated__/graphql"
import {playstormPath} from "~/paths"
import {trackEvent} from "~/util/analytics"

const CreativeAnswerFavoriteListItem = ({
  answerFavorite,
}: {
  answerFavorite: AnswerFavoriteFragmentFragment
}) => {
  const answer = answerFavorite.answer
  const path = `${playstormPath({id: answer.playstormId})}#${answer.id}`
  const text =
    answer.questionType === QuestionTypeEnum.Strengthening
      ? answer.strengtheningMessages.nodes[0].text
      : answer.response

  return (
    <Link
      to={path}
      className="group relative"
      onClick={() => {
        trackEvent("Click Favorite Item", {
          id: answerFavorite.id!,
          answerId: answerFavorite.answerId,
        })
      }}
    >
      <div className="flex h-full flex-col rounded-lg bg-white text-xs">
        <div className="flex max-h-[318px] flex-col p-6 leading-5">
          {answer.playstorm?.title && (
            <div className="mb-2 text-base font-bold text-brand-blue">
              {answer.playstorm?.title}
            </div>
          )}
          <div className="markdown-content markdown-content-compact line-clamp-10 whitespace-pre-line">
            <Markdown>{text}</Markdown>
          </div>
          <div className="mt-5 text-xs">
            <span className="font-semibold">Date Favorited:</span>{" "}
            {new Intl.DateTimeFormat("en", {
              dateStyle: "long",
            }).format(new Date(answerFavorite.updatedAt))}
          </div>
        </div>
        <div className="mt-auto flex h-[65px] w-full flex-col items-center justify-center rounded-b-lg bg-brand-blue text-xs font-bold text-white">
          View
        </div>
      </div>
    </Link>
  )
}

export default CreativeAnswerFavoriteListItem
