import clsx from "clsx"
import {AnswerFavoriteFragmentFragment} from "~/__generated__/graphql"
import CreativeAnswerFavoriteListItem from "./CreativeAnswerFavoriteListItem"
import Button from "./ui/Button"

const CreativeAnswerFavoritesList = ({
  answerFavorites,
  fetchNextPage,
  hasNextPage,
  loading,
}: {
  answerFavorites: AnswerFavoriteFragmentFragment[]
  fetchNextPage: () => void
  hasNextPage: boolean
  loading: boolean
}) => {
  return (
    <div>
      {answerFavorites.length > 0 ? (
        <div
          className={clsx(
            "flex flex-col gap-6",
            "md:grid md:grid-cols-2",
            "lg:grid-cols-3"
          )}
        >
          {answerFavorites.map(answerFavorite => (
            <CreativeAnswerFavoriteListItem
              key={answerFavorite.id}
              answerFavorite={answerFavorite}
            />
          ))}
        </div>
      ) : (
        <div className="my-14 pt-4 text-center">
          Pin your favorite ideas once you create your first Playstorm.
        </div>
      )}
      {hasNextPage && (
        <div className="mt-6 flex justify-center">
          <Button
            onClick={fetchNextPage}
            color="ghost"
            inline={true}
            disabled={loading}
          >
            Load more
          </Button>
        </div>
      )}
    </div>
  )
}

export default CreativeAnswerFavoritesList
