import clsx from "clsx"

const HR = ({dark}: {dark?: boolean}) => {
  return (
    <hr
      className={clsx("my-2", dark ? "border-black/10" : "border-white/40")}
    />
  )
}

export default HR
