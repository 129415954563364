import {Form, Formik, FormikHelpers, FormikProps} from "formik"
import {toast} from "react-hot-toast"
import * as Yup from "yup"
import {accountPath, signUpPostPath} from "~/paths"
import fetchWrapper from "~/util/fetchWrapper"

import {useNavigate} from "react-router-dom"
import {trackEvent} from "~/util/analytics"
import {displayActiveRecordErrors} from "~/util/validations"
import Button, {ButtonLink} from "./ui/Button"
import FieldGroup from "./ui/FieldGroup"
import FormikField from "./ui/FormikField"

type Values = {
  currentPassword: string
  password: string
  passwordConfirmation: string
}

const initialValues = {
  currentPassword: "",
  password: "",
  passwordConfirmation: "",
}

const validationSchema = Yup.object({
  currentPassword: Yup.string().required("Your current password is required"),
  password: Yup.string()
    .min(6, "New password must be at least 6 characters")
    .required("A new password is required"),
  passwordConfirmation: Yup.string()
    .required("A password confirmation is required")
    .oneOf([Yup.ref("password"), ""], "Passwords must match"),
})

const EditPasswordForm = () => {
  const navigate = useNavigate()

  const onSubmit = async (
    values: Values,
    {setFieldError, resetForm}: FormikHelpers<Values>
  ) => {
    trackEvent("Submit Edit Password Form")

    const params = {
      user: {
        current_password: values.currentPassword,
        password: values.password,
        password_confirmation: values.passwordConfirmation,
      },
    }
    try {
      const response = await fetchWrapper(signUpPostPath, {
        method: "PUT",
        body: JSON.stringify(params),
      })
      if (response.ok) {
        toast.success("Your password has been updated!")
        navigate(accountPath)
        resetForm()
      } else {
        const error = await response.json()
        const validationErrors = error?.errors
        if (validationErrors) {
          displayActiveRecordErrors(validationErrors, setFieldError)
        } else {
          const message =
            error?.response?.data?.error ||
            "Password update failed, please try again"
          toast.error(message)
        }
      }
    } catch (error) {
      console.error(error)
      const message = "Password update failed, please try again"
      toast.error(message)
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({isSubmitting}: FormikProps<Values>) => (
        <Form data-test="edit-password-form">
          <FieldGroup>
            <FormikField
              name="currentPassword"
              type="password"
              label="Current Password"
              light={true}
            />
            <FormikField
              name="password"
              type="password"
              label="Create New Password"
              light={true}
            />
            <FormikField
              name="passwordConfirmation"
              type="password"
              label="Confirm New Password"
              light={true}
            />
            <Button type="submit" disabled={isSubmitting}>
              Save
            </Button>
            <ButtonLink to={accountPath} disabled={isSubmitting} color="ghost">
              Cancel
            </ButtonLink>
          </FieldGroup>
        </Form>
      )}
    </Formik>
  )
}

export default EditPasswordForm
