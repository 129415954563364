import * as Sentry from "@sentry/react"
import {Form, Formik, FormikProps} from "formik"
import {toast} from "react-hot-toast"
import * as Yup from "yup"
import Button from "~/components/ui/Button"
import FormikField from "~/components/ui/FormikField"
import {H1} from "~/components/ui/Headings"
import Link from "~/components/ui/Link"
import {createAccountPath, forgotPasswordPath, signInPostPath} from "~/paths"
import {setUserId, trackEvent} from "~/util/analytics"
import fetchWrapper from "~/util/fetchWrapper"
import FormLogoHeader from "./FormLogoHeader"
import Panel from "./ui/Panel"

type Values = {
  email: string
  password: string
}

const initialValues = {
  email: "",
  password: "",
}

const validationSchema = Yup.object({
  email: Yup.string().email("Email is invalid").required("Email is required"),
  password: Yup.string().required("A password is required"),
})

const onSubmit = async (values: Values) => {
  trackEvent("Submit Login Form")

  const params = {user: values}
  try {
    const response = await fetchWrapper(signInPostPath, {
      method: "POST",
      body: JSON.stringify(params),
    })
    const data = await response.json()
    if (data?.userId) {
      setUserId(data.userId)
      trackEvent("Logged In")
      window.location.replace("/")
      Sentry.setUser({id: data.userId})
    } else {
      const message = data?.error || "Sign in failed, please try again"
      toast.error(message)
    }
  } catch (error) {
    console.error(error)
    const message = "Sign in failed, please try again"
    toast.error(message)
  }
}

const LogInForm = () => {
  return (
    <div className="flex w-full flex-col items-center">
      <FormLogoHeader />
      <Panel size="sm">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnBlur={false}
          onSubmit={onSubmit}
        >
          {({isSubmitting}: FormikProps<Values>) => (
            <Form id="login-form" name="login-form">
              <div className="flex flex-col gap-10">
                <H1 center={true} id="sign-in">
                  Log in to account
                </H1>
                <div className="flex flex-col gap-2">
                  <FormikField
                    name="email"
                    type="email"
                    label="Email Address"
                    autoFocus={true}
                  />
                  <FormikField
                    name="password"
                    type="password"
                    label="Password"
                  />
                </div>
                <div className="flex justify-center">
                  <Button type="submit" disabled={isSubmitting} inline={true}>
                    Log In
                  </Button>
                </div>
                <div className="mb-1 mt-2 flex flex-col items-center justify-between px-6 sm:flex-row sm:gap-5">
                  <Link
                    to={forgotPasswordPath}
                    onClick={() => trackEvent("Click Forgot Password")}
                  >
                    Forgot Password?
                  </Link>
                  <Link to={createAccountPath}>Create an account</Link>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Panel>
    </div>
  )
}

export default LogInForm
