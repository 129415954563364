import {Link} from "react-router-dom"
import {CreativePlaystormIndexFragmentFragment} from "~/__generated__/graphql"
import {playstormPath} from "~/paths"
import {trackEvent} from "~/util/analytics"
import {dateTimeShort} from "~/util/dates"
import AdminActions, {AdminAction} from "./AdminActions"
import Button from "./ui/Button"
import {TD, TH, Table} from "./ui/Table"

const playstormStatus = (completedAt?: string) =>
  completedAt ? "Complete" : "Incomplete"

const CreativePlaystormsTable = ({
  playstorms,
  fetchNextPage,
  hasNextPage,
  loading,
}: {
  playstorms: CreativePlaystormIndexFragmentFragment[]
  fetchNextPage: () => void
  hasNextPage: boolean
  loading: boolean
}) => {
  const trackPlaystormClick = (id: string) => {
    trackEvent("Click Playstorm", {id})
  }
  return (
    <div className="flex w-full flex-1 flex-col rounded-2xl bg-white p-2">
      <Table>
        <thead>
          <tr>
            <TH className="min-w-[145px]">Playstorm Title</TH>
            <TH className="min-w-[145px]">Template Title</TH>
            <TH className="min-w-[145px]">Created</TH>
            <TH className="min-w-[145px]">Updated</TH>
            <TH>Status</TH>
            <TH align="right">Actions</TH>
          </tr>
        </thead>
        <tbody>
          {playstorms.map((playstorm, index) => {
            const first = index === 0
            const path = playstormPath({id: playstorm.id})
            return (
              <tr key={playstorm.id} data-test-id={playstorm.id}>
                <TD first={first}>
                  <Link
                    to={path}
                    onClick={() => trackPlaystormClick(playstorm.id)}
                  >
                    {playstorm.title}
                  </Link>
                </TD>
                <TD first={first}>{playstorm.templateTitle}</TD>
                <TD first={first}>
                  <Link
                    to={path}
                    onClick={() => trackPlaystormClick(playstorm.id)}
                  >
                    {dateTimeShort(new Date(playstorm.createdAt))}
                  </Link>
                </TD>
                <TD first={first}>
                  <Link
                    to={path}
                    onClick={() => trackPlaystormClick(playstorm.id)}
                  >
                    {dateTimeShort(new Date(playstorm.updatedAt))}
                  </Link>
                </TD>
                <TD first={first}>
                  <Link
                    to={path}
                    onClick={() => trackPlaystormClick(playstorm.id)}
                  >
                    {playstormStatus(playstorm.completedAt)}
                  </Link>
                </TD>
                <TD align="right" first={first}>
                  <AdminActions>
                    <AdminAction
                      to={path}
                      onClick={() => trackPlaystormClick(playstorm.id)}
                    >
                      {playstorm.completedAt ? "View" : "Resume Playstorm"}
                    </AdminAction>
                  </AdminActions>
                </TD>
              </tr>
            )
          })}
        </tbody>
      </Table>
      {hasNextPage && (
        <div className="my-4 flex justify-center">
          <Button
            onClick={fetchNextPage}
            color="ghost"
            inline={true}
            disabled={loading}
          >
            Load more
          </Button>
        </div>
      )}
    </div>
  )
}

export default CreativePlaystormsTable
