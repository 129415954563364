import {useParams} from "react-router-dom"
import invariant from "tiny-invariant"
import FormLogoHeader from "~/components/FormLogoHeader"
import SurveyAnswerForm from "~/components/SurveyAnswerForm"
import Container from "~/components/ui/Container"
import Error from "~/components/ui/Error"
import Loading from "~/components/ui/Loading"
import Panel from "~/components/ui/Panel"
import useSurveyQuestionQuery from "~/hooks/useSurveyQuestionQuery"

const AccountSurveyQuestionScreen = () => {
  const {id} = useParams()
  invariant(id, "expected id")
  const {surveyQuestion, loading, error} = useSurveyQuestionQuery(id)

  if (loading) return <Loading />
  if (error || !surveyQuestion)
    return <Error message="Error loading survey question" />

  return (
    <Container className="pt-20">
      <FormLogoHeader />
      <Panel size="lg">
        <SurveyAnswerForm
          surveyQuestion={surveyQuestion}
          title="Personal Preferences"
        />
      </Panel>
    </Container>
  )
}

export default AccountSurveyQuestionScreen
