import {useMutation} from "@apollo/client"
import invariant from "tiny-invariant"
import {getFragmentData, gql} from "~/__generated__"
import {PLAYSTORM_FRAGMENT} from "./usePlaystormQuery"

export const PLAYSTORM_CREATE_MUTATION = gql(`
  mutation PlaystormCreate($input: PlaystormCreateInput!) {
    playstormCreate(input: $input) {
      playstorm {
        ...PlaystormFragment
      }
      firstQuestionId
    }
  }
`)

const usePlaystormCreate = () => {
  return useMutation(PLAYSTORM_CREATE_MUTATION, {
    update(cache, {data}) {
      invariant(
        data?.playstormCreate?.playstorm,
        "Missing playstorm create response"
      )
      cache.modify({
        fields: {
          playstorms(existing = {}) {
            const playstorm = getFragmentData(
              PLAYSTORM_FRAGMENT,
              data.playstormCreate?.playstorm
            )
            const newPlaystormRef = cache.writeFragment({
              data: playstorm,
              fragment: PLAYSTORM_FRAGMENT,
            })
            return {
              ...existing,
              nodes: [newPlaystormRef, ...existing.nodes],
            }
          },
        },
      })
    },
  })
}

export default usePlaystormCreate
