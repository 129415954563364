import {Helmet} from "react-helmet"
import {Link, useParams} from "react-router-dom"
import invariant from "tiny-invariant"
import {getFragmentData} from "~/__generated__"
import AdminPlaystormAnswer from "~/components/AdminPlaystormAnswer"
import AdminPostPlaystormSurveyAnswers from "~/components/AdminPostPlaystormSurveyAnswers"
import Container from "~/components/ui/Container"
import Error from "~/components/ui/Error"
import {H1} from "~/components/ui/Headings"
import Loading from "~/components/ui/Loading"
import useAdminPlaystormQuery, {
  ADMIN_ANSWER_FRAGMENT,
} from "~/hooks/useAdminPlaystormQuery"
import {SURVEY_ANSWER_FRAGMENT} from "~/hooks/useSurveyAnswersQuery"
import {adminEditTemplatePath, adminPlaystormsPath} from "~/paths"

const AdminPlaystormScreen = () => {
  const {id} = useParams()
  invariant(id, "Expected id to be defined")

  const {playstorm, loading, error} = useAdminPlaystormQuery(id)

  if (loading && !playstorm) return <Loading />
  if (error || !playstorm) return <Error message="Error loading playstorm" />

  const answers = playstorm.answers.nodes.map(answer =>
    getFragmentData(ADMIN_ANSWER_FRAGMENT, answer)
  )

  const postPlaystormSurveyAnswers =
    playstorm.postPlaystormSurveyAnswers.nodes.map(playstormAnswer =>
      getFragmentData(SURVEY_ANSWER_FRAGMENT, playstormAnswer)
    )

  const title = playstorm.title

  return (
    <Container size="full" centered={false}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="mb-4 flex items-center">
        <Link to={adminPlaystormsPath} className="mr-2 text-xl">
          {"<"}
        </Link>
        <H1>{title}</H1>
      </div>
      <div>
        Template:{" "}
        <Link to={adminEditTemplatePath({id: playstorm.templateId})}>
          {playstorm.templateTitle}
        </Link>
      </div>
      <div>
        Completed:{" "}
        {playstorm.completedAt
          ? `${new Date(playstorm.completedAt).toLocaleDateString()}`
          : "-"}
      </div>
      <div>Name: {playstorm.user.name}</div>
      <div>Email: {playstorm.user.email}</div>
      <div>Total ideas: {playstorm.listItemCount}</div>

      <div className="mt-10 flex flex-col gap-4">
        {answers.map(answer => (
          <AdminPlaystormAnswer key={answer.id} answer={answer} />
        ))}
        <AdminPostPlaystormSurveyAnswers
          surveyAnswers={postPlaystormSurveyAnswers}
        />
      </div>
    </Container>
  )
}

export default AdminPlaystormScreen
