import {Formik, FormikHelpers} from "formik"
import toast from "react-hot-toast"
import invariant from "tiny-invariant"
import {getFragmentData} from "~/__generated__"
import {TemplateFragmentFragment} from "~/__generated__/graphql"
import {TEMPLATE_FRAGMENT} from "~/hooks/useTemplateQuery"
import useTemplateUpdate from "~/hooks/useTemplateUpdate"
import {displayErrors} from "~/util/validations"
import TemplateFields, {
  TemplateFormValues,
  templateValidationSchema,
} from "./TemplateFields"

const TemplateUpdateForm = ({
  template,
}: {
  template: TemplateFragmentFragment
}) => {
  const [mutate] = useTemplateUpdate()

  const initialValues: TemplateFormValues = {
    title: template?.title || "",
    description: template?.description || "",
    duration: template?.duration || "",
    status: template?.status?.toString() || "",
    heroImage: "",
    visibility: template.visibility,
    organizationIds: template.organizations.map(org => org.id),
  }

  const updateTemplate = async (
    values: typeof initialValues,
    {setFieldError, resetForm}: FormikHelpers<TemplateFormValues>
  ) => {
    console.log({values})
    try {
      const resp = await mutate({
        variables: {
          input: {
            id: template.id,
            templateInput: {
              title: values.title,
              description: values.description,
              duration: values.duration,
              status: values.status,
              heroImage: values.heroImage || undefined,
              visibility: values.visibility,
              organizationIds: values.organizationIds,
            },
          },
        },
      })
      const updatedTemplate = getFragmentData(
        TEMPLATE_FRAGMENT,
        resp?.data?.templateUpdate.template
      )
      invariant(updatedTemplate, "Expected template")
      resetForm({
        values: {
          title: updatedTemplate.title,
          description: updatedTemplate.description,
          duration: updatedTemplate.duration,
          status: updatedTemplate.status,
          heroImage: "",
          visibility: updatedTemplate.visibility,
          organizationIds: values.organizationIds,
        },
      })
      toast.success("Template updated successfully!")
    } catch (error: any) {
      displayErrors(error?.graphQLErrors, setFieldError)
    }
  }

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={templateValidationSchema}
        validateOnBlur={false}
        onSubmit={updateTemplate}
      >
        <TemplateFields template={template} />
      </Formik>
    </div>
  )
}

export default TemplateUpdateForm
