import clsx from "clsx"
import {Link, Outlet, matchPath, useLocation} from "react-router-dom"
import AdminMenu from "~/components/AdminMenu"
import CreativeMenu from "~/components/CreativeMenu"
import MobileNav from "~/components/MobileNav"
import useViewer from "~/hooks/useViewer"
import LogoLg from "~/images/logo-lg.svg"
import {
  accountPath,
  editAccountPath,
  editPasswordPath,
  favoritesPath,
  playstormsPath,
} from "~/paths"

export const Menu = ({hasGrayBackground}: {hasGrayBackground: boolean}) => {
  const viewer = useViewer()
  return (
    <>
      <div className={clsx("flex items-center justify-center", "md:hidden")}>
        <div className="absolute left-0 top-0">
          <MobileNav>
            {viewer.admin ? <AdminMenu /> : <CreativeMenu mobile={true} />}
          </MobileNav>
        </div>
        {hasGrayBackground && (
          <Link to="/" className="mt-2 hover:opacity-90">
            <div className="scale-[0.6]">
              <LogoLg />
            </div>
          </Link>
        )}
      </div>
      <div className={clsx("hidden bg-white", "md:block")}>
        {viewer.admin ? <AdminMenu /> : <CreativeMenu />}
      </div>
    </>
  )
}

const GRAY_BAGROUND_PATHS = [
  playstormsPath,
  favoritesPath,
  editAccountPath,
  editPasswordPath,
  accountPath,
]

const AuthenticatedLayout = () => {
  const viewer = useViewer()
  const location = useLocation()
  const hasGrayBackground =
    GRAY_BAGROUND_PATHS.some(path => matchPath(path, location.pathname)) ||
    (!viewer.admin && location.pathname === "/")

  return (
    <div
      className={clsx("flex min-h-full flex-col", "md:flex-row", {
        "bg-latteo-gray": hasGrayBackground,
      })}
      data-test="logged-in"
    >
      <Menu hasGrayBackground={hasGrayBackground} />
      <div className="flex min-w-0 flex-1 flex-col">
        <div className="flex h-full flex-1 flex-col">
          <Outlet />
        </div>
      </div>
    </div>
  )
}

export default AuthenticatedLayout
