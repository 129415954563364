import {Helmet} from "react-helmet"
import toast from "react-hot-toast"
import {useParams} from "react-router-dom"
import invariant from "tiny-invariant"
import {getFragmentData} from "~/__generated__"
import AdminOnboardingSurveyAnswers from "~/components/AdminOnboardingSurveyAnswers"
import AdminPlaystormsTable from "~/components/AdminPlaystormsTable"
import EditAccountForm from "~/components/EditAccountForm"
import ScreenTitle from "~/components/ScreenTitle"
import Button from "~/components/ui/Button"
import Container from "~/components/ui/Container"
import DashboardPanel from "~/components/ui/DashboardPanel"
import Error from "~/components/ui/Error"
import {H1} from "~/components/ui/Headings"
import Loading from "~/components/ui/Loading"
import useAdminPlaystormsQuery from "~/hooks/useAdminPlaystormsQuery"
import useAdminUserQuery from "~/hooks/useAdminUserQuery"
import {SURVEY_ANSWER_FRAGMENT} from "~/hooks/useSurveyAnswersQuery"
import {signInAsPath} from "~/paths"
import fetchWrapper from "~/util/fetchWrapper"

const UserPlaystorms = ({userId}: {userId: string}) => {
  const {playstorms, loading, error, hasNextPage, fetchNextPage} =
    useAdminPlaystormsQuery({
      variables: {userId},
    })

  if (loading && playstorms.length === 0) return <Loading />
  if (error || !playstorms) return <Error message="Error loading playstorms" />

  return (
    <div>
      <div className="flex justify-between">
        <H1>Playstorms</H1>
      </div>
      <div className="mt-10">
        <AdminPlaystormsTable
          playstorms={playstorms}
          hasNextPage={hasNextPage!!}
          fetchNextPage={fetchNextPage}
          loading={loading}
        />
      </div>
    </div>
  )
}

const TITLE = "User Details"

const AdminUsersScreen = () => {
  const {id} = useParams()
  invariant(id, "Expected id to be defined")
  const {user, loading, error} = useAdminUserQuery(id)

  const signInAs = async () => {
    try {
      const response = await fetchWrapper(signInAsPath({id}), {
        method: "POST",
      })
      if (response.status === 200) {
        window.location.replace(`/?flash=Signed in as ${user?.email || "user"}`)
      } else {
        console.error(response)
        toast.error("Sign in failed, please try again")
      }
    } catch (error) {
      console.error(error)
      const message = "Sign in failed, please try again"
      toast.error(message)
    }
  }

  if (loading) return <Loading />
  if (error || !user) return <Error message="Error loading users" />

  const onboardingSurveyAnswers = user.onboardingSurveyAnswers.nodes.map(
    playstormAnswer => getFragmentData(SURVEY_ANSWER_FRAGMENT, playstormAnswer)
  )

  return (
    <Container size="full" centered={false}>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <ScreenTitle
        actions={
          <div>
            <Button onClick={signInAs}>Impersonate</Button>
          </div>
        }
      >
        {TITLE}
      </ScreenTitle>
      <div className="flex flex-col gap-16">
        <DashboardPanel title="Account Details">
          <EditAccountForm user={user} />
        </DashboardPanel>
        <UserPlaystorms userId={id} />
        <AdminOnboardingSurveyAnswers surveyAnswers={onboardingSurveyAnswers} />
      </div>
    </Container>
  )
}

export default AdminUsersScreen
