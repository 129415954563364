import {useQuery} from "@apollo/client"
import {getFragmentData, gql} from "~/__generated__"

export const QUESTION_FRAGMENT = gql(`
  fragment QuestionFragment on Question {
    id
    text
    affirmationPrompt
    grandRevealPrompt
    questionType
    gptModel
    audioUrl
  }
`)

const GET_QUESTION_QUERY = gql(`
  query Question($id: ID!) {
    question(id: $id) {
      ...QuestionFragment
    }
  }
`)

const useQuestionQuery = (id: string) => {
  const {data, loading, error} = useQuery(GET_QUESTION_QUERY, {
    variables: {
      id,
    },
  })

  const question = getFragmentData(QUESTION_FRAGMENT, data?.question)
  return {question, data, loading, error}
}

export default useQuestionQuery
