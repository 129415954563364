import {Formik, FormikHelpers} from "formik"
import {useNavigate} from "react-router-dom"
import invariant from "tiny-invariant"
import {getFragmentData} from "~/__generated__"
import {
  AnswerTypeEnum,
  PlaystormStepAnswerFragmentFragment,
} from "~/__generated__/graphql"
import useAnswerCreate from "~/hooks/useAnswerCreate"
import {ANSWER_FRAGMENT} from "~/hooks/useAnswerQuery"
import useRecordingUpload from "~/hooks/useRecordingUpload"
import useViewerContext from "~/hooks/useViewerContext"
import {displayErrors} from "~/util/validations"
import {getAnswerSubmitPath} from "./AnswerEditForm"
import AnswerFields, {
  AnswerFormValues,
  answerValidationSchema,
} from "./AnswerFields"

const AnswerCreateForm = ({
  answer,
}: {
  answer: PlaystormStepAnswerFragmentFragment
}) => {
  const [mutate] = useAnswerCreate()
  const navigate = useNavigate()
  const {uploadAudio} = useRecordingUpload()
  const {refetchViewer} = useViewerContext()

  const initialValues = {
    text: "",
    answerType: AnswerTypeEnum.Audio,
    recording: "",
    duration: 0,
  }

  const createAnswer = async (
    values: AnswerFormValues,
    {setFieldError}: FormikHelpers<AnswerFormValues>
  ) => {
    try {
      let signedRecordingId = undefined

      if (values.recordingBlob) {
        signedRecordingId = await uploadAudio(values.recordingBlob)
      }

      const resp = await mutate({
        variables: {
          input: {
            answerInput: {
              text: values.text,
              answerType: values.answerType,
              recording: signedRecordingId,
            },
            questionId: answer.questionId,
            playstormId: answer.playstormId,
          },
        },
      })
      const createdAnswer = getFragmentData(
        ANSWER_FRAGMENT,
        resp?.data?.answerCreate?.answer
      )
      const id = createdAnswer?.id
      invariant(id, "Expected answer id")

      const nextPath = getAnswerSubmitPath({answer})

      if (!answer.nextQuestionId) {
        refetchViewer()
      }

      navigate(nextPath)
    } catch (error: any) {
      console.error(error)
      displayErrors(error?.graphQLErrors, setFieldError)
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={answerValidationSchema}
      validateOnBlur={false}
      onSubmit={createAnswer}
    >
      <AnswerFields answer={answer} />
    </Formik>
  )
}

export default AnswerCreateForm
