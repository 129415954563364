import {useEffect} from "react"
import {Helmet} from "react-helmet"
import toast from "react-hot-toast"
import {Outlet, useLocation, useSearchParams} from "react-router-dom"
import {useGooglePageTracking} from "~/hooks/useGooglePageTracking"
import {trackView} from "~/util/analytics"

const Layout = () => {
  const location = useLocation()
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    trackView()
  }, [location.pathname])

  useGooglePageTracking()

  useEffect(() => {
    if (searchParams) {
      const params = new URLSearchParams(searchParams)
      const flash = params.get("flash")
      if (flash) {
        const newSearchParams = searchParams
        newSearchParams.delete("flash")
        setSearchParams(newSearchParams)
        toast.success(flash)
      }
    }
  }, [searchParams, setSearchParams])

  return (
    <div className="h-full text-black">
      <Helmet titleTemplate="%s | Playstorming" defaultTitle="Playstorming" />
      <Outlet />
    </div>
  )
}

export default Layout
