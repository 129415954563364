import clsx from "clsx"
import {getFragmentData} from "~/__generated__"
import {
  MessageTypeEnum,
  PlaystormOverviewAnswerFragmentFragment,
} from "~/__generated__/graphql"
import StrengtheningMessages from "~/components/StrengtheningMessages"
import Panel from "~/components/ui/Panel"
import useAnswerFavoriteToggle from "~/hooks/useAnswerFavoriteToggle"
import useMessageCreate from "~/hooks/useMessageCreate"
import {MESSAGE_FRAGMENT} from "~/hooks/useMessageUpdatedSubscription"
import {trackEvent} from "~/util/analytics"
import {isJobComplete} from "~/util/jobs"
import FavoriteHeartEmpty from "../../components/icons/favorite-heart-empty.svg"
import FavoriteHeartFilled from "../../components/icons/favorite-heart-filled.svg"
import IdeationPencilSm from "../../components/icons/ideation-pencil-sm.svg"
import PlaystormOverviewActionButton from "./PlaystormOverviewActionButton"
import PlaystormOverviewActionButtons from "./PlaystormOverviewActionButtons"

const PlaystormStrengtheningIdeas = ({
  answer,
}: {
  answer: PlaystormOverviewAnswerFragmentFragment
}) => {
  const {isOwner, isFavorite, strengtheningMessages} = answer
  const messages = strengtheningMessages.nodes.map(message =>
    getFragmentData(MESSAGE_FRAGMENT, message)
  )
  const streamingMessageId = messages.find(
    message => !isJobComplete(message.jobStatus)
  )?.id
  const [mutate, {loading}] = useMessageCreate()
  const [toggleFavorite, {loading: favoriteToggleLoading}] =
    useAnswerFavoriteToggle(answer)

  const createMessage = () => {
    trackEvent("Click Generate More Ideas", {
      playstormId: answer.playstormId,
    })
    mutate({
      variables: {
        input: {
          messageInput: {
            messageType: MessageTypeEnum.Strengthening,
          },
          answerId: messages[0].answerId,
        },
      },
    })
  }

  return (
    <div id={answer.id!}>
      <Panel
        size="lg"
        color="green"
        className={clsx({
          "!pb-0": isOwner,
        })}
      >
        <StrengtheningMessages messages={messages} editable={isOwner!!} />
        {isOwner && (
          <PlaystormOverviewActionButtons>
            <PlaystormOverviewActionButton
              onClick={() => {
                trackEvent(
                  `Click Grand Reveal ${
                    answer.isFavorite ? "UnFavorite" : "Favorite"
                  }`
                )
                toggleFavorite()
              }}
              icon={
                isFavorite ? <FavoriteHeartFilled /> : <FavoriteHeartEmpty />
              }
              disabled={favoriteToggleLoading}
              data-test={isFavorite ? "is-favorite" : "is-not-favorite"}
            >
              Favorite
            </PlaystormOverviewActionButton>
            <PlaystormOverviewActionButton
              onClick={createMessage}
              disabled={loading || !!streamingMessageId}
              icon={<IdeationPencilSm />}
            >
              Generate More Ideas
            </PlaystormOverviewActionButton>
          </PlaystormOverviewActionButtons>
        )}
      </Panel>
    </div>
  )
}

export default PlaystormStrengtheningIdeas
