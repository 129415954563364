import {Formik, FormikHelpers} from "formik"
import toast from "react-hot-toast"
import invariant from "tiny-invariant"
import {getFragmentData} from "~/__generated__"
import {QuestionFragmentFragment} from "~/__generated__/graphql"
import env from "~/env"
import {QUESTION_FRAGMENT} from "~/hooks/useQuestionQuery"
import useQuestionUpdate from "~/hooks/useQuestionUpdate"
import useTTSUpload from "~/hooks/useTTSUpload"
import {displayErrors} from "~/util/validations"
import AdminQuestionFields, {
  QuestionFormValues,
  questionValidationSchema,
} from "./AdminQuestionFields"

const AdminQuestionUpdateForm = ({
  question,
  close,
}: {
  question: QuestionFragmentFragment
  close: () => void
}) => {
  const [mutate] = useQuestionUpdate()
  const ttsUpload = useTTSUpload()
  const {hasGeneratedAudio, uploadAudio} = ttsUpload
  invariant(question.gptModel, "Expected question.gptModel")

  const initialValues = {
    text: question?.text || "",
    affirmationPrompt: question?.affirmationPrompt || "",
    grandRevealPrompt: question?.grandRevealPrompt || "",
    questionType: question?.questionType?.toString() || "",
    gptModel: question.gptModel,
    audio: "",
  }

  const updateQuestion = async (
    values: QuestionFormValues,
    {setFieldError, resetForm}: FormikHelpers<QuestionFormValues>
  ) => {
    try {
      let signedAudioId = undefined

      // TODO: Mock uploads instead of skipping in test env
      if (env.ENVIRONMENT !== "test") {
        // If the user has generated new audio, or the text has changed,
        // generate new audio and upload it
        if (hasGeneratedAudio || question.text !== values.text) {
          signedAudioId = await uploadAudio(values.text)
        }
      }

      const resp = await mutate({
        variables: {
          input: {
            id: question.id,
            questionInput: {
              text: values.text,
              affirmationPrompt: values.affirmationPrompt,
              grandRevealPrompt: values.grandRevealPrompt,
              questionType: values.questionType,
              gptModel: values.gptModel,
              audio: signedAudioId,
            },
          },
        },
      })
      const updatedQuestion = getFragmentData(
        QUESTION_FRAGMENT,
        resp.data?.questionUpdate.question
      )
      invariant(updatedQuestion, "Expected question")
      resetForm({
        values: {
          text: values.text,
          affirmationPrompt: values.affirmationPrompt,
          grandRevealPrompt: values.grandRevealPrompt,
          questionType: values.questionType,
          gptModel: values.gptModel,
          audio: "",
        },
      })
      close()
      toast.success("Question updated successfully!")
    } catch (error: any) {
      displayErrors(error?.graphQLErrors, setFieldError)
    }
  }

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={questionValidationSchema}
        validateOnBlur={false}
        onSubmit={updateQuestion}
      >
        <AdminQuestionFields
          close={close}
          ttsUpload={ttsUpload}
          question={question}
        />
      </Formik>
    </div>
  )
}

export default AdminQuestionUpdateForm
