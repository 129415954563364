import {PlaystormStepAnswerFragmentFragment} from "~/__generated__/graphql"
import {
  AnswerControlButtonsContainer,
  BackButton,
  ContinueButton,
  NextButton,
} from "./AnswerControlButtons"

const AnswerTextControls = ({
  answer,
  toggleAnswerType,
}: {
  answer: PlaystormStepAnswerFragmentFragment
  toggleAnswerType: () => void
}) => {
  return (
    <AnswerControlButtonsContainer>
      <BackButton onClick={toggleAnswerType} />
      <ContinueButton />
      <NextButton answer={answer} />
    </AnswerControlButtonsContainer>
  )
}

export default AnswerTextControls
