import clsx from "clsx"
import BubblePointerImageSm from "./icons/bubble-pointer-sm.svg"
import BubblePointerImage from "./icons/bubble-pointer.svg"

const BubblePointer = () => {
  return (
    <>
      <div
        className={clsx("absolute bottom-[-21px] left-[-13px]", "sm:hidden")}
      >
        <BubblePointerImageSm />
      </div>
      <div
        className={clsx(
          "absolute bottom-[-34px] left-[-21px] hidden",
          "sm:block"
        )}
      >
        <BubblePointerImage />
      </div>
    </>
  )
}

export default BubblePointer
