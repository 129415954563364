import clsx from "clsx"
import {Field, useField} from "formik"
import FieldLabelContainer from "./FieldLabelContainer"
import FormikFileUpload from "./FormikFileUpload"
import FormikInputField from "./FormikInputField"
import FormikRadioField from "./FormikRadioField"
import FormikSelectField from "./FormikSelectField"
import ValidationError from "./ValidationError"

export type OptionType = {
  label: string
  value: string
  isDisabled?: boolean
  hideErrors?: boolean
}
export type FormikFieldType = typeof Field & {
  options?: OptionType[]
  buttonTitle?: string
  skipLabelNesting?: boolean
  uploadedImageUrl?: string
  light?: boolean
  size?: "md" | "lg"
  inputClassName?: string
  focusTrigger?: boolean
  description?: string
}

const FormikField: FormikFieldType = ({
  as,
  label,
  hideErrors,
  hidden,
  buttonTitle,
  skipLabelNesting,
  uploadedImageUrl,
  light,
  size,
  inputClassName,
  description,
  ...props
}) => {
  const [_field, meta] = useField(props)
  return (
    <div
      className={clsx("w-full", {
        hidden: hidden,
      })}
      data-test={`${props.name}-field`}
    >
      <FieldLabelContainer
        label={label}
        skipLabelNesting={skipLabelNesting}
        size={size}
        description={description}
      >
        {as === "select" ? (
          <FormikSelectField light={light} {...props} />
        ) : as === "radio" ? (
          <FormikRadioField {...props} type="radio" />
        ) : as === "file" ? (
          <FormikFileUpload
            buttonTitle={buttonTitle}
            uploadedImageUrl={uploadedImageUrl}
            {...props}
          />
        ) : (
          <FormikInputField
            {...props}
            as={as}
            light={light}
            size={size}
            inputClassName={inputClassName}
          />
        )}
      </FieldLabelContainer>

      {!hideErrors && meta.touched && meta.error ? (
        <ValidationError>{meta.error}</ValidationError>
      ) : null}
    </div>
  )
}

export default FormikField
