import useFileUpload from "./useFileUpload"

const useRecordingUpload = () => {
  const uploadFile = useFileUpload()

  const uploadAudio = async (blob: Blob) => {
    const file = new File([blob], "audio")
    const signedAudioId = await uploadFile(file)
    return signedAudioId
  }

  return {
    uploadAudio,
  }
}

export default useRecordingUpload
