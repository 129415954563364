import clsx from "clsx"
import {Form, Formik, FormikHelpers, useFormikContext} from "formik"
import {useCallback, useEffect, useRef, useState} from "react"
import Markdown from "react-markdown"
import * as Yup from "yup"
import {MessageFragmentFragment} from "~/__generated__/graphql"
import useAnswerUpdate from "~/hooks/useAnswerUpdate"
import useMessageUpdate from "~/hooks/useMessageUpdate"
import useMessageUpdatedSubscription from "~/hooks/useMessageUpdatedSubscription"
import FormikAutosave from "~/lib/FormikAutosave"
import {trackEvent} from "~/util/analytics"
import {displayErrors} from "~/util/validations"
import PencilSm from "../../components/icons/pencil-sm.svg"
import UndoIcon from "../../components/icons/undo-icon.svg"
import ConfirmModal from "../ui/ConfirmModal"
import FormikField from "../ui/FormikField"

type AnswerValues = {
  text: string
}

const validationSchema = Yup.object({
  text: Yup.string().required("Text is required"),
})

const AnswerEditFields = ({
  initialText,
  toggleForm,
  showForm,
}: {
  initialText: string
  toggleForm: () => void
  showForm: boolean
}) => {
  const {values, setFieldValue} = useFormikContext<AnswerValues>()
  const [showRevertConfirmation, setShowRevertConfirmation] = useState(false)
  const toggleRevertConfirmation = () => {
    trackEvent("Click Grand Reveal Revert Answer")
    setShowRevertConfirmation(prev => !prev)
  }

  const revert = () => {
    setFieldValue("text", initialText)
  }

  return (
    <div>
      <FormikAutosave />
      <FormikField
        name="text"
        autoFocus={true}
        as="textarea"
        rows={5}
        inputClassName="rounded-2xl p-6 !text-xl pr-24 !leading-8"
        focusTrigger={showForm}
      />
      <div className="absolute right-4 top-4 flex gap-2">
        <button
          title="Edit"
          onClick={toggleForm}
          type="button"
          className="text-black hover:opacity-75"
          data-test="inner-edit-text-button"
        >
          <PencilSm />
        </button>
        <button
          title="Restore to original"
          onClick={toggleRevertConfirmation}
          type="button"
          className="text-black hover:opacity-75"
          disabled={values.text === initialText || !initialText}
          data-test="revert-text-button"
        >
          <UndoIcon />
        </button>
      </div>

      {showRevertConfirmation && (
        <ConfirmModal
          onClose={toggleRevertConfirmation}
          onConfirm={revert}
          data-test="selection-update-warning"
        >
          Are you sure you want to restore your answer?
        </ConfirmModal>
      )}
    </div>
  )
}

export const TextContent = ({text}: {text: string}) => {
  // todo: Consider rendering markdown serverside
  return (
    <div className="markdown-content text-xl leading-8">
      <Markdown>{text}</Markdown>
    </div>
  )
}

const PlaystormOverviewText = ({
  onSubmit,
  text,
  initialText,
  backgroundColor = "blue",
  loading = false,
  editable = true,
}: {
  onSubmit: (
    values: AnswerValues,
    {setFieldError}: FormikHelpers<AnswerValues>
  ) => Promise<void>
  text: string
  initialText: string
  backgroundColor?: "green" | "blue"
  loading?: boolean
  editable?: boolean
}) => {
  const [showForm, setShowForm] = useState(false)
  const toggleForm = useCallback(() => setShowForm(prev => !prev), [])
  const ref = useRef<HTMLDivElement | null>(null)

  const initialValues = {
    text: text || "",
  }

  const clickEditButton = () => {
    trackEvent("Click Grand Reveal Edit Answer")
    toggleForm()
  }

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        showForm &&
        ref.current &&
        !ref.current.contains(event.target as Node)
      ) {
        toggleForm()
      }
    }
    document.addEventListener("click", handleClickOutside, true)
    return () => {
      document.removeEventListener("click", handleClickOutside, true)
    }
  }, [toggleForm, showForm])

  if (loading || editable === false)
    return (
      <div
        className={clsx({
          "pr-10": !!editable,
        })}
      >
        <TextContent text={text} />
      </div>
    )

  return (
    <div ref={ref}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnBlur={false}
        enableReinitialize={true}
        onSubmit={onSubmit}
      >
        {({values}) => (
          <Form className="relative">
            <div className={showForm ? "" : "hidden"}>
              <AnswerEditFields
                initialText={initialText}
                toggleForm={toggleForm}
                showForm={showForm}
              />
            </div>
            <div
              className={clsx("relative", {
                hidden: showForm,
                "pr-10": editable,
              })}
            >
              {editable && (
                <button
                  className={clsx(
                    "absolute right-0 top-0 text-black hover:opacity-75",
                    backgroundColor === "green" ? "text-black" : "text-white"
                  )}
                  title="Edit"
                  onClick={clickEditButton}
                  type="button"
                  data-test="edit-text-button"
                >
                  <PencilSm />
                </button>
              )}
              <button
                onClick={clickEditButton}
                className="text-left"
                type="button"
              >
                <TextContent text={values.text} />
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export const PlaystormOverviewMessageTextStreaming = ({
  message,
}: {
  message: MessageFragmentFragment
}) => {
  useMessageUpdatedSubscription(message)

  return (
    <div
      key={message.id}
      className={clsx({
        "animate-pulse": !message.text,
      })}
    >
      <PlaystormOverviewMessageText
        id={message.id}
        text={message.text || "..."}
        initialText={message.initialText || ""}
      />
    </div>
  )
}

export const PlaystormOverviewMessageText = ({
  id,
  text,
  initialText,
  loading,
  editable,
  backgroundColor,
}: {
  id: string
  text: string
  initialText: string
  loading?: boolean
  editable?: boolean
  backgroundColor?: "green" | "blue"
}) => {
  const [mutateMessage] = useMessageUpdate()
  const onSubmit = async (
    values: AnswerValues,
    {setFieldError}: FormikHelpers<AnswerValues>
  ) => {
    try {
      await mutateMessage({
        variables: {
          input: {
            id: id,
            messageInput: {
              text: values.text,
            },
          },
        },
      })
      return
    } catch (error: any) {
      displayErrors(error?.graphQLErrors, setFieldError)
    }
  }

  return (
    <div data-test={`message-${id}`}>
      <PlaystormOverviewText
        onSubmit={onSubmit}
        text={text}
        initialText={initialText}
        loading={loading}
        editable={editable}
        backgroundColor={backgroundColor}
      />
    </div>
  )
}

export const PlaystormOverviewAnswerText = ({
  id,
  text,
  initialText,
  editable,
}: {
  id: string
  text: string
  initialText: string
  editable?: boolean
}) => {
  const [mutateAnswer] = useAnswerUpdate()
  const onSubmit = async (
    values: AnswerValues,
    {setFieldError}: FormikHelpers<AnswerValues>
  ) => {
    try {
      await mutateAnswer({
        variables: {
          input: {
            id: id,
            answerInput: {
              text: values.text,
            },
          },
        },
      })
    } catch (error: any) {
      displayErrors(error?.graphQLErrors, setFieldError)
    }
  }

  return (
    <div data-test-id={`answer-${id}`}>
      <PlaystormOverviewText
        onSubmit={onSubmit}
        text={text}
        initialText={initialText}
        editable={editable}
      />
    </div>
  )
}
