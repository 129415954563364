import {useQuery} from "@apollo/client"
import {getFragmentData, gql} from "~/__generated__"

export const SURVEY_QUESTION_FRAGMENT = gql(`
  fragment SurveyQuestionFragment on SurveyQuestion {
    id
    title
    position
    nextSurveyQuestionId
    surveyQuestionType
    surveyLocation
    surveyOptions {
      nodes {
        id
        title
        iconName
      }
    }
  }
`)

const GET_SURVEY_QUESTION_QUERY = gql(`
  query SurveyQuestion($id: ID!) {
    surveyQuestion(id: $id) {
      ...SurveyQuestionFragment
    }
  }
`)

const useSurveyQuestionQuery = (id: string) => {
  const {data, loading, error} = useQuery(GET_SURVEY_QUESTION_QUERY, {
    variables: {
      id,
    },
  })

  const surveyQuestion = getFragmentData(
    SURVEY_QUESTION_FRAGMENT,
    data?.surveyQuestion
  )
  return {surveyQuestion, data, loading, error}
}

export default useSurveyQuestionQuery
