import clsx from "clsx"

const PlaystormOverviewTabContent = ({
  className,
  children,
}: {
  className?: string
  children: React.ReactNode
}) => (
  <div
    className={clsx(
      "-mx-10 flex max-h-[220px] overflow-y-auto bg-black/40",
      className
    )}
    children={children}
  />
)

export default PlaystormOverviewTabContent
