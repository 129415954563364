import {Formik, FormikHelpers} from "formik"
import {useNavigate} from "react-router-dom"
import invariant from "tiny-invariant"
import {
  AnswerFragmentFragment,
  PlaystormStepAnswerFragmentFragment,
} from "~/__generated__/graphql"
import useAnswerUpdate from "~/hooks/useAnswerUpdate"
import useRecordingUpload from "~/hooks/useRecordingUpload"
import {
  playstormAffirmationPath,
  playstormPath,
  playstormQuestionPath,
} from "~/paths"
import {displayErrors} from "~/util/validations"
import AnswerFields, {
  AnswerFormValues,
  answerValidationSchema,
} from "./AnswerFields"

const shouldShowAffirmation = (
  answer: Pick<
    AnswerFragmentFragment,
    "playstormId" | "questionId" | "nextQuestionId" | "questionType"
  >
) => {
  const {nextQuestionId} = answer
  return !!nextQuestionId
}

export const getAnswerSubmitPath = ({
  answer,
  skipAffirmation = false,
}: {
  answer: Pick<
    AnswerFragmentFragment,
    "playstormId" | "questionId" | "nextQuestionId" | "questionType"
  >
  skipAffirmation?: boolean
}) => {
  const {playstormId, questionId, nextQuestionId} = answer
  const showAffirmation = !skipAffirmation && shouldShowAffirmation(answer)
  if (showAffirmation) {
    return playstormAffirmationPath({
      id: playstormId,
      questionId: questionId,
    })
  }

  if (!!nextQuestionId) {
    return playstormQuestionPath({
      id: playstormId,
      questionId: nextQuestionId,
    })
  }

  return playstormPath({id: playstormId})
}

const AnswerEditForm = ({
  answer,
}: {
  answer: PlaystormStepAnswerFragmentFragment
}) => {
  const [mutate] = useAnswerUpdate()
  const navigate = useNavigate()
  const {uploadAudio} = useRecordingUpload()

  const updateAnswer = async (
    values: AnswerFormValues,
    {setFieldError}: FormikHelpers<AnswerFormValues>
  ) => {
    try {
      let signedRecordingId = undefined

      if (values.recordingBlob) {
        signedRecordingId = await uploadAudio(values.recordingBlob)
      }

      invariant(answer.id, "Expected answer id")
      const showAffirmation = shouldShowAffirmation(answer)
      await mutate({
        variables: {
          input: {
            id: answer.id,
            answerInput: {
              text: values.text,
              answerType: values.answerType,
              recording: signedRecordingId,
            },
            queueAffirmation: showAffirmation,
          },
        },
      })

      navigate(
        getAnswerSubmitPath({
          answer,
        })
      )
    } catch (error: any) {
      console.error(error)
      displayErrors(error?.graphQLErrors, setFieldError)
    }
  }

  const initialValues = {
    text: answer.text || "",
    answerType: answer.answerType,
    recording: "",
    duration: 0,
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={answerValidationSchema}
      validateOnBlur={false}
      onSubmit={updateAnswer}
    >
      <AnswerFields answer={answer} />
    </Formik>
  )
}

export default AnswerEditForm
