import clsx from "clsx"
import {TemplateFragmentFragment} from "~/__generated__/graphql"
import CreativeTemplateListItem from "./CreativeTemplateListItem"
import Button from "./ui/Button"

const CreativeTemplateList = ({
  templates,
  fetchNextPage,
  hasNextPage,
  loading,
}: {
  templates: TemplateFragmentFragment[]
  fetchNextPage: () => void
  hasNextPage: boolean
  loading: boolean
}) => {
  return (
    <div>
      <div
        className={clsx(
          "flex flex-col gap-6",
          "md:grid md:grid-cols-2",
          "lg:grid-cols-3"
        )}
      >
        {templates.map(template => (
          <CreativeTemplateListItem key={template.id} template={template} />
        ))}
      </div>
      {hasNextPage && (
        <div className="mt-6 flex justify-center">
          <Button
            onClick={fetchNextPage}
            color="ghost"
            inline={true}
            disabled={loading}
          >
            Load more
          </Button>
        </div>
      )}
    </div>
  )
}

export default CreativeTemplateList
