import {useApolloClient, useSubscription} from "@apollo/client"
import {useRef} from "react"
import invariant from "tiny-invariant"
import {gql} from "~/__generated__"
import {UpdatedMessage} from "~/__generated__/graphql"

export const MESSAGE_TTS_JOB_STATUS_FRAGMENT = gql(`
  fragment MessageTtsJobStatusFragment on Message {
    ttsJobStatus
  }
`)

export const MESSAGE_TTS_UPDATED_SUBSCRIPTION = gql(`
  subscription MessageTtsUpdated($messageId: ID!) {
    messageTtsUpdated(messageId: $messageId) {
      message {
        id
        audioChunk
        audioChunkIndex
        ttsJobStatus
      }
    }
  }
`)

const useMessageTtsUpdatedSubscription = (
  messageId: string,
  onNewAudioChunk: (audioChunk: string) => void
) => {
  const messageQueue = useRef<UpdatedMessage[]>([])
  const lastBufferedMessageIndex = useRef(-1)

  const client = useApolloClient()

  const {data, loading, error} = useSubscription(
    MESSAGE_TTS_UPDATED_SUBSCRIPTION,
    {
      variables: {messageId: messageId},
      onData: ({data: {data}}) => {
        const updatedMessage = data?.messageTtsUpdated.message
        invariant(updatedMessage, "expected message to exist")

        if (
          updatedMessage.audioChunkIndex !== undefined &&
          updatedMessage.audioChunkIndex !== null &&
          updatedMessage.audioChunkIndex >= 0
        ) {
          onIncomingMessage(updatedMessage)
        }

        client.cache.writeFragment({
          id: `Message:${messageId}`,
          fragment: MESSAGE_TTS_JOB_STATUS_FRAGMENT,
          data: {
            ttsJobStatus: updatedMessage.ttsJobStatus,
          },
        })
      },
    }
  )

  const sendAudioChunk = (message: UpdatedMessage) => {
    // Send the next audio chunk to the buffer
    if (message.audioChunk) {
      onNewAudioChunk(message.audioChunk)
    }
    lastBufferedMessageIndex.current += 1

    // If the queue has the next message, send it
    const queuedNextMessage = messageQueue.current.find(
      m => m.audioChunkIndex === lastBufferedMessageIndex.current + 1
    )
    if (queuedNextMessage) {
      messageQueue.current = messageQueue.current.filter(
        message => message.audioChunkIndex !== queuedNextMessage.audioChunkIndex
      )
      sendAudioChunk(queuedNextMessage)
    }
  }

  // Messages are not guaranteed to arrive in order. Send them out by audioChunkIndex,
  // or queue them until the correct next message arrives.
  const onIncomingMessage = (message: UpdatedMessage) => {
    console.log("onIncomingMessage index", message.audioChunkIndex)
    // Always send the first audio chunk
    if (message.audioChunkIndex === 0) {
      sendAudioChunk(message)
      // Only send the audio chunk if it's the next one in the queue
    } else if (
      message.audioChunkIndex ===
      lastBufferedMessageIndex.current + 1
    ) {
      sendAudioChunk(message)
      // Add any messages that came out of order to the queue
    } else {
      messageQueue.current.push(message)
    }
  }

  return {data, loading, error}
}

export default useMessageTtsUpdatedSubscription
