import {Helmet} from "react-helmet"
import {useParams} from "react-router-dom"
import invariant from "tiny-invariant"
import TemplateScreenContent from "~/components/TemplateScreenContent"
import Container from "~/components/ui/Container"
import Error from "~/components/ui/Error"
import {H1} from "~/components/ui/Headings"
import Loading from "~/components/ui/Loading"
import useTemplateQuery from "~/hooks/useTemplateQuery"

const TITLE = "Edit Playstorm Template"

const AdminEditTemplateScreen = () => {
  const {id} = useParams()
  invariant(id, "Expected id to be defined")
  const {template, loading, error} = useTemplateQuery(id)

  if (loading) return <Loading />
  if (error || !template) return <Error message="Error loading template" />

  return (
    <Container size="full" centered={false}>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <div className="my-6">
        <H1>{TITLE}</H1>
      </div>
      <TemplateScreenContent template={template} />
    </Container>
  )
}

export default AdminEditTemplateScreen
