import {useQuery} from "@apollo/client"
import {getFragmentData, gql} from "~/__generated__"
import {TEMPLATE_FRAGMENT} from "./useTemplateQuery"
import {TemplateVisibility} from "~/__generated__/graphql"

const GET_TEMPLATES_QUERY = gql(`
  query Templates($after: String, $visibility: TemplateVisibility) {
    templates(after: $after, first: 21, visibility: $visibility) {
      edges {
        node {
          ...TemplateFragment
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`)

const useTemplatesQuery = (
  options: {visibility?: TemplateVisibility; skip?: boolean} = {}
) => {
  const {data, loading, error, fetchMore} = useQuery(GET_TEMPLATES_QUERY, {
    variables: {
      visibility: options.visibility,
    },
    skip: options.skip,
  })

  const pageInfo = data?.templates.pageInfo
  const endCursor = pageInfo?.endCursor
  const hasNextPage = pageInfo?.hasNextPage

  const fetchNextPage = () => {
    fetchMore({
      variables: {
        after: endCursor,
      },
    })
  }

  const templates =
    data?.templates?.edges.map(edge =>
      getFragmentData(TEMPLATE_FRAGMENT, edge.node)
    ) || []

  return {
    templates,
    data,
    loading,
    error,
    hasNextPage,
    endCursor,
    fetchNextPage,
  }
}

export default useTemplatesQuery
