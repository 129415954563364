import AudioPlayer from "react-h5-audio-player"
import ForwardIcon from "../icons/forward-icon.svg"
import RewindIcon from "../icons/rewind-icon.svg"
import PlaystormOverviewTabContent from "./PlaystormOverviewTabContent"

const PlaystormOverviewPlayer = ({
  recordingUrl,
  autoPlay = false,
}: {
  recordingUrl: string
  autoPlay?: boolean
}) => {
  return (
    <PlaystormOverviewTabContent className="p-4">
      <div className="answer-audio-player mx-auto w-full max-w-[545px]">
        <AudioPlayer
          autoPlay={autoPlay}
          src={recordingUrl}
          customIcons={{
            rewind: <RewindIcon />,
            forward: <ForwardIcon />,
          }}
        />
      </div>
    </PlaystormOverviewTabContent>
  )
}

export default PlaystormOverviewPlayer
