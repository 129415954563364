// From https://gist.github.com/SPodjasek/c5354da2e897daa14654674ab21c9b72
// which was forked from https://gist.github.com/jaredpalmer/56e10cabe839747b84b81410839829be

import {useFormikContext} from "formik"
import debounce from "lodash/debounce"
import React, {useCallback, useEffect, useState} from "react"
import isEqual from "react-fast-compare"

type AutoSaveFieldsStates = "changed" | "saved" | undefined

export type AutoSaveFieldsStatusRenderer = (
  state: "submitting" | AutoSaveFieldsStates
) => React.ReactNode

export interface AutoSaveFieldsProps {
  /**
   * Number of milliseconds to wait after last change before submitting the form
   */
  debounceMs?: number
  /**
   * Should the status indicator be visible
   */
  visible?: boolean
  children?: React.ReactNode | AutoSaveFieldsStatusRenderer
}

export interface AutoSaveFieldsState {
  status?: AutoSaveFieldsStates
  values?: any
}

const FormikAutosave = ({
  debounceMs = 1000,
  visible = false,
  children,
}: AutoSaveFieldsProps) => {
  const formik = useFormikContext()
  const [{status, values}, setStatus] = useState<AutoSaveFieldsState>({
    status: undefined,
    values: formik.values,
  })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSubmit = useCallback(
    debounce(async values => {
      await formik.submitForm()
      return setStatus({status: "saved", values})
    }, debounceMs),
    [formik.submitForm, debounceMs]
  )

  useEffect(() => {
    if (
      formik.isValid &&
      (formik.dirty || !isEqual(formik.values, values)) &&
      !formik.isSubmitting
    ) {
      setStatus({status: "changed", values})
      debouncedSubmit(formik.values)
    }
    return debouncedSubmit.cancel
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSubmit, formik.values])

  return typeof children === "function" ? (
    (children as AutoSaveFieldsStatusRenderer)(
      formik.isSubmitting ? "submitting" : status
    )
  ) : React.Children.count(children) !== 0 && status === "saved" ? (
    React.Children.only(children)
  ) : visible ? (
    <p className="text-success text-center">
      {!!formik.isSubmitting
        ? "Saving..."
        : status === "saved"
        ? "Changes saved"
        : null}
    </p>
  ) : null
}

export default FormikAutosave
