import {path} from "static-path"

export const rootPath = "/"

export const forgotPasswordPath = "/forgot-password"
export const resetPasswordPath = "/reset-password"
export const createAccountPath = "/create-account"
export const accountSurveyQuestionPath = path("/account/questions/:id")
export const signInPostPath = "/users/sign_in"
export const logInPath = "/log-in"
export const logInDeletePath = "/users/sign_out"
export const signUpPostPath = "/users"
export const resetPasswordPostPath = "/users/password"
export const adminTtsPostPath = "/admin/tts"
export const transcribeAudioPostPath = "/transcription"
export const signInAsPath = path("/admin/users/:id/sign-in-as")

export const newPlaystormPath = path("/playstorms/:templateId/new/")
export const playstormPath = path("/playstorms/:id")
export const playstormsPath = "/playstorms"
export const favoritesPath = "/favorites"
export const accountPath = "/account"
export const editPasswordPath = "/account/password"
export const editAccountPath = "/account/edit"
export const playstormQuestionPath = path("/playstorms/:id/:questionId")
export const playstormAffirmationPath = path(
  "/playstorms/:id/:questionId/success"
)
export const playstormSurveyQuestionPath = path(
  "/playstorms/:id/complete/:surveyQuestionId"
)

export const adminTemplatesPath = "/admin/templates"
export const adminNewTemplatePath = "/admin/templates/new"
export const adminEditTemplatePath = path("/admin/templates/:id/edit")
export const adminPlaystormsPath = "/admin/playstorms"
export const adminPlaystormPath = path("/admin/playstorms/:id")
export const adminUsersPath = "/admin/users"
export const adminUserPath = path("/admin/users/:id")
export const adminOrganizationsPath = "/admin/organizations"
export const adminOrganizationPath = path("/admin/organizations/:id")
export const adminNewOrganizationPath = "/admin/organizations/new"
