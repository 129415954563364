import {useEffect} from "react"
import {useLocation} from "react-router-dom"
import {trackEvent} from "~/util/analytics"

// Amplitude tracks all page views automatically. This adds named page events which
// allow us to track important page views without having to keep the same url/page
// titles in the future
const useTrackPage = (name: string) => {
  const location = useLocation()

  useEffect(() => {
    trackEvent(`${name} Page View`, {
      path: location.pathname,
    })
  }, [name, location.pathname])
}

export default useTrackPage
