import {toast} from "react-hot-toast"
import {gql} from "~/__generated__"
import {fileChecksum} from "~/util/fileChecksum"
import usePresignUpload from "./usePresignUpload"

export const PRESIGN_UPLOAD_MUTATION = gql(`
  mutation PresignUpload($input: PresignUploadInput!) {
    presignUpload(input: $input) {
      url
      headers
      signedId
    }
  }
`)

const useFileUpload = () => {
  const [mutate] = usePresignUpload()

  const uploadToS3 = async (
    file: File,
    url: string,
    headers: Record<string, string>
  ) => {
    const resp = await fetch(url, {
      method: "PUT",
      headers,
      body: file,
    })
    if (resp.status !== 200) {
      throw new Error("aws upload failed")
    }
  }

  const uploadFile = async (file: File) => {
    if (file.size > 10000000) {
      toast.error("File must be less than 10mb")
      return
    }
    const checksum = await fileChecksum(file)
    const resp = await mutate({
      variables: {
        input: {
          filename: `${file.name}.mp3`,
          byteSize: file.size,
          contentType: file.type,
          checksum,
        },
      },
    })

    const presignUpload = resp?.data?.presignUpload
    if (presignUpload) {
      await uploadToS3(file, presignUpload.url, presignUpload.headers)
      return presignUpload.signedId
    } else {
      throw new Error("presignUpload failed")
    }
  }

  return uploadFile
}

export default useFileUpload
