import {Link} from "react-router-dom"
import {
  AdminUserFragmentFragment,
  UserStatusEnum,
} from "~/__generated__/graphql"
import useToggleUserArchived from "~/hooks/useToggleUserArchived"
import {adminPlaystormPath, adminUserPath} from "~/paths"
import {dateTimeShort} from "~/util/dates"
import AdminActions, {AdminAction} from "./AdminActions"
import Button from "./ui/Button"
import {TD, TH, Table} from "./ui/Table"

const AdminUsersTable = ({
  users,
  hasNextPage,
  fetchNextPage,
  loading,
}: {
  users: AdminUserFragmentFragment[]
  hasNextPage: boolean
  fetchNextPage: () => void
  loading: boolean
}) => {
  const [toggleUserArchived] = useToggleUserArchived()
  return (
    <div className="flex w-full flex-1 flex-col">
      <Table>
        <thead>
          <tr>
            <TH>Name</TH>
            <TH>Email</TH>
            <TH>Organization (Legacy)</TH>
            <TH>Organization</TH>
            <TH>Playstorms</TH>
            <TH>Last Playstorm</TH>
            <TH>Status</TH>
            <TH align="right">Actions</TH>
          </tr>
        </thead>
        <tbody>
          {users.map((user, index) => {
            const first = index === 0
            const userPath = adminUserPath({id: user.id})
            return (
              <tr key={user.id}>
                <TD first={first}>
                  <Link to={userPath}>{user.name}</Link>
                </TD>
                <TD first={first}>
                  <Link to={userPath}>{user.email}</Link>
                </TD>
                <TD first={first}>{user.organization}</TD>
                <TD first={first}>
                  {user.structuredOrganization
                    ? user.structuredOrganization.name
                    : null}
                </TD>
                <TD first={first}>{user.playstormsCount}</TD>
                <TD first={first}>
                  {!!user.lastPlaystorm ? (
                    <Link to={adminPlaystormPath({id: user.lastPlaystorm.id})}>
                      {dateTimeShort(new Date(user.lastPlaystorm.createdAt))}
                    </Link>
                  ) : (
                    "-"
                  )}
                </TD>
                <TD first={first}>{user.status}</TD>
                <TD align="right" first={first}>
                  <AdminActions>
                    <AdminAction to={userPath}>View User</AdminAction>
                    <AdminAction
                      onClick={() =>
                        toggleUserArchived({
                          variables: {
                            input: {
                              id: user.id,
                            },
                          },
                        })
                      }
                    >
                      {user.status === UserStatusEnum.Active
                        ? "Archive"
                        : "Activate"}{" "}
                      User
                    </AdminAction>
                  </AdminActions>
                </TD>
              </tr>
            )
          })}
        </tbody>
      </Table>
      {hasNextPage && (
        <div className="mt-6 flex justify-center">
          <Button
            onClick={fetchNextPage}
            color="ghost"
            inline={true}
            disabled={loading}
          >
            Load more
          </Button>
        </div>
      )}
    </div>
  )
}

export default AdminUsersTable
