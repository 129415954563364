import clsx from "clsx"
import {Link, NavLink} from "react-router-dom"
import useSignOut from "~/hooks/useSignOut"
import Logo from "./Logo"
import SignOutIcon from "./icons/sign-out-icon.svg"

const SidebarMenuItemClasses = [
  "flex items-center px-4 py-2.5 gap-3 text-sm text-brand-blue/70",
  "hover:text-brand-blue",
]

const sidebarMenuItemBorderColor = "border-brand-blue/30"

const SidebarSignOutButton = () => {
  const {signOut, loading: signOutLoading} = useSignOut()

  return (
    <button
      onClick={signOut}
      disabled={signOutLoading}
      className={clsx(
        SidebarMenuItemClasses,
        "border-b",
        sidebarMenuItemBorderColor
      )}
    >
      <SignOutIcon />
      Sign Out
    </button>
  )
}

export const SidebarMenuContainer = ({
  children,
}: {
  children: React.ReactNode
}) => {
  return (
    <div className="flex w-[200px] flex-col bg-white">
      <Link to="/" className="hover:opacity-80">
        <div className="flex scale-[0.9] justify-center px-4 py-6">
          <Logo />
        </div>
      </Link>
      <div className="flex flex-1 flex-col">
        {children}
        <SidebarSignOutButton />
      </div>
    </div>
  )
}

export const SidebarMenuItems = ({children}: {children: React.ReactNode}) => {
  return (
    <div
      className={clsx(
        "divide-y divide-brand-blue/30 border-b border-t",
        sidebarMenuItemBorderColor
      )}
      children={children}
    />
  )
}

export const SidebarMenuItem = ({
  to,
  children,
}: {
  to: string
  children: React.ReactNode
}) => {
  return (
    <NavLink
      to={to}
      className={({isActive}) =>
        clsx(...SidebarMenuItemClasses, {
          "!text-black": isActive,
        })
      }
      children={children}
    />
  )
}
