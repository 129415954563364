import {
  SurveyAnswerFragmentFragment,
  SurveyQuestionFragmentFragment,
} from "~/__generated__/graphql"

const AdminQuestionValue = ({
  question,
  surveyAnswers,
}: {
  question: SurveyQuestionFragmentFragment
  surveyAnswers: SurveyAnswerFragmentFragment[]
}) => {
  return (
    <>
      {question.surveyOptions.nodes.map(surveyOption => (
        <div key={surveyOption.id} data-test-id={surveyOption.id}>
          <div className="mb-2 font-bold">{surveyOption.title}</div>
          <div>
            {surveyAnswers.find(
              surveyAnswer => surveyAnswer.surveyOptionId === surveyOption.id
            )?.value || "-"}
          </div>
        </div>
      ))}
    </>
  )
}

export default AdminQuestionValue
