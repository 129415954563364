import {H1} from "~/components/ui/Headings"
import {cn} from "~/lib/utils"

const ScreenTitle = ({
  actions = null,
  children,
  className,
}: {
  actions?: React.ReactNode
  children: React.ReactNode
  className?: string
}) => {
  return (
    <div className={cn("mb-10 mt-6 flex justify-between md:mb-16", className)}>
      <H1>{children}</H1>
      <div>{actions}</div>
    </div>
  )
}

export default ScreenTitle
