import clsx from "clsx"
import {useLayoutEffect, useState} from "react"
import useFitText from "use-fit-text"
import BubblePointer from "./BubblePointer"

const QuestionBubble = ({text}: {text: string}) => {
  const [initialized, setInitialized] = useState(false)

  const {fontSize, ref} = useFitText({
    maxFontSize: 100,
  })

  useLayoutEffect(() => {
    // Avoid font size flash
    setTimeout(() => {
      setInitialized(true)
    }, 50)
  }, [])

  return (
    <div
      className={clsx(
        "relative mx-auto mb-12 flex h-[170px] w-full flex-col items-center justify-center rounded-[20px] bg-brand-green p-5 text-lg font-bold",
        "sm:h-[270px] sm:rounded-5xl sm:p-11 sm:text-3xl"
      )}
    >
      <div
        ref={ref}
        style={{fontSize, lineHeight: "138%"}}
        className={clsx(
          "max-h-full text-center transition-opacity duration-100",
          initialized ? "opacity-100" : "opacity-0"
        )}
      >
        {text}
      </div>
      <BubblePointer />
    </div>
  )
}

export default QuestionBubble
