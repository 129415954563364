import {useQuery} from "@apollo/client"
import {getFragmentData, gql} from "~/__generated__"
import {QuerySurveyQuestionsArgs} from "~/__generated__/graphql"
import {SURVEY_QUESTION_FRAGMENT} from "./useSurveyQuestionQuery"

const GET_SURVEY_QUESTIONS_QUERY = gql(`
  query SurveyQuestions($surveyLocation: SurveyLocationEnum, $first: Int = 500) {
    surveyQuestions(surveyLocation: $surveyLocation, first: $first) {
      nodes {
        ...SurveyQuestionFragment
      }
    }
  }
`)

const useSurveyQuestionsQuery = ({
  variables,
}: {
  variables: QuerySurveyQuestionsArgs
}) => {
  const {data, loading, error} = useQuery(GET_SURVEY_QUESTIONS_QUERY, {
    variables,
  })

  const surveyQuestions =
    data?.surveyQuestions?.nodes.map(node =>
      getFragmentData(SURVEY_QUESTION_FRAGMENT, node)
    ) || []

  return {
    surveyQuestions,
    data,
    loading,
    error,
  }
}

export default useSurveyQuestionsQuery
