import {useMutation} from "@apollo/client"
import {gql} from "~/__generated__"

export const USER_UPDATE_MUTATION = gql(`
  mutation UserUpdate($input: UserUpdateInput!) {
    userUpdate(input: $input) {
      user {
        ...ViewerFragment
      }
    }
  }
`)

const useUserUpdate = () => {
  return useMutation(USER_UPDATE_MUTATION)
}

export default useUserUpdate
