import * as amplitude from "@amplitude/analytics-browser"
import ahoy from "ahoy.js"
import env from "~/env"

ahoy.configure({
  urlPrefix: "",
  visitsUrl: "/ahoy/visits",
  eventsUrl: "/ahoy/events",
  useBeacon: true,
  startOnReady: true,
  trackVisits: false,
  cookies: true,
  cookieDomain: null,
  headers: {},
  visitParams: {},
  withCredentials: true,
  visitDuration: 4 * 60, // 4 hours
  visitorDuration: 2 * 365 * 24 * 60, // 2 years
})

export const trackView = () => {
  ahoy.trackView()
}

export type EventProps = Record<string, string | string[] | number>

export const trackEvent = (name: string, properties: EventProps = {}) => {
  if (env.AMPLITUDE_ENABLED === "true") {
    amplitude.logEvent(name, properties)
  }
}

export const setUserId = (userId: string) => {
  if (env.AMPLITUDE_ENABLED === "true") {
    amplitude.setUserId(userId)
  }
}

export const identify = (user: {
  admin: boolean
  createdAt: string
  completedPlaystormsCount: number
  pendingPlaystormsCount: number
  hasAnsweredOnboardingSurvey: boolean
}) => {
  if (env.AMPLITUDE_ENABLED === "true") {
    amplitude.identify(
      new amplitude.Identify().set("userAttributes", {
        admin: user.admin,
        createdAt: user.createdAt,
        completedPlaystormsCount: user.completedPlaystormsCount,
        pendingPlaystormsCount: user.pendingPlaystormsCount,
        hasAnsweredOnboardingSurvey: user.hasAnsweredOnboardingSurvey,
      })
    )
  }
}
