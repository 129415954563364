import * as Sentry from "@sentry/react"
import {sample} from "lodash"
import {useEffect, useMemo, useRef, useState} from "react"
import {MessageFragmentFragment} from "~/__generated__/graphql"
import useMessageTtsUpdatedSubscription from "~/hooks/useMessageTtsUpdatedSubscription"
import useMessageUpdatedSubscription from "~/hooks/useMessageUpdatedSubscription"
import {trackEvent} from "~/util/analytics"
import {isJobComplete} from "~/util/jobs"
import BubblePointer from "../BubblePointer"
import QuestionBubble from "../QuestionBubble"
import Panel from "../ui/Panel"
import {
  AUDIO_MPEG_MIME_TYPE,
  AudioChunk,
  AudioOutputManager,
  base64ToArrayBuffer,
} from "./tts"

const MessageTts = ({
  messageId,
  onAudioPlayed,
}: {
  messageId: string
  onAudioPlayed: () => void
}) => {
  const [hasPlayed, setHasPlayed] = useState(false)
  const ttsRef = useRef(new AudioOutputManager())
  const audioRef = useRef<HTMLMediaElement & {srcObject: any}>(new Audio())
  const tts = ttsRef.current

  const onNewAudioChunk = async (chunk: string) => {
    if (!hasPlayed) {
      onAudioPlayed()
      setHasPlayed(true)
    }

    const tts = ttsRef.current
    const streamId = audioRef.current.srcObject.id
    const audioChunk = new AudioChunk(
      AUDIO_MPEG_MIME_TYPE,
      base64ToArrayBuffer(chunk)
    )
    tts.appendBuffer(streamId, audioChunk)
  }

  useEffect(() => {
    const tts = ttsRef.current
    const audio = audioRef.current
    const streamId = audioRef.current?.srcObject?.id

    const init = async () => {
      await tts.start()
      audioRef.current.srcObject = tts.createStream()
      audioRef.current.play()
    }

    init()

    return () => {
      audio.pause()
      tts.stop()
      if (streamId) {
        tts.destroyStream(streamId)
        tts.flush(streamId)
      }
    }
  }, [tts])

  useMessageTtsUpdatedSubscription(messageId, onNewAudioChunk)

  return null
}

const AFFIRMATION_TEXT_LINES = [
  "Got it... I'm thinking about that",
  "Great, working on it",
  "One sec, I'm jotting that all down",
  "Okay, I like where this is going",
  "Good stuff, I'm making some notes",
]

const TIMED_OUT_TEXT = "Great work, lets move on to the next question"

const MessageContent = ({text}: {text: string}) => {
  return (
    <div className="relative">
      <Panel size="md" color="green" largePadding={true}>
        <div className="whitespace-pre-line font-bold leading-8">{text}</div>
      </Panel>
      <BubblePointer />
    </div>
  )
}

const AffirmationMessage = ({
  message,
  isLoading,
}: {
  message: MessageFragmentFragment
  isLoading: boolean
}) => {
  useMessageUpdatedSubscription(message)
  const text = useMemo(() => sample(AFFIRMATION_TEXT_LINES), [])

  if (isLoading) {
    return (
      <div>
        <QuestionBubble text={text || AFFIRMATION_TEXT_LINES[0]} />
      </div>
    )
  }

  return <MessageContent text={message.text || TIMED_OUT_TEXT} />
}

const Affirmation = ({
  message,
  isLoading,
  hasPlayedAudio,
  setHasPlayedAudio,
  setHasAudioTimedOut,
}: {
  message: MessageFragmentFragment
  hasPlayedAudio: boolean
  isLoading: boolean
  setHasPlayedAudio: (show: boolean) => void
  setHasAudioTimedOut: (show: boolean) => void
}) => {
  const startTime = useRef(new Date().getTime())
  const [seconds, setSeconds] = useState(0)
  const [textTimedOut, setTextTimedOut] = useState(false)

  useEffect(() => {
    if (!hasPlayedAudio && isJobComplete(message.ttsJobStatus)) {
      setHasPlayedAudio(true)
    }
  }, [hasPlayedAudio, message, setHasPlayedAudio])

  const onAudioPlayed = () => {
    if (!hasPlayedAudio) {
      setHasPlayedAudio(true)
      trackEvent("Played Affirmation Audio", {
        delaySeconds: (new Date().getTime() - startTime.current) / 1000,
      })
    }
  }

  useEffect(() => {
    let interval: NodeJS.Timeout | undefined
    if (!hasPlayedAudio) {
      interval = setInterval(() => {
        setSeconds(seconds => seconds + 1)
      }, 1000)
    }

    return () => clearInterval(interval)
  }, [hasPlayedAudio])

  useEffect(() => {
    const extra = {
      messageId: message.id,
      messageTtsJobStatus: message.ttsJobStatus,
      messageJobStatus: message.jobStatus,
      messageText: message.text,
      seconds,
    }

    if (seconds === 10) {
      if (!message.text) {
        Sentry.captureException(new Error("Affirmation text timeout"), {extra})
        setTextTimedOut(true)
      } else if (!hasPlayedAudio) {
        Sentry.captureException(new Error("Affirmation audio timeout"), {extra})
        setHasAudioTimedOut(true)
      }
    }
  }, [seconds, hasPlayedAudio, message, setHasAudioTimedOut])

  if (textTimedOut) {
    return (
      <div>
        <MessageContent text={TIMED_OUT_TEXT} />
      </div>
    )
  }

  return (
    <div>
      <MessageTts messageId={message.id} onAudioPlayed={onAudioPlayed} />
      <AffirmationMessage message={message} isLoading={isLoading} />
    </div>
  )
}

export default Affirmation
