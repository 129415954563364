import ScreenTitle from "~/components/ScreenTitle"
import Container from "~/components/ui/Container"
import AdminDashboard from "../../images/admin-dashboard.jpg"

const AdminDashboardScreen = () => {
  return (
    <Container size="full" centered={false}>
      <ScreenTitle>Admin Dashboard</ScreenTitle>
      <div className="flex w-full items-center justify-center">
        <img src={AdminDashboard} alt="Admin Dashboard" />
      </div>
    </Container>
  )
}

export default AdminDashboardScreen
