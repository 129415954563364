// Env vars are assigned in app/views/spa/_js_env.html.erb

interface Env {
  ENVIRONMENT: string
  ENABLE_SENTRY: string
  SENTRY_DSN: string
  AMPLITUDE_API_KEY: string
  AMPLITUDE_ENABLED: string
}

declare global {
  interface Window {
    env: Env
    gtag: any
  }
}

export default window.env
