import invariant from "tiny-invariant"
import useViewerContext from "./useViewerContext"

const useViewer = () => {
  const value = useViewerContext()
  invariant(value.viewer, "Missing viewer")
  return value.viewer
}

export default useViewer
