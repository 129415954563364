import clsx from "clsx"
import {trackEvent} from "~/util/analytics"
import Link from "./ui/Link"

const Footer = () => {
  return (
    <div
      className={clsx(
        "mt-auto flex flex-col items-center gap-2 px-4 py-3.5 font-sans text-3xs tracking-tight-px text-white opacity-75",
        "sm:flex-row sm:justify-between sm:gap-0"
      )}
    >
      <div>
        © {new Date().getFullYear()} Playstorming LLC. All Rights Reserved
      </div>
      <div
        className={clsx("flex flex-col items-center", "sm:flex-row sm:gap-2")}
      >
        <Link
          to="https://www.playstorming.com/terms-of-use"
          target="_blank"
          color="white"
          onClick={() => trackEvent("Click Terms & Conditions")}
        >
          Terms & Conditions
        </Link>
        <span className={clsx("hidden", "sm:inline")}>|</span>
        <Link
          to="https://www.playstorming.com/privacy-policy"
          target="_blank"
          color="white"
          onClick={() => trackEvent("Click View Privacy Policy")}
        >
          Privacy Policy
        </Link>
      </div>
    </div>
  )
}

export default Footer
