import clsx from "clsx"

const SIZE_CLASSES = {
  sm: clsx("w-full", "sm:w-[448px]"),
  md: clsx("w-full", "md:w-[620px]"),
  lg: clsx("w-full", "xl:max-w-[1024px]"),
}

const COLOR_CLASSES = {
  gray: "bg-latteo-gray",
  green: "bg-brand-green text-black",
  blue: "bg-brand-blue text-white",
  white: "bg-white",
}

const Panel = ({
  size,
  color = "gray",
  largePadding,
  className,
  children,
}: {
  size: keyof typeof SIZE_CLASSES
  color?: keyof typeof COLOR_CLASSES
  largePadding?: boolean
  className?: string
  children: React.ReactNode
}) => {
  return (
    <div
      className={clsx(
        "overflow-hidden rounded-xl p-4",
        "sm:p-6",
        "md:rounded-4xl",
        largePadding ? "md:px-16 md:py-14" : "md:px-6 md:py-8",
        SIZE_CLASSES[size],
        COLOR_CLASSES[color],
        className
      )}
    >
      {children}
    </div>
  )
}

export default Panel
