import {Dialog, Transition} from "@headlessui/react"
import clsx from "clsx"
import {Fragment, useState} from "react"
import {useSearchParams} from "react-router-dom"
import CloseCircleLg from "../images/close-circle-lg.svg"
import Onboarding1 from "../images/onboarding-1.png"
import Onboarding2 from "../images/onboarding-2.png"
import Onboarding3 from "../images/onboarding-3.png"
import Onboarding4 from "../images/onboarding-4.png"
import Onboarding5 from "../images/onboarding-5.png"
import Button from "./ui/Button"

const content = [
  {
    title: "Welcome to Playstorming",
    text: "To get started, you will need to select a Playstorm from your dashboard.",
    image: Onboarding1,
  },
  {
    title: "Record your Response",
    text: "You can use the record button to answer questions, or click on the Type button to enter your response manually.",
    image: Onboarding2,
  },
  {
    title: "AI Generation",
    text: "Once you have finished answering the questions, AI will generate ideas for you.",
    image: Onboarding3,
  },
  {
    title: "Go for quantity over quality",
    text: "The more ideas you generate, the better ideas you'll get!",
    image: Onboarding4,
  },
  {
    title: "Edit & Share",
    text: "You can then edit and share your responses as needed.",
    image: Onboarding5,
  },
]

const Content = ({close}: {close: () => void}) => {
  const [step, setStep] = useState(0)
  const stepContent = content[step]

  return (
    <div className="flex">
      <div className="flex basis-[50%] flex-col p-10 pb-6">
        <div className="mt-7 flex flex-1 flex-col">
          <div className="mb-4 text-2xl font-semibold text-brand-blue">
            {stepContent.title}
          </div>
          <div>{stepContent.text}</div>
        </div>
        <div className="mt-auto flex items-center justify-between">
          <div className="flex items-center">
            {content.map((_, index) => (
              <button
                key={index}
                className={clsx(
                  "flex h-[14px] w-[14px] items-center justify-center hover:opacity-75"
                )}
                onClick={() => setStep(index)}
              >
                <div
                  className={clsx(
                    "h-[5px] w-[5px] rounded-full hover:opacity-75",
                    index === step ? "bg-brand-blue" : "bg-brand-blue/30"
                  )}
                />
              </button>
            ))}
          </div>
          <div className="flex h-[40px] items-center">
            {step < content.length - 1 ? (
              <button
                onClick={() => setStep(step + 1)}
                className="p-2 text-xs font-semibold text-brand-blue hover:opacity-75"
              >
                Next
              </button>
            ) : (
              <Button
                onClick={close}
                rounding="full"
                inline={true}
                minWidth={false}
                size="sm"
              >
                Playstorm
              </Button>
            )}
          </div>
        </div>
      </div>
      <div className="flex basis-[50%]">
        <img
          src={stepContent.image}
          alt={stepContent.title}
          className="object-cover"
        />
      </div>
    </div>
  )
}

const OnboardingModal = () => {
  const [_searchParams, setSearchParams] = useSearchParams()
  const [isOpen, setIsOpen] = useState(true)

  const close = () => {
    setIsOpen(false)
    setSearchParams(new URLSearchParams({}))
  }

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={close}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={clsx(
                  "relative w-full max-w-[650px] transform rounded bg-white text-left align-middle shadow-xl transition-all"
                )}
              >
                <button
                  onClick={close}
                  className={clsx(
                    "absolute left-2 top-2 outline-none",
                    "hover:opacity-75"
                  )}
                  data-test="onboarding-close"
                >
                  <CloseCircleLg />
                </button>
                <Content close={close} />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

export default OnboardingModal
