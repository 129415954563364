import useViewer from "~/hooks/useViewer"
import CreativeDashboardScreen from "./CreativeDashboardScreen"
import AdminDashboardScreen from "./admin/AdminDashboardScreen"

const DashboardScreen = () => {
  const viewer = useViewer()
  if (viewer.admin) return <AdminDashboardScreen />
  return <CreativeDashboardScreen />
}

export default DashboardScreen
