import {Form, useFormikContext} from "formik"
import {useRef} from "react"
import toast from "react-hot-toast"
import * as Yup from "yup"
import {
  QuestionFragmentFragment,
  QuestionTypeEnum,
} from "~/__generated__/graphql"
import Button from "~/components/ui/Button"
import FormikField from "~/components/ui/FormikField"
import useTTSUpload from "~/hooks/useTTSUpload"
import FieldContainer from "./ui/FieldContainer"
import FieldGroup from "./ui/FieldGroup"
import HR from "./ui/HR"
import ValidationError from "./ui/ValidationError"

export const gptModelOptions = ["gpt-4-1106-preview", "gpt-3.5-turbo"].map(
  value => ({label: value, value})
)

export type QuestionFormValues = {
  text: string
  affirmationPrompt: string
  grandRevealPrompt: string
  questionType: string
  gptModel: string
  audio: string
}

export const questionValidationSchema = Yup.object({
  text: Yup.string().when("$questionType", ([other], schema) =>
    other === QuestionTypeEnum.Strengthening
      ? schema
      : schema.required("Text is required")
  ),
  questionType: Yup.string().required("Question type is required"),
  gptModel: Yup.string().required("GPT model is required"),
  affirmationPrompt: Yup.string().when("$questionType", ([other], schema) =>
    other === QuestionTypeEnum.Strengthening
      ? schema
      : schema.required("Affirmation prompt is required")
  ),
  grandRevealPrompt: Yup.string().required("Grand reveal prompt is required"),
})

const questionTypeOptions = [
  {
    label: "Introduction",
    value: QuestionTypeEnum.Intro,
  },
  {
    label: "Warmup",
    value: QuestionTypeEnum.Warmup,
  },
  {
    label: "Round",
    value: QuestionTypeEnum.Round,
  },
  {
    label: "AI Strengthening",
    value: QuestionTypeEnum.Strengthening,
  },
]

const AdminQuestionFields = ({
  close,
  question,
  ttsUpload,
}: {
  close: () => void
  ttsUpload: ReturnType<typeof useTTSUpload>
  question?: QuestionFragmentFragment
}) => {
  const {
    generateAndPlayAudio,
    isGeneratingAudio,
    playGeneratedAudio,
    hasGeneratedAudio,
  } = ttsUpload
  const audioRef = useRef<HTMLAudioElement>(null)
  const {isSubmitting, values, errors} = useFormikContext<QuestionFormValues>()
  const isStrengthening = values.questionType === "strengthening"
  const hasAudio = !!question?.audioUrl || hasGeneratedAudio

  const onClickPlayAudio = () => {
    if (hasGeneratedAudio) {
      playGeneratedAudio()
    } else if (audioRef?.current) {
      audioRef.current.play()
    } else {
      toast.error("Missing audio")
    }
  }

  return (
    <Form>
      <FieldContainer>
        <FieldGroup>
          <FormikField
            as="select"
            options={questionTypeOptions}
            name="questionType"
            label="Question Type"
            placeholder=""
            light={true}
          />
          {!isStrengthening && (
            <FormikField
              name="text"
              label="Question Text"
              as="textarea"
              rows={3}
              light={true}
            />
          )}
          <div className="mb-4">
            <div className="mb-4 flex gap-3">
              <Button
                type="button"
                onClick={() => generateAndPlayAudio(values.text)}
                disabled={isGeneratingAudio || !values.text}
                rounding="default"
                inline={true}
                size="sm"
              >
                {isGeneratingAudio
                  ? "Generating Audio..."
                  : hasAudio
                  ? "Regenerate Audio"
                  : "Generate Audio"}
              </Button>
              <Button
                type="button"
                onClick={onClickPlayAudio}
                disabled={!hasAudio}
                rounding="default"
                inline={true}
                size="sm"
              >
                Play Audio
              </Button>
              {question?.audioUrl && (
                <audio src={question.audioUrl} ref={audioRef} />
              )}
            </div>
            {!!errors.audio && (
              <ValidationError>{errors.audio}</ValidationError>
            )}
          </div>
          {!isStrengthening && (
            <FormikField
              name="affirmationPrompt"
              label="Affirmation Prompt"
              as="textarea"
              rows={3}
              light={true}
            />
          )}
          <FormikField
            name="grandRevealPrompt"
            label={
              isStrengthening
                ? "AI Strengthening Prompt"
                : "Grand Reveal Summary Prompt"
            }
            as="textarea"
            rows={3}
            light={true}
          />
          <FormikField
            as="select"
            options={gptModelOptions}
            name="gptModel"
            label="GPT Model"
            placeholder=""
            light={true}
          />
        </FieldGroup>
      </FieldContainer>
      <div className="mb-6 mt-10">
        <HR dark={true} />
      </div>
      <FieldContainer>
        <div className="flex gap-2">
          <div className="w-full max-w-[150px]">
            <Button
              type="button"
              onClick={close}
              color="outlineDark"
              rounding="full"
              minWidth={false}
            >
              Cancel
            </Button>
          </div>
          <Button
            type="submit"
            disabled={isSubmitting}
            rounding="full"
            color="black"
            minWidth={false}
          >
            Save
          </Button>
        </div>
      </FieldContainer>
    </Form>
  )
}

export default AdminQuestionFields
