import {useNavigate} from "react-router-dom"
import invariant from "tiny-invariant"
import {getFragmentData} from "~/__generated__"
import {
  AnswerTypeEnum,
  PlaystormStepAnswerFragmentFragment,
} from "~/__generated__/graphql"
import useAnswerCreate from "~/hooks/useAnswerCreate"
import {ANSWER_FRAGMENT} from "~/hooks/useAnswerQuery"
import useViewerContext from "~/hooks/useViewerContext"
import {trackEvent} from "~/util/analytics"
import AnswerButton, {AnswerLink} from "./AnswerButton"
import {getAnswerSubmitPath} from "./AnswerEditForm"
import BackIcon from "./icons/back-icon.svg"
import CheckIcon from "./icons/check-icon.svg"
import KeyboardIcon from "./icons/keyboard-icon.svg"
import MicIcon from "./icons/mic-icon.svg"
import NextIcon from "./icons/next-icon.svg"
import RestartIcon from "./icons/restart-icon.svg"
import StopIcon from "./icons/stop-icon.svg"

export const AnswerControlButtonsContainer = ({
  children,
}: {
  children: React.ReactNode
}) => (
  <div className="mt-5 flex items-center justify-center gap-0">{children}</div>
)

export const NextButton = ({
  answer,
  disabled,
}: {
  answer: PlaystormStepAnswerFragmentFragment
  disabled?: boolean
}) => {
  const navigate = useNavigate()
  const [mutate, {loading}] = useAnswerCreate()
  const {refetchViewer} = useViewerContext()

  const skipAnswer = async () => {
    if (answer.id) {
      navigate(getAnswerSubmitPath({answer, skipAffirmation: true}))
    } else {
      const resp = await mutate({
        variables: {
          input: {
            answerInput: {
              answerType: AnswerTypeEnum.Text,
              text: "",
            },
            questionId: answer.questionId,
            playstormId: answer.playstormId,
          },
        },
      })
      const createdAnswer = getFragmentData(
        ANSWER_FRAGMENT,
        resp?.data?.answerCreate?.answer
      )
      const id = createdAnswer?.id
      invariant(id, "Expected answer id")

      if (!answer.nextQuestionId) {
        refetchViewer()
      }

      navigate(
        getAnswerSubmitPath({answer: createdAnswer, skipAffirmation: true})
      )
    }
  }

  return (
    <AnswerButton
      onClick={() => {
        trackEvent("Click Skip Question Button")
        skipAnswer()
      }}
      type="button"
      size="sm"
      label="Next"
      disabled={disabled || loading}
    >
      <NextIcon />
    </AnswerButton>
  )
}

export const TypeButton = ({
  onClick,
  disabled,
}: {
  onClick: () => void
  disabled?: boolean
}) => {
  return (
    <AnswerButton
      onClick={() => {
        trackEvent("Click Type Answer Button")
        onClick()
      }}
      size="sm"
      label="Type"
      type="button"
      disabled={disabled}
    >
      <KeyboardIcon />
    </AnswerButton>
  )
}

export const BackButton = ({onClick}: {onClick: () => void}) => {
  const onMouseDown = (e: React.MouseEvent<HTMLButtonElement>) => {
    // Hack to prevent Formik validation from running. validateOnBlur={false} doesn't work.
    e.preventDefault()
  }

  return (
    <AnswerButton
      onClick={() => {
        trackEvent("Click Switch Back Button")
        onClick()
      }}
      onMouseDown={onMouseDown}
      size="sm"
      label="Switch Back"
      type="button"
    >
      <div className="text-xl">
        <BackIcon />
      </div>
    </AnswerButton>
  )
}

export const ContinueButton = ({
  onClick,
  type = "submit",
  disabled,
}: {
  onClick?: () => void
  type?: "submit" | "button"
  disabled?: boolean
}) => {
  return (
    <AnswerButton
      size="md"
      label="Continue"
      type={type}
      disabled={disabled}
      onClick={onClick}
    >
      <CheckIcon />
    </AnswerButton>
  )
}

export const RecordButton = ({onClick}: {onClick: () => void}) => {
  return (
    <AnswerButton
      size="md"
      label="Record"
      type="button"
      onClick={() => {
        trackEvent("Click Record Button")
        onClick()
      }}
    >
      <MicIcon />
    </AnswerButton>
  )
}

export const ContinueRecordingButton = ({onClick}: {onClick: () => void}) => {
  return (
    <AnswerButton
      size="sm"
      label="Keep Recording"
      type="button"
      onClick={() => {
        trackEvent("Click Keep Recording Button")
        onClick()
      }}
    >
      <div className="scale-[0.7]">
        <MicIcon />
      </div>
    </AnswerButton>
  )
}

export const RestartRecordingButton = ({
  onClick,
  disabled,
}: {
  onClick: () => void
  disabled?: boolean
}) => {
  return (
    <AnswerButton
      size="sm"
      label="Restart Recording"
      type="button"
      onClick={() => {
        trackEvent("Click Restart Recording Button")
        onClick()
      }}
      disabled={disabled}
    >
      <RestartIcon />
    </AnswerButton>
  )
}

export const StopRecordingButton = ({onClick}: {onClick: () => void}) => {
  return (
    <AnswerButton
      size="md"
      label="Stop"
      type="button"
      onClick={() => {
        trackEvent("Click Stop Recording Button")
        onClick()
      }}
      color="white"
    >
      <StopIcon />
    </AnswerButton>
  )
}

export const ContinueLink = ({to}: {to: string}) => {
  return (
    <AnswerLink
      size="md"
      label="Continue"
      to={to}
      onClick={() => {
        trackEvent("Click Affirmation Continue Link")
      }}
    >
      <CheckIcon />
    </AnswerLink>
  )
}
