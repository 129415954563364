import {MutationResult, useMutation, useQuery} from "@apollo/client"
import {Field, Form, FormikProvider, useFormik} from "formik"
import {useEffect, useState} from "react"
import toast from "react-hot-toast"
import {useNavigate} from "react-router-dom"
import invariant from "tiny-invariant"
import {FragmentType, getFragmentData, gql} from "~/__generated__"
import HR from "~/components/ui/HR"
import {
  adminEditTemplatePath,
  adminOrganizationPath,
  adminOrganizationsPath,
  adminUserPath,
} from "~/paths"
import {displayErrors} from "~/util/validations"
import AdminActions, {AdminAction} from "./AdminActions"
import Button from "./ui/Button"
import {ErrorUI} from "./ui/Error"
import FieldContainer from "./ui/FieldContainer"
import FieldGroup from "./ui/FieldGroup"
import FormikField from "./ui/FormikField"
import Modal from "./ui/Modal"
import {TD, TH} from "./ui/Table"
import Link from "./ui/Link"

const SubHeading = ({children}: {children: React.ReactNode}) => {
  return (
    <div className="my-8">
      <div>{children}</div>
      <HR />
    </div>
  )
}

const organizationFragment = gql(/* GraphQL */ `
  fragment OrganizationForm on Organization {
    id
    name
    templates(first: 1000) {
      ...TemplateTable
    }
    members(first: 1000) {
      ...MemberTable
    }
  }
`)

export const OrganizationScreenContent = ({
  organization,
  onRefresh,
}: {
  organization?: FragmentType<typeof organizationFragment>
  onRefresh?: () => void
}) => {
  return (
    <div className="flex flex-col gap-8">
      <div>
        <SubHeading>Organization Details</SubHeading>
        {organization ? (
          <OrganizationUpdateForm
            organization={organization}
            onRefresh={() => {
              invariant(onRefresh, "onRefresh is required")
              onRefresh()
            }}
          />
        ) : (
          <OrganizationCreateForm />
        )}
      </div>
    </div>
  )
}

const updateMutation = gql(/* GraphQL */ `
  mutation OrganizationUpdate($input: OrganizationUpdateInput!) {
    organizationUpdate(input: $input) {
      organization {
        id
        ...OrganizationForm
      }
    }
  }
`)

const OrganizationUpdateForm = (props: {
  organization: FragmentType<typeof organizationFragment>
  onRefresh: () => void
}) => {
  const navigate = useNavigate()
  const organization = getFragmentData(organizationFragment, props.organization)
  const [execMutation, mutationResult] = useMutation(updateMutation, {
    onCompleted: () => {
      navigate(adminOrganizationsPath)
      toast.success("Organization updated successfully!")
    },
  })

  return (
    <>
      <OrganizationForm
        initialValues={{name: organization.name, id: organization.id}}
        onSubmit={values =>
          execMutation({
            variables: {
              input: {id: organization.id, organizationInput: values},
            },
          })
        }
        result={mutationResult}
      />

      <OrganizationTemplates
        organization={props.organization}
        onUpdateOrganization={props.onRefresh}
      />

      <OrganizationMembers
        organization={props.organization}
        onUpdateOrganization={props.onRefresh}
      />
    </>
  )
}

const organizationTemplatesQuery = gql(/* GraphQL */ `
  query OrganizationTemplatesQuery {
    templates(visibility: ORGANIZATION_MEMBERS, first: 1000) {
      edges {
        node {
          id
          title
        }
      }
    }
  }
`)

const addTemplatesMutation = gql(/* GraphQL */ `
  mutation OrganizationAddTemplates($input: AddOrganizationTemplateInput!) {
    addOrganizationTemplate(input: $input) {
      templates {
        id
      }
    }
  }
`)

const OrganizationTemplates = (props: {
  organization: FragmentType<typeof organizationFragment>
  /** e.g. a template or user has been added or removed */
  onUpdateOrganization: () => void
}) => {
  const organization = getFragmentData(organizationFragment, props.organization)
  const [isTemplateModalOpen, setIsTemplateModalOpen] = useState(false)
  const result = useQuery(organizationTemplatesQuery)
  const [execMutation, mutationResult] = useMutation(addTemplatesMutation, {
    onCompleted: () => {
      props.onUpdateOrganization()
    },
  })
  const formik = useFormik({
    initialValues: {
      templates: [] as Array<string>,
    },
    onSubmit: values => {
      execMutation({
        variables: {
          input: {
            organizationId: organization.id,
            templateIds: values.templates,
          },
        },
      })
    },
  })

  const templates = result.data?.templates.edges.map(edge => edge.node) ?? []

  const orgTemplates = getFragmentData(
    templateTableFragment,
    organization.templates
  ).edges.map(e => e.node)

  const availableTemplates = templates.filter(
    template =>
      !orgTemplates.some(orgTemplate => orgTemplate.id === template.id)
  )

  return (
    <>
      <div className="mb-5 mt-8 flex w-full items-center border-b pb-2">
        <div className="flex-1">
          <div>Templates visible to this organization</div>
        </div>
        <div className="ms-auto">
          <Button
            type="button"
            onClick={() => {
              setIsTemplateModalOpen(true)
            }}
          >
            Add Template
          </Button>
          {isTemplateModalOpen && (
            <Modal
              title="Add template"
              onClose={() => {
                formik.resetForm()
                setIsTemplateModalOpen(false)
              }}
            >
              <FormikProvider value={formik}>
                <Form className="flex min-h-[250px] flex-col">
                  {result.loading ? (
                    <div>Loading...</div>
                  ) : result.error ? (
                    <ErrorUI message="Error loading templates" />
                  ) : availableTemplates ? (
                    <>
                      {availableTemplates.length === 0 ? (
                        <div>No templates available to add</div>
                      ) : null}
                      {availableTemplates.map(template => (
                        <div
                          key={template.id}
                          className="mb-2 flex items-center gap-2 border-b pb-2"
                        >
                          <div className="px-2">
                            <Field
                              type="checkbox"
                              name="templates"
                              value={template.id}
                            >
                              {({field, form}: any) => (
                                <input
                                  id={`template-${template.id}`}
                                  type="checkbox"
                                  {...field}
                                  checked={form.values.templates.includes(
                                    template.id
                                  )}
                                  onChange={() => {
                                    if (
                                      form.values.templates.includes(
                                        template.id
                                      )
                                    ) {
                                      const nextValue =
                                        form.values.templates.filter(
                                          (value: any) => value !== template.id
                                        )
                                      form.setFieldValue("templates", nextValue)
                                    } else {
                                      const nextValue = [
                                        ...form.values.templates,
                                        template.id,
                                      ]
                                      form.setFieldValue("templates", nextValue)
                                    }
                                  }}
                                />
                              )}
                            </Field>
                          </div>
                          <label htmlFor={`template-${template.id}`}>
                            {template.title}
                          </label>
                        </div>
                      ))}
                    </>
                  ) : null}
                  <div className="mt-auto pt-4">
                    <Button
                      type="submit"
                      disabled={
                        result.loading || formik.values.templates.length === 0
                      }
                    >
                      Add Templates
                    </Button>
                    {mutationResult.error ? (
                      <ErrorUI message="Error adding templates" />
                    ) : null}
                  </div>
                </Form>
              </FormikProvider>
            </Modal>
          )}
        </div>
      </div>
      <TemplateTable
        templates={organization.templates}
        organizationId={organization.id}
        onUpdate={props.onUpdateOrganization}
      />
    </>
  )
}

const createMutation = gql(/* GraphQL */ `
  mutation OrganizationCreate($input: OrganizationCreateInput!) {
    organizationCreate(input: $input) {
      organization {
        id
        ...OrganizationForm
      }
    }
  }
`)

const OrganizationCreateForm = () => {
  const navigate = useNavigate()
  const [execMutation, result] = useMutation(createMutation, {
    onCompleted: data => {
      navigate(
        adminOrganizationPath({id: data.organizationCreate.organization.id})
      )
      toast.success("Organization created successfully!")
    },
  })
  return (
    <OrganizationForm
      initialValues={{name: ""}}
      onSubmit={values =>
        execMutation({
          variables: {input: {organizationInput: values}},
        })
      }
      result={result}
    />
  )
}

type OrganizationFormFields = {
  id?: string
  name: string
}

const OrganizationForm = (props: {
  initialValues: OrganizationFormFields
  onSubmit: (values: OrganizationFormFields) => void
  result: MutationResult
}) => {
  const formik = useFormik({
    initialValues: props.initialValues,
    onSubmit: props.onSubmit,
  })

  useEffect(() => {
    if (props.result.error) {
      displayErrors(props.result.error.graphQLErrors, formik.setFieldError)
    }
  }, [formik.setFieldError, props.result.error])

  const isPersisted = props.initialValues.id != null
  const isNew = !isPersisted

  return (
    <FormikProvider value={formik}>
      <Form>
        <FieldContainer>
          <FieldGroup>
            <FormikField name="name" label="Organization Title" light={true} />
          </FieldGroup>
          <div className="mt-8 flex items-center gap-2">
            <Button
              type="submit"
              disabled={props.result.loading}
              rounding="full"
            >
              {isNew ? "Create Organization" : "Save Organization"}
            </Button>
          </div>

          {isNew ? (
            <div className="mt-4 text-sm opacity-40">
              You’ll be able to invite creatives and add Templates after
              creating an Organization.
            </div>
          ) : null}
        </FieldContainer>
      </Form>
    </FormikProvider>
  )
}

const templateTableFragment = gql(/* GraphQL */ `
  fragment TemplateTable on TemplateConnection {
    edges {
      node {
        id
        title
        status
      }
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
`)

const EmptyList: React.FC<{children: React.ReactNode}> = props => {
  return <div className="text-sm opacity-50" {...props} />
}

const removeTemplateMutation = gql(/* GraphQL */ `
  mutation RemoveTemplateFromOrganization(
    $input: RemoveOrganizationTemplateInput!
  ) {
    removeOrganizationTemplate(input: $input) {
      clientMutationId
    }
  }
`)

const TemplateTable = (props: {
  templates: FragmentType<typeof templateTableFragment>
  organizationId: string
  onUpdate: () => void
}) => {
  const [removeTemplate] = useMutation(removeTemplateMutation, {
    onCompleted: () => {
      props.onUpdate()
    },
  })

  const templates = getFragmentData(
    templateTableFragment,
    props.templates
  ).edges.map(e => e.node)

  if (templates.length === 0) {
    return <EmptyList>No templates in this organization</EmptyList>
  }

  return (
    <table className="w-full">
      <thead>
        <tr>
          <TH>Template Title</TH>
          <TH>Status</TH>
          <TH align="right">Actions</TH>
        </tr>
      </thead>
      <tbody>
        {templates.map(template => (
          <tr key={template.id}>
            <TD>
              <Link to={adminEditTemplatePath({id: template.id})}>
                {template.title}
              </Link>
            </TD>

            <TD>{template.status}</TD>
            <TD align="right">
              <AdminActions>
                <AdminAction
                  onClick={() => {
                    removeTemplate({
                      variables: {
                        input: {
                          templateId: template.id,
                          organizationId: props.organizationId,
                        },
                      },
                    })
                  }}
                >
                  Remove from Org
                </AdminAction>
              </AdminActions>
            </TD>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

const memberTableFragment = gql(/* GraphQL */ `
  fragment MemberTable on UserConnection {
    edges {
      node {
        id
        name
        email
      }
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
`)

const removeMemberMutation = gql(/* GraphQL */ `
  mutation RemoveMemberFromOrganization(
    $input: RemoveOrganizationMemberInput!
  ) {
    removeOrganizationMember(input: $input) {
      clientMutationId
    }
  }
`)

const OrganizationMembers = (props: {
  organization: FragmentType<typeof organizationFragment>
  onUpdateOrganization: () => void
}) => {
  const organization = getFragmentData(organizationFragment, props.organization)
  const members = getFragmentData(
    memberTableFragment,
    organization.members
  ).edges.map(e => e.node)

  const [removeMember] = useMutation(removeMemberMutation, {
    onCompleted: () => {
      props.onUpdateOrganization()
    },
    onError: error => {
      displayErrors(error.graphQLErrors)
    },
  })

  return (
    <div className="mt-8 pb-8">
      <InviteMembersCard
        organizationId={organization.id}
        onUpdateOrganization={props.onUpdateOrganization}
      />
      <div className="mb-4 border-b pb-2">Members</div>
      {members.length === 0 ? (
        <EmptyList>No members in this organization</EmptyList>
      ) : (
        <table className="w-full">
          <thead>
            <tr>
              <TH>User Name</TH>
              <TH align="right">Actions</TH>
            </tr>
          </thead>
          <tbody>
            {members.map(user => (
              <tr key={user.id}>
                <TD>
                  <Link to={adminUserPath({id: user.id})}>{user.email}</Link>
                </TD>
                <TD align="right">
                  <AdminActions>
                    <AdminAction
                      onClick={() => {
                        removeMember({
                          variables: {
                            input: {
                              userId: user.id,
                              organizationId: organization.id,
                            },
                          },
                        })
                      }}
                    >
                      Remove from Org
                    </AdminAction>
                  </AdminActions>
                </TD>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  )
}

const inviteMembersMutation = gql(/* GraphQL */ `
  mutation InviteMembers($input: InviteOrganizationMemberInput!) {
    inviteOrganizationMember(input: $input) {
      clientMutationId
      users {
        id
      }
      organizationInvitationCount
      errors
    }
  }
`)

const InviteMembersCard: React.FC<{
  organizationId: string
  onUpdateOrganization: () => void
}> = props => {
  const formik = useFormik({
    initialValues: {emails: ""},
    onSubmit: () => {
      execMutation({
        variables: {
          input: {
            organizationId: props.organizationId,
            emails: formik.values.emails.split(",").map(e => e.trim()),
          },
        },
      })
    },
  })

  const [execMutation, result] = useMutation(inviteMembersMutation, {
    onCompleted: data => {
      data.inviteOrganizationMember.errors.forEach(error => {
        toast.error(error)
      })
      let immediatelyAddedCount = data.inviteOrganizationMember.users.length
      let emailed = data.inviteOrganizationMember.organizationInvitationCount

      let message
      if (immediatelyAddedCount > 0 && emailed > 0) {
        message = `Added ${immediatelyAddedCount} existing members and sent ${emailed} invitations`
      } else if (immediatelyAddedCount > 0) {
        message = `Added ${immediatelyAddedCount} existing members`
      } else if (emailed > 0) {
        message = `Sent ${emailed} invitations`
      }

      if (message) {
        toast.success(message)
      }

      formik.resetForm()
      props.onUpdateOrganization()
    },
  })

  const isEmails =
    formik.values.emails
      .split(",")
      .map(e => e.trim())
      .filter(Boolean).length === 0

  return (
    <FormikProvider value={formik}>
      <Form>
        <div className="flex flex-col border">
          <div className="border-b p-3">Invite Creatives</div>
          <div className="flex">
            <Field
              as="textarea"
              name="emails"
              placeholder="Email addresses separated by commas…"
              className="m-0 flex h-24 w-full resize-none rounded-md p-3"
            />
          </div>
        </div>

        {result.error ? <ErrorUI message="Error inviting members" /> : null}
        <div className="flex w-full">
          <Button
            type="submit"
            className="ml-auto mt-4 w-auto"
            disabled={isEmails || result.loading}
          >
            {result.loading ? "Inviting…" : "Invite"}
          </Button>
        </div>
      </Form>
    </FormikProvider>
  )
}
