import {useQuery} from "@apollo/client"
import {getFragmentData, gql} from "~/__generated__"

export const ADMIN_USER_FRAGMENT = gql(`
  fragment AdminUserFragment on User {
    id
    email
    name
    firstName
    lastName
    organization
    organizationRole
    structuredOrganization {
      id
      name
    }
    admin
    status
    playstormsCount
    lastPlaystorm {
      id
      createdAt
    }
    onboardingSurveyAnswers {
      nodes {
        ...SurveyAnswerFragment
      }
    }
  }
`)

const GET_ADMIN_USER_QUERY = gql(`
  query AdminUser($id: ID!) {
    user(id: $id) {
      ...AdminUserFragment
    }
  }
`)

const useAdminUserQuery = (id: string) => {
  const {data, loading, error} = useQuery(GET_ADMIN_USER_QUERY, {
    variables: {
      id,
    },
  })
  const user = getFragmentData(ADMIN_USER_FRAGMENT, data?.user)

  return {
    user,
    data,
    loading,
    error,
  }
}

export default useAdminUserQuery
