import React from "react"
import {useSearchParams} from "react-router-dom"
import {TemplateVisibility} from "~/__generated__/graphql"
import CreativeTemplateList from "~/components/CreativeTemplateList"
import OnboardingModal from "~/components/OnboardingModal"
import ScreenTitle from "~/components/ScreenTitle"
import Container from "~/components/ui/Container"
import Error from "~/components/ui/Error"
import Loading from "~/components/ui/Loading"
import useTemplatesQuery from "~/hooks/useTemplatesQuery"
import useTrackPage from "~/hooks/useTrackPage"
import useViewer from "~/hooks/useViewer"
import background from "~/images/backgrounds/creative-dashboard-bg.jpg"

const CreativeDashboardScreen = () => {
  useTrackPage("Creative Dashboard")
  const viewer = useViewer()
  const everyoneTemplatesResult = useTemplatesQuery({
    visibility: TemplateVisibility.Everyone,
  })
  const organizationTemplatesResult = useTemplatesQuery({
    visibility: TemplateVisibility.OrganizationMembers,
    skip: viewer.structuredOrganization == null,
  })
  const individualTemplatesResult = useTemplatesQuery({
    visibility: TemplateVisibility.Individuals,
    skip: viewer.structuredOrganization != null,
  })
  const [searchParams] = useSearchParams()

  const loading =
    everyoneTemplatesResult.loading ||
    organizationTemplatesResult.loading ||
    individualTemplatesResult.loading

  const error =
    everyoneTemplatesResult.error ||
    organizationTemplatesResult.error ||
    individualTemplatesResult.error

  if (loading) {
    return <Loading />
  }
  if (error) {
    return <Error message="Error loading dashboard" />
  }

  return (
    <div className="flex flex-1 flex-col">
      {searchParams.get("onboarding") && <OnboardingModal />}
      <Container size="full" centered={false}>
        {viewer.structuredOrganization &&
        organizationTemplatesResult.templates.length > 0 ? (
          <div className="mb-12">
            <ScreenTitle className="mb-6 md:mb-6">
              {viewer.structuredOrganization.name} Playstorms
            </ScreenTitle>
            <CreateTemplateContainer>
              <CreativeTemplateList
                templates={organizationTemplatesResult.templates}
                fetchNextPage={organizationTemplatesResult.fetchNextPage}
                hasNextPage={organizationTemplatesResult.hasNextPage!!}
                loading={organizationTemplatesResult.loading}
              />
            </CreateTemplateContainer>
          </div>
        ) : null}

        {viewer.structuredOrganization == null && (
          <div className="mb-12">
            <ScreenTitle className="mb-6 md:mb-6">My Playstorms</ScreenTitle>
            <CreateTemplateContainer>
              <CreativeTemplateList
                templates={individualTemplatesResult.templates}
                fetchNextPage={individualTemplatesResult.fetchNextPage}
                hasNextPage={individualTemplatesResult.hasNextPage!!}
                loading={individualTemplatesResult.loading}
              />
            </CreateTemplateContainer>
          </div>
        )}

        {everyoneTemplatesResult.templates.length > 0 && (
          <>
            <ScreenTitle className="mb-6 md:mb-6">
              Public Playstorms
            </ScreenTitle>
            <CreateTemplateContainer>
              <CreativeTemplateList
                templates={everyoneTemplatesResult.templates}
                fetchNextPage={everyoneTemplatesResult.fetchNextPage}
                hasNextPage={everyoneTemplatesResult.hasNextPage!!}
                loading={everyoneTemplatesResult.loading}
              />
            </CreateTemplateContainer>
          </>
        )}
      </Container>
      <div className="mt-auto pt-[105px]">
        <img src={background} alt="Background" className="h-auto w-full" />
      </div>
    </div>
  )
}

const CreateTemplateContainer: React.FC<{
  children: React.ReactNode
}> = props => {
  return <div className="flex flex-col gap-4" {...props} />
}

export default CreativeDashboardScreen
