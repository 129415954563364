import clsx from "clsx"

const SIZES = {
  md: clsx("text-xl", "sm:text-3xl"),
  lg: clsx("text-2xl", "sm:text-4xl md:leading-11"),
}

type HeadingProps = {
  id?: string
  center?: boolean
  size?: keyof typeof SIZES
  className?: string
  children: React.ReactNode
}

export const H1 = ({
  id,
  center,
  size = "md",
  className,
  children,
}: HeadingProps) => {
  return (
    <h1
      className={clsx("font-bold text-brand-blue", SIZES[size], className, {
        "text-center": center,
      })}
      id={id}
    >
      {children}
    </h1>
  )
}
