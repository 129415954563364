import {Helmet} from "react-helmet"
import EditPasswordForm from "~/components/EditPasswordForm"
import ScreenTitle from "~/components/ScreenTitle"
import Container from "~/components/ui/Container"
import DashboardPanel from "~/components/ui/DashboardPanel"

const CreativeEditPasswordScreen = () => {
  return (
    <Container size="full" centered={false} className="flex flex-1 flex-col">
      <Helmet>
        <title>Edit Password</title>
      </Helmet>
      <ScreenTitle>Edit Password</ScreenTitle>
      <div className="mt-10 flex flex-col gap-8">
        <DashboardPanel title="Password" color="white">
          <EditPasswordForm />
        </DashboardPanel>
      </div>
    </Container>
  )
}

export default CreativeEditPasswordScreen
