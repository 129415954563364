import {useMutation} from "@apollo/client"
import clsx from "clsx"
import {Form, Formik, FormikHelpers, FormikProps} from "formik"
import {toast} from "react-hot-toast"
import {useNavigate} from "react-router-dom"
import * as Yup from "yup"
import {gql} from "~/__generated__"
import Button from "~/components/ui/Button"
import FormikField from "~/components/ui/FormikField"
import {H1} from "~/components/ui/Headings"
import Link from "~/components/ui/Link"
import {logInPath} from "~/paths"
import {displayErrors} from "~/util/validations"
import Logo from "./Logo"
import Panel from "./ui/Panel"

const FORGOT_PASSWORD_MUTATION = gql(`
  mutation ForgotPassword($input: ForgotPasswordInput!) {
    forgotPassword(input: $input) {
      email
    }
  }
`)

type Values = {
  email: string
}

const initialValues = {
  email: "",
}

const validationSchema = Yup.object({
  email: Yup.string().email("Email is invalid").required("Email is required"),
})

const ForgotPasswordForm = () => {
  const navigate = useNavigate()
  const [mutate] = useMutation(FORGOT_PASSWORD_MUTATION)

  const onSubmit = async (
    values: Values,
    {setFieldError}: FormikHelpers<Values>
  ) => {
    return await mutate({
      variables: {
        input: {
          email: values.email,
        },
      },
      onCompleted: () => {
        navigate(logInPath)
        toast.success(
          "Check your email for instructions to reset your password."
        )
      },
      onError: ({graphQLErrors}) => {
        displayErrors(graphQLErrors, setFieldError)
      },
    })
  }

  return (
    <div>
      <div className="mb-12 flex justify-center">
        <Link to="/">
          <Logo />
        </Link>
      </div>
      <Panel size="sm">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnBlur={false}
          onSubmit={onSubmit}
        >
          {({isSubmitting}: FormikProps<Values>) => (
            <Form>
              <div className="flex flex-col gap-8">
                <div className="flex flex-col gap-4">
                  <H1 center={true} id="reset-password">
                    Reset Your Password
                  </H1>
                  <div className="w-full text-center">
                    <div className={clsx("mx-auto px-4", "sm:max-w-[320px]")}>
                      Have no fear. We'll email you instructions to reset your
                      password.
                    </div>
                  </div>
                </div>
                <div className="flex flex-col gap-4">
                  <FormikField
                    name="email"
                    type="email"
                    label="Email Address"
                    placeholder="Email Address"
                    autoFocus={true}
                  />
                  <Button type="submit" disabled={isSubmitting}>
                    Request Instructions
                  </Button>
                </div>
                <div className="my-1 flex flex-col items-center justify-center sm:flex-row sm:gap-5">
                  <Link to={logInPath}>Log In</Link>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Panel>
    </div>
  )
}

export default ForgotPasswordForm
