import clsx from "clsx"

const PlaystormOverviewActionButtons = ({
  children,
}: {
  children: React.ReactNode
}) => (
  <div
    className={clsx(
      "-mx-10 mt-8 flex justify-stretch divide-x divide-black/30 border-t border-black/30"
    )}
    children={children}
  />
)

export default PlaystormOverviewActionButtons
