import {Helmet} from "react-helmet"
import ScreenTitle from "~/components/ScreenTitle"
import TemplatesTable from "~/components/TemplatesTable"
import {ButtonLink} from "~/components/ui/Button"
import Container from "~/components/ui/Container"
import Error from "~/components/ui/Error"
import Loading from "~/components/ui/Loading"
import useTemplatesQuery from "~/hooks/useTemplatesQuery"
import {adminNewTemplatePath} from "~/paths"

const TITLE = "Playstorm Templates"

const AdminTemplatesScreen = () => {
  const {templates, loading, error, hasNextPage, fetchNextPage} =
    useTemplatesQuery()

  if (loading) return <Loading />
  if (error) return <Error message="Error loading templates" />

  return (
    <Container size="full" centered={false}>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <ScreenTitle
        actions={
          <ButtonLink to={adminNewTemplatePath} rounding="full">
            New Template
          </ButtonLink>
        }
      >
        {TITLE}
      </ScreenTitle>
      <TemplatesTable
        templates={templates}
        hasNextPage={hasNextPage!!}
        fetchNextPage={fetchNextPage}
        loading={loading}
      />
    </Container>
  )
}

export default AdminTemplatesScreen
