import Button from "./Button"
import FieldContainer from "./FieldContainer"
import Modal from "./Modal"

const ConfirmModal = ({
  onClose,
  onConfirm,
  "data-test": dataTest,
  children,
}: {
  onClose: () => void
  onConfirm: () => void
  "data-test"?: string
  children: React.ReactNode
}) => {
  const onConfirmClick = () => {
    onConfirm()
    onClose()
  }

  return (
    <Modal
      title=""
      onClose={onClose}
      data-test={dataTest}
      hideCloseButton={true}
    >
      <div className="mb-7 mt-1 text-center">{children}</div>
      <div className="flex justify-center">
        <FieldContainer>
          <div className="flex gap-2">
            <div className="w-full max-w-[150px]">
              <Button
                type="button"
                onClick={onClose}
                color="outlineDark"
                rounding="full"
                minWidth={false}
              >
                Cancel
              </Button>
            </div>
            <Button
              type="button"
              onClick={onConfirmClick}
              rounding="full"
              color="black"
              minWidth={false}
            >
              Yes
            </Button>
          </div>
        </FieldContainer>
      </div>
    </Modal>
  )
}

export default ConfirmModal
