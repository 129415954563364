import {Form, Formik, FormikHelpers, useFormikContext} from "formik"
import {useNavigate} from "react-router-dom"
import invariant from "tiny-invariant"
import * as Yup from "yup"
import {getFragmentData} from "~/__generated__"
import usePlaystormCreate from "~/hooks/usePlaystormCreate"
import {PLAYSTORM_FRAGMENT} from "~/hooks/usePlaystormQuery"
import useViewerContext from "~/hooks/useViewerContext"
import {playstormQuestionPath} from "~/paths"
import {trackEvent} from "~/util/analytics"
import {displayErrors} from "~/util/validations"
import Button from "./ui/Button"
import FieldContainer from "./ui/FieldContainer"
import FieldGroup from "./ui/FieldGroup"
import FormikField from "./ui/FormikField"

export type PlaystormFormValues = {
  title: string
}

export const playstormValidationSchema = Yup.object({
  title: Yup.string().required("Title is required"),
})

const PlaystormFields = () => {
  const {isSubmitting, isValid} = useFormikContext<PlaystormFormValues>()

  return (
    <Form>
      <div className="flex justify-center text-left">
        <FieldContainer>
          <FieldGroup>
            <FormikField
              name="title"
              label="Your Playstorm Title"
              inputClassName="text-brand-blue"
              autoFocus={true}
              size="lg"
            />
          </FieldGroup>
          <div className="mt-10 flex justify-center">
            <Button
              type="submit"
              disabled={isSubmitting || !isValid}
              rounding="full"
              size="lg"
              inline={true}
            >
              Playstorm!
            </Button>
          </div>
        </FieldContainer>
      </div>
    </Form>
  )
}

const PlaystormCreateForm = ({
  templateId,
  templateTitle,
}: {
  templateId: string
  templateTitle: string
}) => {
  const navigate = useNavigate()
  const [mutate] = usePlaystormCreate()
  const {refetchViewer} = useViewerContext()

  const initialValues = {
    title: `${templateTitle} ${new Date().toLocaleDateString()}`,
  }

  const createTemplate = async (
    values: PlaystormFormValues,
    {setFieldError}: FormikHelpers<PlaystormFormValues>
  ) => {
    try {
      if (values.title !== initialValues.title) {
        trackEvent("Changed Playstorm Title")
      }

      const resp = await mutate({
        variables: {
          input: {
            playstormInput: {
              title: values.title,
            },
            templateId: templateId,
          },
        },
      })
      const createdPlaystorm = getFragmentData(
        PLAYSTORM_FRAGMENT,
        resp?.data?.playstormCreate.playstorm
      )
      refetchViewer()

      const id = createdPlaystorm?.id
      invariant(id, "Expected playstorm id")
      const firstQuestionId = resp?.data?.playstormCreate.firstQuestionId
      invariant(firstQuestionId, "Expected nextQuestionId")

      navigate(playstormQuestionPath({id, questionId: firstQuestionId}))
    } catch (error: any) {
      displayErrors(error?.graphQLErrors, setFieldError)
    }
  }

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={playstormValidationSchema}
        validateOnBlur={false}
        onSubmit={createTemplate}
      >
        <PlaystormFields />
      </Formik>
    </div>
  )
}

export default PlaystormCreateForm
