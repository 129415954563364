import * as Sentry from "@sentry/react"
import {isRouteErrorResponse, useRouteError} from "react-router-dom"

const ErrorBoundary = () => {
  const error = useRouteError()
  if (isRouteErrorResponse(error)) {
    return (
      <div>
        <h1>{error.status}</h1>
      </div>
    )
  }

  Sentry.captureException(error)

  return <div>An unknown error has occurred</div>
}

export default ErrorBoundary
