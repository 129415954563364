import clsx from "clsx"
import {Link} from "react-router-dom"

const SIZE_CLASSES = {
  sm: "leading-7 w-12 h-12",
  md: "leading-9 w-16 h-16",
}

const COLOR_CLASSES = {
  blue: "bg-brand-blue group-hover:bg-brand-blue/80 group-active:bg-brand-blue/70",
  white: "text-brand-blue bg-white border border-[5px] border-brand-blue",
  transparent:
    "bg-transparent border border-[5px] rounded-full border-white group-hover:opacity-80 group-active:opacity-70",
}

type AnswerButtonType = {
  label: string
  size: keyof typeof SIZE_CLASSES
  color?: keyof typeof COLOR_CLASSES
  disabled?: boolean
  children: React.ReactNode
}

const Content = ({
  size,
  color = "blue",
  label,
  children,
}: Pick<AnswerButtonType, "size" | "label" | "color" | "children">) => {
  return (
    <div className="flex flex-col items-center gap-2.5 text-2xs font-bold uppercase">
      <div
        className={clsx(
          "flex items-center justify-center rounded-full",
          SIZE_CLASSES[size],
          COLOR_CLASSES[color]
        )}
      >
        {children}
      </div>
      {label}
    </div>
  )
}

const ButtonLinkContainer = ({children}: {children: React.ReactNode}) => {
  return (
    <div className="flex min-w-[105px] flex-col items-center justify-center">
      {children}
    </div>
  )
}

export const AnswerLink = ({
  to,
  label,
  size,
  color,
  disabled,
  onClick,
  children,
}: AnswerButtonType & {to: string; onClick?: () => void}) => {
  return (
    <ButtonLinkContainer>
      <Link
        to={to}
        onClick={onClick}
        className={clsx("group", {"pointer-events-none opacity-30": disabled})}
      >
        <Content label={label} size={size} color={color} children={children} />
      </Link>
    </ButtonLinkContainer>
  )
}

export const AnswerButton = ({
  onClick,
  onMouseDown,
  label,
  size = "md",
  color,
  type,
  disabled,
  children,
}: AnswerButtonType & {
  onClick?: () => void
  onMouseDown?: (e: React.MouseEvent<HTMLButtonElement>) => void
  type?: "button" | "submit"
}) => {
  return (
    <ButtonLinkContainer>
      <button
        onClick={onClick}
        onMouseDown={onMouseDown}
        type={type}
        disabled={disabled}
        className={clsx("group", "disabled:opacity-30")}
      >
        <Content label={label} size={size} color={color} children={children} />
      </button>
    </ButtonLinkContainer>
  )
}

export default AnswerButton
