import {SurveyQuestionFragmentFragment} from "~/__generated__/graphql"
import useSurveyAnswersQuery from "~/hooks/useSurveyAnswersQuery"
import SurveyAnswerCreateForm from "./SurveyAnswerCreateForm"
import SurveyAnswerUpdateForm from "./SurveyAnswerUpdateForm"
import Error from "./ui/Error"
import Loading from "./ui/Loading"

// The form accepts a single answer for multiple choice questions,
// and accepts multiple answers for rating/value questions.

const SurveyAnswerForm = ({
  surveyQuestion,
  showHeader = true,
  title,
  playstormId,
  playstormTemplateId,
}: {
  surveyQuestion: SurveyQuestionFragmentFragment
  showHeader?: boolean
  title?: string
  playstormId?: string
  playstormTemplateId?: string
}) => {
  const {surveyAnswers, loading, error} = useSurveyAnswersQuery(
    surveyQuestion.id,
    playstormId
  )
  if (loading) return <Loading />
  if (error) return <Error message="Error loading answers" />

  return (
    <div>
      {showHeader && title && (
        <div className="mb-3 text-center text-3xl font-semibold text-brand-blue">
          {title}
        </div>
      )}
      {showHeader && (
        <div className="mx-auto mb-8 max-w-[360px] text-center">
          {surveyQuestion.title}
        </div>
      )}
      {surveyAnswers?.length > 0 ? (
        <SurveyAnswerUpdateForm
          surveyAnswers={surveyAnswers}
          surveyQuestion={surveyQuestion}
          playstormId={playstormId}
          playstormTemplateId={playstormTemplateId}
        />
      ) : (
        <SurveyAnswerCreateForm
          surveyQuestion={surveyQuestion}
          playstormId={playstormId}
          playstormTemplateId={playstormTemplateId}
        />
      )}
    </div>
  )
}

export default SurveyAnswerForm
