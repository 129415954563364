import {
  SurveyAnswerFragmentFragment,
  SurveyLocationEnum,
  SurveyQuestionTypeEnum,
} from "~/__generated__/graphql"
import useSurveyQuestionsQuery from "~/hooks/useSurveyQuestionsQuery"
import DashboardPanel from "./ui/DashboardPanel"
import Error from "./ui/Error"
import Loading from "./ui/Loading"
import AdminQuestionChoice from "./AdminQuestionChoice"
import AdminQuestionValue from "./AdminQuestionValue"

export const AdminOnboardingSurveyAnswers = ({
  surveyAnswers,
}: {
  surveyAnswers: SurveyAnswerFragmentFragment[]
}) => {
  const {surveyQuestions, loading, error} = useSurveyQuestionsQuery({
    variables: {
      surveyLocation: SurveyLocationEnum.Onboarding,
    },
  })

  if (loading) return <Loading />
  if (error) return <Error />

  return (
    <DashboardPanel title="Onboarding Survey">
      <div className="flex flex-col gap-6">
        {surveyQuestions?.map(question =>
          question.surveyQuestionType === SurveyQuestionTypeEnum.Value ? (
            <AdminQuestionValue
              key={question.id}
              question={question}
              surveyAnswers={surveyAnswers.filter(
                surveyAnswer => surveyAnswer.surveyQuestionId === question.id
              )}
            />
          ) : (
            <AdminQuestionChoice
              key={question.id}
              question={question}
              surveyAnswer={surveyAnswers.find(
                surveyAnswer => surveyAnswer.surveyQuestionId === question.id
              )}
            />
          )
        )}
      </div>
    </DashboardPanel>
  )
}

export default AdminOnboardingSurveyAnswers
