import {useMutation, useQuery} from "@apollo/client"
import {useState} from "react"
import {Helmet} from "react-helmet"
import {Link} from "react-router-dom"
import invariant from "tiny-invariant"
import {gql} from "~/__generated__"
import AdminActions, {AdminAction} from "~/components/AdminActions"
import ScreenTitle from "~/components/ScreenTitle"
import Button, {ButtonLink} from "~/components/ui/Button"
import ConfirmModal from "~/components/ui/ConfirmModal"
import Container from "~/components/ui/Container"
import Error from "~/components/ui/Error"
import Loading from "~/components/ui/Loading"
import {TD, TH} from "~/components/ui/Table"
import {adminNewOrganizationPath, adminOrganizationPath} from "~/paths"
import {dateTimeShort} from "~/util/dates"
import {displayErrors} from "~/util/validations"

const TITLE = "Organizations"

const query = gql(/* GraphQL */ `
  query AdminOrganizationsScreenQuery($after: String) {
    organizations(first: 50, after: $after) {
      edges {
        node {
          id
          name
          membersCount
          templatesCount
          createdAt
          updatedAt
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`)

const deleteOrganizationMutation = gql(/* GraphQL */ `
  mutation AdminOrganizationsScreenDeleteOrganizationMutation($id: ID!) {
    organizationDelete(input: {id: $id}) {
      clientMutationId
    }
  }
`)

const AdminOrganizationsScreen = () => {
  const result = useQuery(query, {variables: {after: null}})
  const [deleteOrganization] = useMutation(deleteOrganizationMutation, {
    onCompleted: () => {
      result.refetch()
    },
    onError: err => {
      displayErrors(err.graphQLErrors)
    },
  })

  const [confirmDelete, setConfirmDelete] = useState<{
    id: string
    name: string
  } | null>(null)

  if (result.loading) return <Loading />
  if (result.error) return <Error message="Error loading users" />

  invariant(result.data, "Data should be available")

  const organizations = result.data.organizations.edges.map(edge => edge.node)

  const {hasNextPage, endCursor} = result.data.organizations.pageInfo

  return (
    <Container size="full" centered={false}>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <ScreenTitle
        actions={
          <ButtonLink to={adminNewOrganizationPath} rounding="full">
            New Organization
          </ButtonLink>
        }
      >
        {TITLE}
      </ScreenTitle>
      <div className="flex w-full flex-1 flex-col pb-6">
        <table className="w-full">
          <thead>
            <tr>
              <TH>Name</TH>
              <TH align="right">Number of Users</TH>
              <TH align="right">Templates</TH>
              <TH align="right">Date Created</TH>
              <TH align="right">Actions</TH>
            </tr>
          </thead>
          <tbody>
            {organizations.map((organization, index) => {
              const first = index === 0
              return (
                <tr key={organization.id}>
                  <TD first={first}>
                    <Link
                      to={adminOrganizationPath({id: organization.id})}
                      className="flex"
                    >
                      {organization.name}
                    </Link>
                  </TD>

                  <TD first={first} align="right">
                    {organization.membersCount.toLocaleString()}
                  </TD>
                  <TD first={first} align="right">
                    {organization.templatesCount.toLocaleString()}
                  </TD>
                  <TD first={first} align="right">
                    {dateTimeShort(new Date(organization.createdAt))}
                  </TD>
                  <TD first={first} align="right">
                    <AdminActions>
                      <AdminAction
                        to={adminOrganizationPath({id: organization.id})}
                      >
                        Edit
                      </AdminAction>
                      <AdminAction
                        onClick={() => {
                          setConfirmDelete(organization)
                        }}
                      >
                        Delete
                      </AdminAction>
                    </AdminActions>
                  </TD>
                </tr>
              )
            })}

            {confirmDelete ? (
              <ConfirmModal
                onConfirm={() => {
                  deleteOrganization({variables: {id: confirmDelete.id}})
                }}
                onClose={() => setConfirmDelete(null)}
              >
                Are you sure you want to delete “{confirmDelete.name}”?
              </ConfirmModal>
            ) : null}
          </tbody>
        </table>
      </div>

      {hasNextPage && (
        <div className="mt-6 flex justify-center">
          <Button
            onClick={() =>
              result.fetchMore({
                variables: {after: endCursor},
              })
            }
            color="ghost"
            inline={true}
            disabled={result.loading}
          >
            Load more
          </Button>
        </div>
      )}
    </Container>
  )
}

export default AdminOrganizationsScreen
