import AdminQuestionCreateForm from "./AdminQuestionCreateForm"
import Modal from "./ui/Modal"

const CreateAdminQuestionModal = ({
  templateId,
  close,
}: {
  templateId: string
  close: () => void
}) => {
  return (
    <Modal
      title="Add Question"
      onClose={close}
      data-test="create-question-modal"
      narrow={true}
    >
      <AdminQuestionCreateForm templateId={templateId} close={close} />
    </Modal>
  )
}

export default CreateAdminQuestionModal
