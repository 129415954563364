import {useMutation} from "@apollo/client"
import invariant from "tiny-invariant"
import {getFragmentData, gql} from "~/__generated__"
import {ANSWER_FRAGMENT} from "./useAnswerQuery"

export const ANSWER_CREATE_MUTATION = gql(`
  mutation AnswerCreate($input: AnswerCreateInput!) {
    answerCreate(input: $input) {
      answer {
        ...AnswerFragment
      }
    }
  }
`)

const useAnswerCreate = () => {
  return useMutation(ANSWER_CREATE_MUTATION, {
    update(cache, {data}) {
      invariant(data?.answerCreate?.answer, "Missing answer create response")
      cache.modify({
        fields: {
          answers(existing = {}) {
            const answer = getFragmentData(
              ANSWER_FRAGMENT,
              data.answerCreate.answer
            )
            const newAnswerRef = cache.writeFragment({
              data: answer,
              fragment: ANSWER_FRAGMENT,
            })
            return {
              ...existing,
              nodes: [newAnswerRef, ...existing.nodes],
            }
          },
          playstormStep(existing = {}) {
            const answer = getFragmentData(
              ANSWER_FRAGMENT,
              data.answerCreate.answer
            )
            const newAnswerRef = cache.writeFragment({
              data: answer,
              fragment: ANSWER_FRAGMENT,
            })
            return {
              ...existing,
              answer: newAnswerRef,
            }
          },
        },
      })
    },
  })
}

export default useAnswerCreate
