import {Menu, Transition} from "@headlessui/react"
import {Fragment} from "react"
import {Link} from "react-router-dom"
import MoreIcon from "./icons/more-icon.svg"

type Props =
  | {
      to: string
      onClick?: () => void
    }
  | {
      to?: string
      onClick: () => void
    }

const getAdminActionClassName = (active: boolean) =>
  `${
    active ? "bg-dark-blue text-white" : "text-gray-900"
  } group flex w-full items-center rounded-lg px-4 py-2.5 text-sm`

export const AdminAction = ({
  to,
  onClick,
  children,
}: Props & {children: React.ReactNode}) => {
  return (
    <div className="p-1">
      <Menu.Item>
        {({active}) =>
          to ? (
            <Link
              to={to}
              className={getAdminActionClassName(active)}
              onClick={onClick}
            >
              {children}
            </Link>
          ) : (
            <button
              onClick={onClick}
              className={getAdminActionClassName(active)}
            >
              {children}
            </button>
          )
        }
      </Menu.Item>
    </div>
  )
}

const AdminActions = ({children}: {children: React.ReactNode}) => {
  return (
    <div>
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="inline-flex w-full justify-center rounded-md px-2 py-1 text-brand-blue/70 hover:bg-brand-blue hover:text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-400">
            <MoreIcon />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-10 mt-2 w-52 origin-top-right rounded-lg bg-white p-1 shadow-lg ring-1 ring-black/5 focus:outline-none">
            {children}
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  )
}

export default AdminActions
