import {Helmet} from "react-helmet"
import ForgotPasswordForm from "~/components/ForgotPasswordForm"
import Container from "~/components/ui/Container"

const ForgotPasswordScreen = () => {
  return (
    <Container className="pt-28">
      <Helmet>
        <title>Reset Your Password</title>
      </Helmet>
      <ForgotPasswordForm />
    </Container>
  )
}

export default ForgotPasswordScreen
