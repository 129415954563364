import useMediaRecorder from "@wmik/use-media-recorder"
import {useMachine} from "@xstate/react"
import {useFormikContext} from "formik"
import {createContext, useContext, useEffect} from "react"
import {fromPromise} from "xstate"
import {AnswerFormValues, MAX_AUDIO_DURATION} from "../AnswerFields"
import recordingMachine from "./recordingMachine"

export type MachineType = ReturnType<typeof useMachine>
export const RecordingMachineContext = createContext({
  machine: {} as MachineType,
})

// This was originally designed to work with https://github.com/chengsokdara/use-whisper
// It was replaced with use-media-recorder due to the following bugs:
// https://github.com/chengsokdara/use-whisper/issues/53
// https://github.com/chengsokdara/use-whisper/issues/49

export const RecordingMachineProvider = ({
  reset,
  stopAudio,
  children,
}: {
  reset: () => void
  stopAudio: () => void
  children: React.ReactNode
}) => {
  const {setFieldValue, submitForm, values} =
    useFormikContext<AnswerFormValues>()

  let {
    status,
    mediaBlob,
    stopRecording,
    pauseRecording,
    startRecording,
    resumeRecording,
  } = useMediaRecorder({
    blobOptions: {type: "video/webm"},
    mediaStreamConstraints: {audio: true, video: false},
  })

  const machine: MachineType = useMachine(
    recordingMachine.provide({
      actors: {
        startRecording: fromPromise(async () => {
          stopAudio()

          // TODO: This should either be handled by the state machine, or
          // we should switch to use-media-recorder's state. The state machine
          // was initially added to handle more complex logic
          if (status === "paused") {
            return resumeRecording()
          }
          return await startRecording()
        }),
        resumeRecording: fromPromise(async () => {
          resumeRecording()
        }),
        pauseRecording: fromPromise(async () => {
          pauseRecording()
        }),
        stopRecording: fromPromise(async () => {
          stopRecording()
        }),
        submitRecording: fromPromise(() => {
          return submitForm()
        }),
      },
      actions: {
        reset: () => {
          setFieldValue("transription", undefined)
          setFieldValue("recordingBlob", undefined)
          setFieldValue("duration", 0)
          reset()
        },
      },
    })
  )

  const [state, send] = machine

  // Submit the form when the recording is stopped
  useEffect(() => {
    const onTranscriptionComplete = async () => {
      await setFieldValue("recordingBlob", mediaBlob)

      // Hack to wait for the form fields to update. Avoids adding another useEffect
      setTimeout(() => {
        send({type: "SUBMIT"})
      }, 100)
    }

    if (state.matches("recorded.stopped") && mediaBlob) {
      onTranscriptionComplete()
    }
  }, [mediaBlob, send, setFieldValue, state])

  useEffect(() => {
    const interval = setInterval(() => {
      if (state.matches("recording.started")) {
        const newDuration = values.duration + 1000
        if (newDuration >= MAX_AUDIO_DURATION) {
          send({type: "PAUSE"})
          alert("You've reached the maximum recording time.")
        } else {
          setFieldValue("duration", newDuration)
        }
      }
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [send, setFieldValue, state, values.duration])

  return (
    <RecordingMachineContext.Provider value={{machine}}>
      {children}
    </RecordingMachineContext.Provider>
  )
}

export const useRecordingMachine = () => {
  return useContext(RecordingMachineContext)
}
