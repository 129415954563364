import {
  SurveyAnswerFragmentFragment,
  SurveyQuestionFragmentFragment,
} from "~/__generated__/graphql"

const AdminQuestionChoice = ({
  question,
  surveyAnswer,
}: {
  question: SurveyQuestionFragmentFragment
  surveyAnswer?: SurveyAnswerFragmentFragment
}) => {
  return (
    <div data-test-id={question.id}>
      <div className="mb-2 font-bold">{question.title}</div>
      <div>
        {question.surveyOptions.nodes.find(
          o => o.id === surveyAnswer?.surveyOptionId
        )?.title || "-"}
      </div>
    </div>
  )
}

export default AdminQuestionChoice
