import * as amplitude from "@amplitude/analytics-browser"
import {useState} from "react"
import {toast} from "react-hot-toast"
import {logInDeletePath} from "~/paths"
import fetchWrapper from "~/util/fetchWrapper"

const useSignOut = () => {
  const [loading, setLoading] = useState(false)
  const signOut = async () => {
    setLoading(true)
    try {
      await fetchWrapper(logInDeletePath, {method: "DELETE"})
      amplitude.track("Logged Out")
      amplitude.reset()
      window.location.replace("/")
    } catch (error) {
      toast.error("Sign out failed, please try again")
    } finally {
      setLoading(false)
    }
  }

  return {signOut, loading}
}

export default useSignOut
