import {formatDuration, intervalToDuration} from "date-fns"

export const dateTimeShort = (date: Date) => {
  return new Intl.DateTimeFormat("en", {
    dateStyle: "short",
    timeStyle: "short",
  })
    .format(date)
    .replace(",", "")
}

const zeroPad = (num: number) => String(num).padStart(2, "0")

export const formatMsDuration = (ms: number) => {
  const duration = intervalToDuration({start: 0, end: ms})
  return formatDuration(duration, {
    format: ["minutes", "seconds"],
    zero: true,
    delimiter: ":",
    locale: {
      formatDistance: (_token, count) => zeroPad(count),
    },
  })
}
