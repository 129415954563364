import clsx from "clsx"
import {Helmet} from "react-helmet"
import {useParams} from "react-router-dom"
import invariant from "tiny-invariant"
import SurveyAnswerForm from "~/components/SurveyAnswerForm"
import Container from "~/components/ui/Container"
import Error from "~/components/ui/Error"
import Loading from "~/components/ui/Loading"
import Panel from "~/components/ui/Panel"
import usePlaystormQuery from "~/hooks/usePlaystormQuery"
import useSurveyQuestionQuery from "~/hooks/useSurveyQuestionQuery"
import useTrackPage from "~/hooks/useTrackPage"
import bottomBgMobile from "~/images/backgrounds/playstom-survey-bg-mobile.jpg"
import bottomBg from "~/images/backgrounds/playstom-survey-bg.jpg"
import background from "~/images/backgrounds/playstorm-survey-top-bg.jpg"

const PlaystormSurveyQuestionScreen = () => {
  const {id, surveyQuestionId} = useParams()
  invariant(surveyQuestionId, "expected surveyQuestionId")
  invariant(id, "expected id")
  const {surveyQuestion, loading, error} =
    useSurveyQuestionQuery(surveyQuestionId)
  const {
    playstorm,
    loading: playstormLoading,
    error: playstormError,
  } = usePlaystormQuery(id)

  useTrackPage("Playstorm Post Survey")

  if (loading || playstormLoading) return <Loading />
  if (error || playstormError || !surveyQuestion || !playstorm)
    return <Error message="Error loading survey question" />

  return (
    <div
      className={clsx(
        "hidden-mobile-bg flex flex-1 flex-col bg-right-top bg-no-repeat"
      )}
      style={{
        backgroundImage: `url(${background})`,
      }}
    >
      <Container className={clsx("pt-24", "md:pb-24")}>
        <Helmet>
          <title>Playstorm Survey</title>
        </Helmet>
        <Panel size="md" className="min-h-[600px] md:!w-[650px]">
          <div className="mb-5 text-center text-7xl">💬</div>
          <div
            className={clsx(
              "mb-4 text-center text-xl font-semibold text-brand-blue",
              "sm:text-2xl"
            )}
          >
            Congratulations on completing your Playstorm! Below are a few
            questions to ground your experience and track your creative
            progress.
          </div>

          <div className="mx-auto mb-8 max-w-[416px] text-center">
            Please slide the cursor to indicate your perception of your
            playstorming ideas?
          </div>

          <div className="pb-10">
            <SurveyAnswerForm
              surveyQuestion={surveyQuestion}
              playstormId={id}
              playstormTemplateId={playstorm.templateId}
              showHeader={false}
            />
          </div>
        </Panel>
      </Container>
      <div className="mt-auto">
        <img
          src={bottomBg}
          alt="Background"
          className={clsx("hidden h-auto w-full", "md:block")}
        />
        <img
          src={bottomBgMobile}
          alt="Background"
          className={clsx("h-auto w-full", "md:hidden")}
        />
      </div>
    </div>
  )
}

export default PlaystormSurveyQuestionScreen
