import clsx from "clsx"

const PlaystormOverviewHeading = ({children}: {children: React.ReactNode}) => (
  <div
    className={clsx(
      "mb-9 flex flex-col gap-4 text-xl font-bold",
      "sm:text-[36px]",
      "md:flex-row md:items-center md:justify-between"
    )}
    children={children}
  />
)

export default PlaystormOverviewHeading
