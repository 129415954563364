import {useMutation} from "@apollo/client"
import {gql} from "~/__generated__"

export const TEMPLATE_CREATE_MUTATION = gql(`
  mutation TemplateCreate($input: TemplateCreateInput!) {
    templateCreate(input: $input) {
      template {
        ...TemplateFragment
      }
    }
  }
`)

const useTemplateCreate = () => {
  return useMutation(TEMPLATE_CREATE_MUTATION)
}

export default useTemplateCreate
