import Select, {components as ReactSelectComponents} from "react-select"
import StateManagedSelect from "react-select/dist/declarations/src"
import ChevronsUpDownIcon from "../icons/chevrons-up-down-icon.svg"

const sansFontFamily =
  'ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"'

const SelectField: StateManagedSelect & {light?: boolean} = ({
  components,
  // @ts-expect-error todo: extend StateManagedSelect to include light prop
  light,
  ...props
}) => {
  return (
    <Select
      theme={selectTheme => ({
        ...selectTheme,
        borderRadius: 0,
        colors: {
          ...selectTheme.colors,
          neutral20: "#e5e7eb",
          primary: "rgb(255 255 255 / 0.2)",
        },
      })}
      components={{
        DropdownIndicator: props => (
          <ReactSelectComponents.DropdownIndicator {...props}>
            <div data-test="dropdown-indicator">
              <ChevronsUpDownIcon />
            </div>
          </ReactSelectComponents.DropdownIndicator>
        ),
        ...components,
      }}
      styles={{
        control: baseStyles => ({
          ...baseStyles,
          backgroundColor: light ? "#ffffff" : "rgb(0 0 0 / 0.4)",
          minHeight: 40,
          borderRadius: "0.5rem",
          boxShadow: "0px 3px 4px 0px #00000008",
          borderColor: light ? "rgb(229, 231, 235)" : "transparent",
          fontSize: 14,
          fontFamily: sansFontFamily,
          paddingLeft: "0.625rem",
          paddingRight: "0.625rem",
          transition: "none !important",
          "&:hover": {},
        }),
        singleValue: base => ({
          ...base,
          color: light ? "#151515" : "#ffffff",
          marginLeft: 0,
          marginRight: 0,
        }),
        valueContainer: base => ({
          ...base,
          color: "#ffffff",
          paddingLeft: 0,
          paddingRight: 0,
        }),
        input: base => ({
          ...base,
          color: "#ffffff",
          marginLeft: 0,
          marginRight: 0,
        }),
        indicatorSeparator: () => ({
          display: "none",
          transition: "none",
        }),
        dropdownIndicator: base => ({
          ...base,
          color: light ? "#151515" : "#ffffff",
        }),
        menu: base => ({
          ...base,
          zIndex: 20,
          backgroundColor: light ? "#ffffff" : "#495d7f",
          borderRadius: "0.625rem",
        }),
        option: (base, state) => ({
          ...base,
          fontSize: 14,
          color: light
            ? state.isSelected
              ? "#151515"
              : "#555555"
            : state.isSelected
            ? "#ffffff"
            : "#c5c5c5",
          background: "transparent",
          "&:hover": {
            color: light ? "#151515" : "#ffffff",
          },
          "&:active": {
            background: "transparent",
            color: light ? "#151515" : "#ffffff",
          },
        }),
      }}
      {...props}
    />
  )
}

export default SelectField
