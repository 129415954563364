import {useMutation} from "@apollo/client"
import toast from "react-hot-toast"
import invariant from "tiny-invariant"
import {gql} from "~/__generated__"
import {PlaystormOverviewAnswerFragmentFragment} from "~/__generated__/graphql"

export const TOGGLE_ANSWER_FAVORITE = gql(`
  mutation ToggleAnswerFavorite($input: ToggleAnswerFavoriteInput!) {
    toggleAnswerFavorite(input: $input) {
      answer {
        ...PlaystormStepAnswerFragment
      }
    }
  }
`)

const useAnswerFavoriteToggle = (
  answer: PlaystormOverviewAnswerFragmentFragment
) => {
  invariant(answer.id, "Expected answer id to be defined.")

  return useMutation(TOGGLE_ANSWER_FAVORITE, {
    variables: {input: {answerId: answer.id}},

    onError: () => {
      toast.error("Favorite toggle failed, please try again.")
    },
  })
}

export default useAnswerFavoriteToggle
