import {Popover} from "@headlessui/react"
import clsx from "clsx"
import React, {useEffect} from "react"
import {useLocation} from "react-router-dom"
import XSvg from "./icons/close-menu-icon.svg"
import HamburgerSvg from "./icons/hamburger.svg"

const Content = ({
  open,
  children,
}: {
  open: boolean
  children: React.ReactNode
}) => {
  useEffect(() => {
    document.body.style.overflow = open ? "hidden" : "unset"
  }, [open])

  return (
    <>
      <Popover.Button
        className={clsx(
          "ml-2 mt-2 flex h-[28px] w-[28px] items-center justify-center text-brand-blue outline-gray-500",
          "hover:opacity-50"
        )}
        data-test="mobile-nav-button"
      >
        <HamburgerSvg />
      </Popover.Button>
      <Popover.Panel className="fixed left-0 top-0 z-50 h-full w-[200px] overflow-y-auto border-r border-brand-blue/30 bg-white">
        <Popover.Button
          className={clsx(
            "ml-2 mt-2 flex h-[28px] w-[28px] items-center justify-center text-brand-blue outline-gray-500",
            "hover:opacity-50"
          )}
          data-test="mobile-nav-button"
        >
          <XSvg />
        </Popover.Button>
        {children}
      </Popover.Panel>
    </>
  )
}

const MobileNav = ({children}: {children: React.ReactNode}) => {
  const location = useLocation()
  return (
    <div key={location.pathname}>
      <Popover>
        {({open}) => <Content open={open} children={children} />}
      </Popover>
    </div>
  )
}

export default MobileNav
