import clsx from "clsx"

export type THType = {
  align?: "right" | "center"
  className?: string
  children?: React.ReactNode
}

export const TH = ({align, className, children}: THType) => {
  return (
    <td
      className={clsx("px-4 py-3 text-sm font-semibold", className, {
        "text-right": align === "right",
        "text-center": align === "center",
      })}
    >
      {children}
    </td>
  )
}

export type TDType = {
  align?: "right" | "center"
  alignCenter?: boolean
  first?: boolean
  className?: string
  children?: React.ReactNode
}

export const TD = ({align, first, className, children}: TDType) => {
  return (
    <td
      className={clsx(
        "border-t px-4 py-4 align-middle text-sm text-brand-blue/80",
        first ? "border-brand-blue" : "border-brand-blue/30",
        className,
        {
          "text-right": align === "right",
          "text-center": align === "center",
        }
      )}
    >
      {children}
    </td>
  )
}

export const Table = ({children}: {children: React.ReactNode}) => {
  return (
    <div className="w-full overflow-x-auto px-4">
      <table className="w-full">{children}</table>
    </div>
  )
}
