import {Formik, FormikHelpers} from "formik"
import toast from "react-hot-toast"
import {useNavigate} from "react-router-dom"
import invariant from "tiny-invariant"
import {getFragmentData} from "~/__generated__"
import {TemplateVisibility} from "~/__generated__/graphql"
import useTemplateCreate from "~/hooks/useTemplateCreate"
import {TEMPLATE_FRAGMENT} from "~/hooks/useTemplateQuery"
import {adminEditTemplatePath} from "~/paths"
import {displayErrors} from "~/util/validations"
import TemplateFields, {
  TemplateFormValues,
  templateValidationSchema,
} from "./TemplateFields"

const TemplateCreateForm = () => {
  const navigate = useNavigate()
  const [mutate] = useTemplateCreate()

  const initialValues: TemplateFormValues = {
    title: "",
    description: "",
    duration: "",
    status: "",
    heroImage: "",
    visibility: TemplateVisibility.Everyone,
    organizationIds: [],
  }

  const createTemplate = async (
    values: TemplateFormValues,
    {setFieldError}: FormikHelpers<TemplateFormValues>
  ) => {
    try {
      const resp = await mutate({
        variables: {
          input: {
            templateInput: {
              title: values.title,
              description: values.description,
              duration: values.duration,
              status: values.status,
              heroImage: values.heroImage || undefined,
              visibility: values.visibility,
              organizationIds: values.organizationIds,
            },
          },
        },
      })
      const createdTemplate = getFragmentData(
        TEMPLATE_FRAGMENT,
        resp?.data?.templateCreate.template
      )
      const id = createdTemplate?.id
      invariant(id, "Expected template id")
      navigate(adminEditTemplatePath({id}))
      toast.success("Template created successfully!")
    } catch (error: any) {
      displayErrors(error?.graphQLErrors, setFieldError)
    }
  }

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={templateValidationSchema}
        validateOnBlur={false}
        onSubmit={createTemplate}
      >
        <TemplateFields />
      </Formik>
    </div>
  )
}

export default TemplateCreateForm
