import clsx from "clsx"
import {useState} from "react"
import {useParams} from "react-router-dom"
import invariant from "tiny-invariant"
import {getFragmentData} from "~/__generated__"
import Affirmation from "~/components/Affirmation"
import {ContinueLink} from "~/components/AnswerControlButtons"
import PlaystormHeader from "~/components/PlaystormHeader"
import Error from "~/components/ui/Error"
import {MESSAGE_FRAGMENT} from "~/hooks/useMessageUpdatedSubscription"
import usePlaystormStep, {
  PLAYSTORM_STEP_ANSWER_FRAGMENT,
} from "~/hooks/usePlaystormStepQuery"
import useTrackPage from "~/hooks/useTrackPage"
import background from "~/images/backgrounds/clouds.jpg"
import {playstormPath, playstormQuestionPath} from "~/paths"

const CreativePlaystormAffirmationScreen = () => {
  const {id, questionId} = useParams()
  const [hasPlayedAudio, setHasPlayedAudio] = useState(false)
  const [hasAudioTimedOut, setHasAudioTimedOut] = useState(false)

  invariant(id, "Expected playstorm id to be defined")
  invariant(questionId, "Expected question id to be defined")
  useTrackPage("Playstorm Affirmation")

  const {playstormStep, loading, error} = usePlaystormStep(id, questionId)

  if (loading) return <div className="min-h-full" />
  if (error || !playstormStep)
    return <Error message="Error loading playstorm" />

  invariant(playstormStep, "Expected playstormStep to be defined")

  const answer = getFragmentData(
    PLAYSTORM_STEP_ANSWER_FRAGMENT,
    playstormStep.answer
  )
  const affirmationMessage = getFragmentData(
    MESSAGE_FRAGMENT,
    answer.affirmationMessage
  )
  const nextPath = answer.nextQuestionId
    ? playstormQuestionPath({id, questionId: answer.nextQuestionId})
    : playstormPath({id})

  const isLoading = !hasPlayedAudio && !hasAudioTimedOut

  return (
    <div
      className="flex flex-1 pb-20"
      style={{
        backgroundImage: `url(${background})`,
        backgroundPosition: "center 120px",
      }}
    >
      <div className="mx-auto flex flex-col items-center">
        <PlaystormHeader />
        <div className="relative z-10 flex h-full w-full flex-col gap-10">
          {!!affirmationMessage && (
            <Affirmation
              message={affirmationMessage}
              isLoading={isLoading}
              hasPlayedAudio={hasPlayedAudio}
              setHasPlayedAudio={setHasPlayedAudio}
              setHasAudioTimedOut={setHasAudioTimedOut}
            />
          )}
          <div
            className={clsx({
              "opacity-50": isLoading,
            })}
          >
            <ContinueLink to={nextPath} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreativePlaystormAffirmationScreen
