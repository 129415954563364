import {useQuery} from "@apollo/client"
import {getFragmentData, gql} from "~/__generated__"

export const PLAYSTORM_OVERVIEW_FRAGMENT = gql(`
  fragment PlaystormOverviewFragment on Playstorm {
    id
    title
    templateTitle
    isOwner
    completedAt
    nextQuestionId
    completedSurveyAt
    answers {
      nodes {
        ...PlaystormOverviewAnswerFragment
      }
    }
  }
`)

export const PLAYSTORM_OVERVIEW_ANSWER_FRAGMENT = gql(`
  fragment PlaystormOverviewAnswerFragment on Answer {
    id
    initialText
    text
    transcription
    summaryMessage {
      ...MessageFragment
    }
    response
    nextQuestionId
    playstormId
    answerType
    questionType
    recordingUrl
    question {
      ...QuestionFragment
    }
    strengtheningMessages {
      nodes {
        ...MessageFragment
      }
    }
    isFavorite
    isOwner
  }
`)

export const GET_PLAYSTORM_OVERVIEW_QUERY = gql(`
  query PlaystormOverview($id: ID!) {
    playstorm(id: $id) {
      ...PlaystormOverviewFragment
    }
  }
`)

const usePlaystormOverviewQuery = (id: string) => {
  const {data, loading, error} = useQuery(GET_PLAYSTORM_OVERVIEW_QUERY, {
    variables: {
      id,
    },
    fetchPolicy: "network-only",
  })
  const playstorm = getFragmentData(
    PLAYSTORM_OVERVIEW_FRAGMENT,
    data?.playstorm
  )
  const answers =
    playstorm?.answers?.nodes?.map(node =>
      getFragmentData(PLAYSTORM_OVERVIEW_ANSWER_FRAGMENT, node)
    ) || []

  const playstormOverview = {
    playstorm,
    answers,
  }

  return {playstormOverview, data, loading, error}
}

export default usePlaystormOverviewQuery
