import React from "react"
import {Link} from "react-router-dom"
import {
  TemplateFragmentFragment,
  TemplateVisibility,
} from "~/__generated__/graphql"
import {adminEditTemplatePath} from "~/paths"
import Button from "./ui/Button"
import {TD, TH, Table} from "./ui/Table"

const TemplatesTable = ({
  templates,
  hasNextPage,
  fetchNextPage,
  loading,
}: {
  templates: TemplateFragmentFragment[]
  hasNextPage: boolean
  fetchNextPage: () => void
  loading: boolean
}) => {
  return (
    <div className="flex w-full flex-1 flex-col">
      <Table>
        <thead>
          <tr>
            <TH>Playstorm</TH>
            <TH align="center">Number of Questions</TH>
            <TH align="right">Duration</TH>
            <TH align="right">Status</TH>
            <TH align="right">Visiblity</TH>
          </tr>
        </thead>
        <tbody>
          {templates.map((template, index) => {
            const first = index === 0
            return (
              <tr key={template.id}>
                <TD first={first}>
                  <Link to={adminEditTemplatePath({id: template.id})}>
                    {template.title}
                  </Link>
                </TD>
                <TD align="center" first={first}>
                  <Link to={adminEditTemplatePath({id: template.id})}>
                    {template.questionsCount}
                  </Link>
                </TD>
                <TD align="right" first={first}>
                  <Link to={adminEditTemplatePath({id: template.id})}>
                    {template.duration}
                  </Link>
                </TD>
                <TD align="right" first={first}>
                  <Link to={adminEditTemplatePath({id: template.id})}>
                    {template.status}
                  </Link>
                </TD>
                <TD align="right" first={first}>
                  <Link to={adminEditTemplatePath({id: template.id})}>
                    <TemplateVisibilityColumn
                      visibility={template.visibility}
                      organizationsCount={template.organizationsCount}
                    />
                  </Link>
                </TD>
              </tr>
            )
          })}
        </tbody>
      </Table>
      {hasNextPage && (
        <div className="mt-6 flex justify-center">
          <Button
            onClick={fetchNextPage}
            color="ghost"
            inline={true}
            disabled={loading}
          >
            Load more
          </Button>
        </div>
      )}
    </div>
  )
}

export default TemplatesTable

const TemplateVisibilityColumn: React.FC<{
  visibility: TemplateVisibility
  organizationsCount: number
}> = ({visibility, organizationsCount}) => {
  switch (visibility) {
    case TemplateVisibility.Everyone:
      return "Everyone"
    case TemplateVisibility.Individuals:
      return "Individuals"
    case TemplateVisibility.OrganizationMembers:
      return `Organizations (${organizationsCount.toLocaleString()})`
  }
}
