import {Dialog, Transition} from "@headlessui/react"
import clsx from "clsx"
import {Fragment} from "react"
import DialogXIcon from "../icons/dialog-x-icon.svg"

const SIZE = {
  md: "max-w-[520px] w-full",
  lg: "max-w-[650px] w-full",
  xl: "max-w-[940px] w-full",
}

const Modal = ({
  onClose: close,
  title,
  size = "md",
  narrow,
  "data-test": dataTest,
  hideCloseButton = false,
  children,
}: {
  onClose: () => void
  title?: string
  size?: keyof typeof SIZE
  narrow?: boolean
  "data-test"?: string
  hideCloseButton?: boolean
  children: React.ReactNode
}) => {
  return (
    <Transition appear show={true} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        onClose={close}
        data-test={dataTest}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={clsx(
                  "relative w-full transform rounded-4xl bg-white px-6 pb-6 pt-2 text-left align-middle shadow-xl transition-all",
                  SIZE[size],
                  {
                    "sm:px-16": narrow,
                  }
                )}
              >
                <div
                  className={clsx(
                    "mt-4 flex items-center justify-between",
                    hideCloseButton && !title ? "mb-4" : "mb-10"
                  )}
                >
                  <div className="w-6" />
                  <div className="text-2xl font-semibold">{title}</div>
                  {!hideCloseButton && (
                    <button
                      onClick={close}
                      className={clsx(
                        "flex h-6 w-6 items-center justify-center rounded-full border border-gray-300 text-gray-500",
                        "hover:opacity-75"
                      )}
                    >
                      <DialogXIcon />
                    </button>
                  )}
                </div>
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

export default Modal
