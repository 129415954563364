import {Helmet} from "react-helmet"
import CreativePlaystormsTable from "~/components/CreativePlaystormsTable"
import ScreenTitle from "~/components/ScreenTitle"
import Container from "~/components/ui/Container"
import Error from "~/components/ui/Error"
import Loading from "~/components/ui/Loading"
import useCreativePlaystormsQuery from "~/hooks/useCreativePlaystormsQuery"
import useTrackPage from "~/hooks/useTrackPage"
import background from "~/images/backgrounds/creative-playstorms-bg.jpg"

const TITLE = "Playstorms"

const AdminPlaystormsScreen = () => {
  useTrackPage("Creative Playstorms")

  const {playstorms, loading, error, hasNextPage, fetchNextPage} =
    useCreativePlaystormsQuery()

  if (loading) return <Loading />
  if (error || !playstorms) return <Error message="Error loading playstorms" />

  return (
    <div className="flex flex-1 flex-col">
      <Container size="full" centered={false}>
        <Helmet>
          <title>{TITLE}</title>
        </Helmet>
        <ScreenTitle>{TITLE}</ScreenTitle>
        <CreativePlaystormsTable
          playstorms={playstorms}
          hasNextPage={hasNextPage!!}
          fetchNextPage={fetchNextPage}
          loading={loading}
        />
      </Container>
      <div className="mt-auto pt-[105px]">
        <img src={background} alt="Background" className="h-auto w-full" />
      </div>
    </div>
  )
}

export default AdminPlaystormsScreen
