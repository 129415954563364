import clsx from "clsx"
import {useState} from "react"
import {
  PlaystormOverviewAnswerFragmentFragment,
  PlaystormOverviewFragmentFragment,
  QuestionTypeEnum,
  SurveyLocationEnum,
} from "~/__generated__/graphql"
import {H1} from "~/components/ui/Headings"
import useSurveyQuestionsQuery from "~/hooks/useSurveyQuestionsQuery"
import {playstormSurveyQuestionPath} from "~/paths"
import {trackEvent} from "~/util/analytics"
import QuestionBubble from "../QuestionBubble"
import SharePlaystormModal from "../SharePlaystormModal"
import idea from "../icons/idea.png"
import lightning from "../icons/lightning.png"
import PlaystormBulb from "../icons/playstorm-bulb.svg"
import PlaystormRobot from "../icons/playstorm-robot.svg"
import ShareIcon from "../icons/share-icon.svg"
import Button, {ButtonLink} from "../ui/Button"
import Error from "../ui/Error"
import Loading from "../ui/Loading"
import PlaystormOverviewAnswer from "./PlaystormOverviewAnswer"
import PlaystormOverviewHeading from "./PlaystormOverviewHeading"
import PlaystormStrengtheningIdeas from "./PlaystormStrengtheningIdeas"

const CompletePlaystorm = ({playstormId}: {playstormId: string}) => {
  const {
    surveyQuestions,
    loading: surveyQuestionsLoading,
    error,
  } = useSurveyQuestionsQuery({
    variables: {
      surveyLocation: SurveyLocationEnum.PostPlaystorm,
      first: 1,
    },
  })

  if (surveyQuestionsLoading) return <Loading />
  if (error || !surveyQuestions)
    return <Error message="Error loading survey questions" />
  if (surveyQuestions.length === 0) return null

  return (
    <ButtonLink
      to={playstormSurveyQuestionPath({
        id: playstormId,
        surveyQuestionId: surveyQuestions[0].id,
      })}
      rounding="full"
      size="sm"
      className="px-5"
      color="outlineDark"
    >
      Back to Dashboard
    </ButtonLink>
  )
}

const Announcement = ({text, iconSrc}: {text: string; iconSrc: string}) => {
  return (
    <div
      className={clsx("flex items-center justify-center", "md:justify-between")}
    >
      <div className={clsx("-mt-5 hidden", "md:block")}>
        <img src={iconSrc} alt="Lightning" />
      </div>
      <div className="max-w-[500px]">
        <QuestionBubble text={text} />
      </div>
      <div className={clsx("-mt-5 hidden", "md:block")}>
        <img src={iconSrc} alt="Lightning" />
      </div>
    </div>
  )
}

const CreativePlaystormOverview = ({
  playstorm,
  answers,
}: {
  playstorm: PlaystormOverviewFragmentFragment
  answers: PlaystormOverviewAnswerFragmentFragment[]
}) => {
  const [showShareModal, setShowShareModal] = useState(false)
  const toggleShareModal = () => setShowShareModal(prev => !prev)
  const {title, isOwner} = playstorm

  trackEvent("View Shared Playstorm", {playstormId: playstorm.id})

  const strengtheningAnswer = answers.find(
    answer => answer.questionType === QuestionTypeEnum.Strengthening
  )
  const otherAnswers = answers.filter(
    answer => answer.id !== strengtheningAnswer?.id
  )

  return (
    <div data-test="playstorm-overview" className="pb-20">
      <div className="mb-24 text-center">
        <H1 center={true} size="lg" className="!text-black">
          {title}
        </H1>
      </div>
      {isOwner && (
        <Announcement
          iconSrc={lightning}
          text="“Here are the results of your Playstorm!”"
        />
      )}
      <div className="flex flex-col gap-9">
        {strengtheningAnswer &&
          strengtheningAnswer.strengtheningMessages.nodes.length > 0 && (
            <div>
              <div className="relative">
                <PlaystormOverviewHeading>
                  <div />
                  {isOwner && (
                    <div
                      className={clsx(
                        "flex flex-col items-center gap-4",
                        "sm:flex-row"
                      )}
                    >
                      <Button
                        onClick={() => {
                          trackEvent("Click Grand Reveal Share")
                          toggleShareModal()
                        }}
                        color="outlineDark"
                        rounding="full"
                        size="sm"
                        className="min-w-[230px]"
                      >
                        <div className="flex items-center gap-4">
                          <ShareIcon /> Share Playstorm Ideas
                        </div>
                      </Button>
                      {!playstorm.completedSurveyAt && (
                        <CompletePlaystorm playstormId={playstorm.id} />
                      )}
                    </div>
                  )}
                </PlaystormOverviewHeading>
                <div className="absolute -left-44 -top-6">
                  <PlaystormRobot />
                </div>
              </div>
              <PlaystormStrengtheningIdeas answer={strengtheningAnswer} />
            </div>
          )}
        {otherAnswers.length > 0 && (
          <div className="mt-24">
            {isOwner && (
              <Announcement iconSrc={idea} text="“Below are your ideas”" />
            )}

            <div className="relative">
              <PlaystormOverviewHeading>
                <div />
                {isOwner && (
                  <div className="text-base font-normal text-brand-blue">
                    Click an idea to edit
                  </div>
                )}
              </PlaystormOverviewHeading>
              <div className="absolute -left-36 -top-16">
                <PlaystormBulb />
              </div>
            </div>

            <div className="flex flex-col gap-8">
              {otherAnswers.map(answer => (
                <PlaystormOverviewAnswer answer={answer} key={answer.id} />
              ))}
            </div>
          </div>
        )}
      </div>
      {showShareModal && <SharePlaystormModal close={toggleShareModal} />}
    </div>
  )
}

export default CreativePlaystormOverview
