import {useMutation} from "@apollo/client"
import {gql} from "~/__generated__"

export const PRESIGN_UPLOAD_MUTATION = gql(`
  mutation PresignUpload($input: PresignUploadInput!) {
    presignUpload(input: $input) {
      url
      headers
      signedId
    }
  }
`)

const usePresignUpload = () => {
  return useMutation(PRESIGN_UPLOAD_MUTATION)
}

export default usePresignUpload
