import clsx from "clsx"
import {useEffect} from "react"
import {Helmet} from "react-helmet"
import {useNavigate} from "react-router-dom"
import Footer from "~/components/Footer"
import LogInForm from "~/components/LogInForm"
import Container from "~/components/ui/Container"
import useTrackPage from "~/hooks/useTrackPage"
import useViewerContext from "~/hooks/useViewerContext"
import background from "~/images/backgrounds/login-bg.jpg"

const LogInScreen = () => {
  const {viewer, loading} = useViewerContext()
  const navigate = useNavigate()

  useEffect(() => {
    if (viewer && !loading) {
      navigate("/")
    }
  }, [viewer, loading, navigate])

  useTrackPage("Log In")

  return (
    <div
      className="bottom-page-bg-cover login-screen-bg"
      style={{
        backgroundImage: `url(${background})`,
      }}
    >
      <Container className={clsx("pt-10", "md:pt-28")}>
        <Helmet>
          <title>Log In</title>
        </Helmet>
        <LogInForm />
      </Container>
      <div className={clsx("mt-[280px]", "mt-0")}>
        <Footer />
      </div>
    </div>
  )
}

export default LogInScreen
