import clsx from "clsx"
import LogoLg from "~/images/logo-lg.svg"
import Logo1X from "~/images/logo.png"
import Logo2X from "~/images/logo@2x.png"

const Logo = () => {
  return (
    <div className="flex flex-col items-center gap-5">
      <div className={clsx("scale-[0.6]", "md:scale-100")}>
        <LogoLg />
      </div>
      <img
        src={Logo1X}
        srcSet={`${Logo1X} 1x, ${Logo2X} 2x`}
        width={184}
        height={26}
        alt="playstorming &trade;"
        className={clsx("hidden", "md:block")}
      />
    </div>
  )
}

export default Logo
