import {useQuery} from "@apollo/client"
import {getFragmentData, gql} from "~/__generated__"

export const ADMIN_ANSWER_FRAGMENT = gql(`
  fragment AdminAnswerFragment on Answer {
    id
    text
    transcription
    transcriptionSummary
    recordingUrl
    nextQuestionId
    answerType
    questionType
    isFavorite
    response
    listItemCount
    question {
      ...QuestionFragment
    }
    summaryMessage {
      ...MessageFragment
    }
    affirmationMessage {
      ...MessageFragment
    }
    strengtheningMessages {
      nodes {
        ...MessageFragment
      }
    }
  }
`)

export const ADMIN_FULL_PLAYSTORM_FRAGMENT = gql(`
  fragment AdminFullPlaystormFragment on Playstorm {
    id
    user {
      id
      name
      email
    }
    templateId
    templateTitle
    title
    completedAt
    listItemCount
    answers {
      nodes {
        ...AdminAnswerFragment
      }
    }
    postPlaystormSurveyAnswers {
      nodes {
        ...SurveyAnswerFragment
      }
    }
  }
`)

const GET_ADMIN_FULL_PLAYSTORM_QUERY = gql(`
  query AdminPlaystorm($id: ID!) {
    playstorm(id: $id) {
      ...AdminFullPlaystormFragment
    }
  }
`)

const useAdminPlaystormQuery = (id: string) => {
  const {data, loading, error} = useQuery(GET_ADMIN_FULL_PLAYSTORM_QUERY, {
    variables: {
      id,
    },
  })

  const playstorm = getFragmentData(
    ADMIN_FULL_PLAYSTORM_FRAGMENT,
    data?.playstorm
  )

  return {playstorm, data, loading, error}
}

export default useAdminPlaystormQuery
