import {useMutation} from "@apollo/client"
import {gql} from "~/__generated__"

export const MESSAGE_UPDATE_MUTATION = gql(`
  mutation MessageUpdate($input: MessageUpdateInput!) {
    messageUpdate(input: $input) {
      message {
        ...MessageFragment
      }
    }
  }
`)

const useMessageUpdate = () => {
  return useMutation(MESSAGE_UPDATE_MUTATION)
}

export default useMessageUpdate
