import {Helmet} from "react-helmet"
import AdminUsersTable from "~/components/AdminUsersTable"
import ScreenTitle from "~/components/ScreenTitle"
import Container from "~/components/ui/Container"
import Error from "~/components/ui/Error"
import Loading from "~/components/ui/Loading"
import useAdminUsersQuery from "~/hooks/useAdminUsersQuery"

const TITLE = "Users"

const AdminUsersScreen = () => {
  const {users, loading, error, hasNextPage, fetchNextPage} =
    useAdminUsersQuery()
  if (loading) return <Loading />
  if (error || users.length === 0)
    return <Error message="Error loading users" />

  return (
    <Container size="full" centered={false}>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <ScreenTitle>{TITLE}</ScreenTitle>
      <AdminUsersTable
        users={users}
        hasNextPage={hasNextPage!!}
        fetchNextPage={fetchNextPage}
        loading={loading}
      />
    </Container>
  )
}

export default AdminUsersScreen
