import {useApolloClient} from "@apollo/client"
import clsx from "clsx"
import {useEffect, useRef, useState} from "react"
import {getFragmentData} from "~/__generated__"
import {
  AnswerTypeEnum,
  MessageFragmentFragment,
  PlaystormOverviewAnswerFragmentFragment,
} from "~/__generated__/graphql"
import Panel from "~/components/ui/Panel"
import useAnswerFavoriteToggle from "~/hooks/useAnswerFavoriteToggle"
import {MESSAGE_FRAGMENT} from "~/hooks/useMessageUpdatedSubscription"
import {GET_PLAYSTORM_OVERVIEW_QUERY} from "~/hooks/usePlaystormOverviewQuery"
import {QUESTION_FRAGMENT} from "~/hooks/useQuestionQuery"
import {trackEvent} from "~/util/analytics"
import {isJobComplete} from "~/util/jobs"
import FavoriteHeartEmpty from "../../components/icons/favorite-heart-empty.svg"
import FavoriteHeartFilled from "../../components/icons/favorite-heart-filled.svg"
import PlayIcon from "../../components/icons/play-icon.svg"
import TranscriptIcon from "../../components/icons/transcript-icon.svg"
import PlaystormOverviewActionButton from "./PlaystormOverviewActionButton"
import PlaystormOverviewActionButtons from "./PlaystormOverviewActionButtons"
import PlaystormOverviewAnswerHeading from "./PlaystormOverviewAnswerHeading"
import PlaystormOverviewPlayer from "./PlaystormOverviewPlayer"
import {
  PlaystormOverviewAnswerText,
  PlaystormOverviewMessageText,
  PlaystormOverviewMessageTextStreaming,
  TextContent,
} from "./PlaystormOverviewText"
import PlaystormOverviewTranscript, {
  playstormOverviewTranscriptTitle,
} from "./PlaystormOverviewTranscript"
import invariant from "tiny-invariant"

type ActiveTab = "player" | "transcript" | null

const ActionButtons = ({
  answer,
  activeTab,
  toggleActiveTab,
  isAudio,
}: {
  answer: PlaystormOverviewAnswerFragmentFragment
  activeTab: ActiveTab
  toggleActiveTab: (tab: ActiveTab) => void
  isAudio: boolean
}) => {
  const [toggleFavorite, {loading: favoriteToggleLoading}] =
    useAnswerFavoriteToggle(answer)
  const isPlayDisabled = !isAudio || !answer.recordingUrl

  return (
    <PlaystormOverviewActionButtons>
      <PlaystormOverviewActionButton
        onClick={() => {
          trackEvent(
            `Click Grand Reveal ${
              answer.isFavorite ? "UnFavorite" : "Favorite"
            }`
          )
          toggleFavorite()
        }}
        icon={
          answer.isFavorite ? <FavoriteHeartFilled /> : <FavoriteHeartEmpty />
        }
        disabled={favoriteToggleLoading}
        data-test={answer.isFavorite ? "is-favorite" : "is-not-favorite"}
      >
        Favorite
      </PlaystormOverviewActionButton>
      <PlaystormOverviewActionButton
        onClick={() => {
          trackEvent("Click Grand Reveal Play Tab")
          toggleActiveTab("player")
        }}
        isPressed={activeTab === "player"}
        icon={<PlayIcon />}
        disabled={isPlayDisabled}
        transparent={isPlayDisabled}
        data-test="answer-player-button"
      >
        Play
      </PlaystormOverviewActionButton>
      <PlaystormOverviewActionButton
        onClick={() => {
          trackEvent("Click Grand Reveal Transcript Tab")
          toggleActiveTab("transcript")
        }}
        isPressed={activeTab === "transcript"}
        icon={<TranscriptIcon />}
        data-test="answer-transcript-button"
      >
        {playstormOverviewTranscriptTitle(answer.answerType)}
      </PlaystormOverviewActionButton>
    </PlaystormOverviewActionButtons>
  )
}

const SummaryMessage = ({message}: {message: MessageFragmentFragment}) => {
  const isLoading = !isJobComplete(message.jobStatus)
  const refetchOnCompleteRef = useRef<boolean>(isLoading)
  const client = useApolloClient()

  useEffect(() => {
    if (refetchOnCompleteRef.current && !!message.text) {
      refetchOnCompleteRef.current = false
      client.refetchQueries({
        include: [GET_PLAYSTORM_OVERVIEW_QUERY],
      })
    }
  }, [client, message.text])

  if (isLoading) {
    return <PlaystormOverviewMessageTextStreaming message={message} />
  }

  return (
    <>
      <PlaystormOverviewMessageText
        id={message.id}
        text={message.text || ""}
        initialText={message.initialText || ""}
      />
    </>
  )
}

const Response = ({
  answer,
}: {
  answer: PlaystormOverviewAnswerFragmentFragment
}) => {
  invariant(answer.id, "Expected answer id")
  const question = getFragmentData(QUESTION_FRAGMENT, answer.question)

  const summaryMessage = getFragmentData(
    MESSAGE_FRAGMENT,
    answer.summaryMessage
  )

  return (
    <>
      <PlaystormOverviewAnswerHeading>
        {question?.text}
      </PlaystormOverviewAnswerHeading>
      <div className="mt-6">
        {!!answer.isOwner ? (
          <>
            {!!summaryMessage ? (
              <SummaryMessage message={summaryMessage} />
            ) : (
              <PlaystormOverviewAnswerText
                id={answer.id}
                text={answer.text || ""}
                initialText={answer.initialText || ""}
              />
            )}
          </>
        ) : (
          <TextContent text={summaryMessage?.text || answer.text || ""} />
        )}
      </div>
    </>
  )
}

const PlaystormOverviewAnswer = ({
  answer,
}: {
  answer: PlaystormOverviewAnswerFragmentFragment
}) => {
  const {isOwner, recordingUrl, answerType} = answer

  // The url signature changes whenever the answer is re-fetched.
  // Cache recordingUrl to avoid the player re-rendering.
  const recordingUrlRef = useRef(recordingUrl)

  const isAudio = answerType === AnswerTypeEnum.Audio

  const [activeTab, setActiveTab] = useState<"player" | "transcript" | null>(
    null
  )

  const toggleActiveTab = (tab: ActiveTab) => {
    setActiveTab(prev => (prev === tab ? null : tab))
  }

  return (
    <div id={answer.id!}>
      <Panel
        size="lg"
        color="blue"
        className={clsx("whitespace-pre-line", {
          "!pb-0": isOwner,
        })}
      >
        <Response answer={answer} />
        {isOwner && (
          <ActionButtons
            answer={answer}
            activeTab={activeTab}
            toggleActiveTab={toggleActiveTab}
            isAudio={isAudio}
          />
        )}
        {activeTab === "player" && recordingUrlRef?.current && (
          <PlaystormOverviewPlayer
            recordingUrl={recordingUrlRef.current}
            autoPlay={true}
          />
        )}
        {activeTab === "transcript" && (
          <PlaystormOverviewTranscript answer={answer} />
        )}
      </Panel>
    </div>
  )
}

export default PlaystormOverviewAnswer
