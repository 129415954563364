import {PlaystormStepAnswerFragmentFragment} from "~/__generated__/graphql"
import AnswerRecorderControls from "./AnswerRecorderControls"
import {MachineType} from "./RecordingMachine"

const AnswerRecorder = ({
  answer,
  toggleAnswerType,
  machine,
}: {
  answer: PlaystormStepAnswerFragmentFragment
  toggleAnswerType: () => void
  machine: MachineType
}) => {
  const [_state, send] = machine

  const onClickStart = () => {
    send({type: "RECORD"})
  }

  const onClickPause = () => {
    send({type: "PAUSE"})
  }

  const onClickResume = () => {
    send({type: "RESUME"})
  }

  const onClickStop = () => {
    send({type: "STOP"})
  }

  const onClickReset = () => {
    send({type: "RESET"})
  }

  return (
    <div>
      <AnswerRecorderControls
        answer={answer}
        start={onClickStart}
        pause={onClickPause}
        resume={onClickResume}
        stop={onClickStop}
        reset={onClickReset}
        toggleAnswerType={toggleAnswerType}
      />
    </div>
  )
}

export default AnswerRecorder
