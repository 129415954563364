import {useMutation} from "@apollo/client"
import {gql} from "~/__generated__"

export const TEMPLATE_UPDATE_MUTATION = gql(`
  mutation TemplateUpdate($input: TemplateUpdateInput!) {
    templateUpdate(input: $input) {
      template {
        ...TemplateFragment
      }
    }
  }
`)

const useTemplateUpdate = () => {
  return useMutation(TEMPLATE_UPDATE_MUTATION)
}

export default useTemplateUpdate
