import clsx from "clsx"
import {useEffect} from "react"
import {Helmet} from "react-helmet"
import {Link, useNavigate, useSearchParams} from "react-router-dom"
import {SurveyLocationEnum} from "~/__generated__/graphql"
import CreateAccountForm from "~/components/CreateAccountForm"
import Footer from "~/components/Footer"
import Container from "~/components/ui/Container"
import Error from "~/components/ui/Error"
import Loading from "~/components/ui/Loading"
import useSurveyQuestionsQuery from "~/hooks/useSurveyQuestionsQuery"
import useTrackPage from "~/hooks/useTrackPage"
import useViewerContext from "~/hooks/useViewerContext"
import background from "~/images/backgrounds/create-account-bg.jpg"
import {trackEvent} from "~/util/analytics"

const CreateAccountScreen = () => {
  const {viewer, loading} = useViewerContext()
  const navigate = useNavigate()
  useTrackPage("Create Account")
  const {
    surveyQuestions,
    loading: surveyQuestionsLoading,
    error,
  } = useSurveyQuestionsQuery({
    variables: {
      surveyLocation: SurveyLocationEnum.Onboarding,
      first: 1,
    },
  })

  const [params] = useSearchParams()
  const email = params.get("email") ?? undefined

  useEffect(() => {
    if (viewer && !loading) {
      navigate("/")
    }
  }, [viewer, loading, navigate])

  if (surveyQuestionsLoading) return <Loading />
  if (error) return <Error message="Error loading questions" />

  return (
    <div
      className="bottom-page-bg create-account-screen-bg"
      style={{
        backgroundImage: `url(${background})`,
      }}
    >
      <Container className={clsx("pt-10", "md:pt-28")}>
        <Helmet>
          <title>Create Account</title>
        </Helmet>
        <CreateAccountForm
          nextSurveyQuestionId={surveyQuestions[0]?.id}
          email={email}
        />
        <div className="mt-4 max-w-[420px] text-center text-3xs">
          By Clicking "Create Account" above, you acknowledge that you have read
          and understood, and agree to Playstorming's{" "}
          <Link
            to="https://www.playstorming.com/terms-of-use"
            target="_blank"
            color="white"
            onClick={() => trackEvent("Click Terms & Conditions Sign Up Page")}
            className="underline hover:opacity-75"
          >
            Terms & Conditions
          </Link>{" "}
          and{" "}
          <Link
            to="https://www.playstorming.com/privacy-policy"
            target="_blank"
            color="white"
            onClick={() => trackEvent("Click View Privacy Policy Sign Up Page")}
            className="underline hover:opacity-75"
          >
            Privacy Policy
          </Link>
        </div>
      </Container>
      <div className={clsx("mt-[280px]", "mt-0")}>
        <Footer />
      </div>
    </div>
  )
}

export default CreateAccountScreen
