import pluralize from "pluralize"
import invariant from "tiny-invariant"
import {getFragmentData} from "~/__generated__"
import {
  AdminAnswerFragmentFragment,
  AnswerTypeEnum,
  QuestionTypeEnum,
} from "~/__generated__/graphql"
import {MESSAGE_FRAGMENT} from "~/hooks/useMessageUpdatedSubscription"
import {QUESTION_FRAGMENT} from "~/hooks/useQuestionQuery"
import PlaystormOverviewPlayer from "./CreativePlaystormOverview/PlaystormOverviewPlayer"
import DashboardPanel from "./ui/DashboardPanel"

const Ideas = ({listItemCount}: {listItemCount: number | null | undefined}) => {
  return (
    <div className="mt-6 text-xs">
      {pluralize("ideas", listItemCount || 0, true)}
    </div>
  )
}

const StrengtheningAnswer = ({
  answer,
}: {
  answer: AdminAnswerFragmentFragment
}) => {
  const messages = answer.strengtheningMessages.nodes.map(node =>
    getFragmentData(MESSAGE_FRAGMENT, node)
  )
  return (
    <DashboardPanel title="AI Ideas">
      <div className="divide-y divide-white/30 whitespace-pre-line">
        {messages.map(message => (
          <div className="py-4 first:pt-0 last:pb-0" key={message.id}>
            {message.text}
          </div>
        ))}
      </div>
      <Ideas listItemCount={answer.listItemCount} />
    </DashboardPanel>
  )
}

const Section = ({
  title,
  children,
}: {
  title: string
  children: React.ReactNode
}) => {
  return (
    <div className="whitespace-pre-line text-sm">
      <div className="mb-2 text-lg font-semibold">{title}</div>
      <div>{children}</div>
    </div>
  )
}

const Answer = ({answer}: {answer: AdminAnswerFragmentFragment}) => {
  const question = getFragmentData(QUESTION_FRAGMENT, answer.question)
  invariant(question, "Expected question to be defined")

  const summaryMessage = getFragmentData(
    MESSAGE_FRAGMENT,
    answer.summaryMessage
  )

  const affirmationMessage = getFragmentData(
    MESSAGE_FRAGMENT,
    answer.affirmationMessage
  )

  return (
    <DashboardPanel title={question.text}>
      <div className="flex flex-col gap-5">
        {answer.answerType === AnswerTypeEnum.Audio && (
          <>
            <div className="overflow-hidden rounded-lg px-10">
              {!!answer.recordingUrl && (
                <PlaystormOverviewPlayer recordingUrl={answer.recordingUrl} />
              )}
            </div>
            <Section title="Audio Transcript">{answer.transcription}</Section>
            <Section title="Grand Reveal">{summaryMessage?.text}</Section>
          </>
        )}
        {answer.answerType === AnswerTypeEnum.Text && (
          <>
            <Section title="Text Response">{answer.text}</Section>
            <Section title="Grand Reveal">{summaryMessage?.text}</Section>
          </>
        )}
        <Section title="Affirmation Text">{affirmationMessage?.text}</Section>
      </div>
      <Ideas listItemCount={answer.listItemCount} />
    </DashboardPanel>
  )
}

const AdminPlaystormAnswer = ({
  answer,
}: {
  answer: AdminAnswerFragmentFragment
}) => {
  if (answer.questionType === QuestionTypeEnum.Strengthening) {
    return <StrengtheningAnswer answer={answer} />
  }

  return <Answer answer={answer} />
}

export default AdminPlaystormAnswer
