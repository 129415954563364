import {Helmet} from "react-helmet"
import EditAccountForm from "~/components/EditAccountForm"
import ScreenTitle from "~/components/ScreenTitle"
import Container from "~/components/ui/Container"
import DashboardPanel from "~/components/ui/DashboardPanel"
import useViewer from "~/hooks/useViewer"

const CreativeAccountScreen = () => {
  const viewer = useViewer()
  return (
    <Container size="full" centered={false} className="flex flex-1 flex-col">
      <Helmet>
        <title>Update Account</title>
      </Helmet>
      <ScreenTitle>Update Account</ScreenTitle>
      <div className="flex flex-col gap-8">
        <DashboardPanel title="Account Details" color="white">
          <EditAccountForm user={viewer} />
        </DashboardPanel>
      </div>
    </Container>
  )
}

export default CreativeAccountScreen
