export const questionTypeDisplay = (questionType: string) => {
  switch (questionType) {
    case "intro":
      return "Introduction"
    case "warmup":
      return "Warmup"
    case "round":
      return "Round"
    case "strengthening":
      return "AI Strengthening"
    default:
      throw new Error(`Unknown question type: ${questionType}`)
  }
}
