const token = document.querySelector('[name="csrf-token"]')
const defaultHeaders: RequestInit["headers"] = {
  "X-CSRF-Token": token?.getAttribute("content") || "",
}

const fetchWrapper = (
  url: string,
  options: RequestInit & {skipContentType?: boolean} = {}
) => {
  const headers = defaultHeaders

  if (!options.skipContentType) {
    headers["Content-Type"] = "application/json"
  }

  return fetch(url, {
    ...options,
    headers: {
      ...headers,
      ...options.headers,
    },
  })
}

export default fetchWrapper
