import clsx from "clsx"
import {LinkProps, Link as ReactRouterLink} from "react-router-dom"

type LinkType = {
  underline?: boolean
  color?: "blue" | "white"
  block?: boolean
} & LinkProps &
  React.RefAttributes<HTMLAnchorElement>

const Link = ({underline, color = "blue", block, ...props}: LinkType) => {
  const className = clsx(
    "hover:opacity-75",
    props.className,
    color === "blue" ? "text-brand-blue" : "text-white",
    {
      underline: underline,
      block: block,
    }
  )

  return <ReactRouterLink {...props} className={className} />
}

export default Link
