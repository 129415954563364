import {useQuery} from "@apollo/client"
import {getFragmentData, gql} from "~/__generated__"
import {QueryAdminPlaystormsArgs} from "~/__generated__/graphql"

export const ADMIN_PLAYSTORM_FRAGMENT = gql(`
  fragment AdminPlaystormFragment on Playstorm {
    id
    user {
      id
      name
    }
    template {
      id
      title
      status
    }
    templateId
    completedAt
    title
  }
`)

const GET_ADMIN_PLAYSTORMS_QUERY = gql(`
  query AdminPlaystorms($userId: ID, $after: String) {
    adminPlaystorms(userId: $userId, after: $after, first: 21) {
      edges {
        node {
          ...AdminPlaystormFragment
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`)

const useAdminPlaystormsQuery = ({
  variables = {},
}: {
  variables?: QueryAdminPlaystormsArgs
}) => {
  const {data, loading, error, fetchMore} = useQuery(
    GET_ADMIN_PLAYSTORMS_QUERY,
    {variables}
  )

  const pageInfo = data?.adminPlaystorms.pageInfo
  const endCursor = pageInfo?.endCursor
  const hasNextPage = pageInfo?.hasNextPage

  const fetchNextPage = () => {
    fetchMore({
      variables: {
        after: endCursor,
      },
    })
  }

  const playstorms =
    data?.adminPlaystorms.edges.map(edge =>
      getFragmentData(ADMIN_PLAYSTORM_FRAGMENT, edge.node)
    ) || []

  return {
    playstorms,
    data,
    loading,
    error,
    hasNextPage,
    endCursor,
    fetchNextPage,
  }
}

export default useAdminPlaystormsQuery
