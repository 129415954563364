import clsx from "clsx"
import {Outlet} from "react-router-dom"
import useViewerMaybe from "~/hooks/useViewerMaybe"
import {Menu} from "./AuthenticatedLayout"

const PublicLayout = () => {
  const viewer = useViewerMaybe()
  return (
    <div className={clsx("flex min-h-full flex-col", "md:flex-row")}>
      {viewer && <Menu hasGrayBackground={false} />}
      <div className="flex min-w-0 flex-1 flex-col">
        <div className="flex h-full flex-1 flex-col">
          <Outlet />
        </div>
      </div>
    </div>
  )
}

export default PublicLayout
