import {useMutation} from "@apollo/client"
import {gql} from "~/__generated__"
import {GET_PLAYSTORM_OVERVIEW_QUERY} from "./usePlaystormOverviewQuery"

export const MESSAGE_CREATE_MUTATION = gql(`
  mutation MessageCreate($input: MessageCreateInput!) {
    messageCreate(input: $input) {
      message {
        ...MessageFragment
      }
    }
  }
`)

const useMessageCreate = () => {
  return useMutation(MESSAGE_CREATE_MUTATION, {
    refetchQueries: [
      GET_PLAYSTORM_OVERVIEW_QUERY, // Refetch PlaystormOverview to get the created strengthening message
    ],
  })
}

export default useMessageCreate
