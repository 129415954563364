import {createBrowserRouter} from "react-router-dom"
import {
  accountPath,
  accountSurveyQuestionPath,
  adminEditTemplatePath,
  adminNewOrganizationPath,
  adminNewTemplatePath,
  adminOrganizationPath,
  adminOrganizationsPath,
  adminPlaystormPath,
  adminPlaystormsPath,
  adminTemplatesPath,
  adminUserPath,
  adminUsersPath,
  createAccountPath,
  editAccountPath,
  editPasswordPath,
  favoritesPath,
  forgotPasswordPath,
  logInPath,
  newPlaystormPath,
  playstormAffirmationPath,
  playstormPath,
  playstormQuestionPath,
  playstormSurveyQuestionPath,
  playstormsPath,
  resetPasswordPath,
} from "./paths"
import AccountSurveyQuestionScreen from "./screens/AccountSurveyQuestionScreen"
import AuthenticatedLayout from "./screens/AuthenticatedLayout"
import AuthenticatedRoute from "./screens/AuthenticatedRoute"
import CreateAccountScreen from "./screens/CreateAccountScreen"
import DashboardScreen from "./screens/DashboardScreen"
import ErrorBoundary from "./screens/ErrorBoundary"
import ForgotPasswordScreen from "./screens/ForgotPasswordScreen"
import Layout from "./screens/Layout"
import LogInScreen from "./screens/LogInScreen"
import PlaystormSurveyQuestionScreen from "./screens/PlaystormSurveyQuestionScreen"
import PublicLayout from "./screens/PublicLayout"
import ResetPasswordScreen from "./screens/ResetPasswordScreen"
import AdminEditTemplateScreen from "./screens/admin/AdminEditTemplateScreen"
import AdminNewTemplateScreen from "./screens/admin/AdminNewTemplateScreen"
import AdminPlaystormScreen from "./screens/admin/AdminPlaystormScreen"
import AdminPlaystormsScreen from "./screens/admin/AdminPlaystormsScreen"
import AdminTemplatesScreen from "./screens/admin/AdminTemplatesScreen"
import AdminUserScreen from "./screens/admin/AdminUserScreen"
import AdminUsersScreen from "./screens/admin/AdminUsersScreen"
import CreativeAccountScreen from "./screens/creative/CreativeAccountScreen"
import CreativeEditAccountScreen from "./screens/creative/CreativeEditAccountScreen"
import CreativeEditPasswordScreen from "./screens/creative/CreativeEditPasswordScreen"
import CreativeFavoritesScreen from "./screens/creative/CreativeFavoritesScreen"
import CreativeNewPlaystormScreen from "./screens/creative/CreativeNewPlaystormScreen"
import CreativePlaystormAffirmationScreen from "./screens/creative/CreativePlaystormAffirmationScreen"
import CreativePlaystormQuestionScreen from "./screens/creative/CreativePlaystormQuestionScreen"
import CreativePlaystormScreen from "./screens/creative/CreativePlaystormScreen"
import CreativePlaystormsScreen from "./screens/creative/CreativePlaystormsScreen"
import AdminOrganizationsScreen from "./screens/admin/AdminOrganizationsScreen"
import AdminNewOrganizationScreen from "./screens/admin/AdminNewOrganizationScreen"
import AdminOrganizationScreen from "./screens/admin/AdminOrganizationScreen"

const publicRoutes = [
  {
    element: <PublicLayout />,
    children: [
      {
        path: createAccountPath,
        element: <CreateAccountScreen />,
      },
      {
        path: logInPath,
        element: <LogInScreen />,
      },
      {
        path: forgotPasswordPath,
        element: <ForgotPasswordScreen />,
      },
      {
        path: resetPasswordPath,
        element: <ResetPasswordScreen />,
      },
      {
        path: playstormPath.pattern,
        element: <CreativePlaystormScreen />,
      },
    ],
  },
]

const authenticatedRoutes = [
  {
    element: <AuthenticatedLayout />,
    children: [
      {
        path: "/",
        element: <DashboardScreen />,
      },
      {
        path: adminTemplatesPath,
        element: <AdminTemplatesScreen />,
      },
      {
        path: adminPlaystormsPath,
        element: <AdminPlaystormsScreen />,
      },
      {
        path: adminPlaystormPath.pattern,
        element: <AdminPlaystormScreen />,
      },
      {
        path: adminUsersPath,
        element: <AdminUsersScreen />,
      },
      {
        path: adminUserPath.pattern,
        element: <AdminUserScreen />,
      },
      {
        path: adminOrganizationsPath,
        element: <AdminOrganizationsScreen />,
      },
      {
        path: adminNewOrganizationPath,
        element: <AdminNewOrganizationScreen />,
      },
      {
        path: adminOrganizationPath.pattern,
        element: <AdminOrganizationScreen />,
      },
      {
        path: adminNewTemplatePath,
        element: <AdminNewTemplateScreen />,
      },
      {
        path: adminEditTemplatePath.pattern,
        element: <AdminEditTemplateScreen />,
      },
      {
        path: newPlaystormPath.pattern,
        element: <CreativeNewPlaystormScreen />,
      },
      {
        path: playstormsPath,
        element: <CreativePlaystormsScreen />,
      },
      {
        path: favoritesPath,
        element: <CreativeFavoritesScreen />,
      },
      {
        path: accountSurveyQuestionPath.pattern,
        element: <AccountSurveyQuestionScreen />,
      },
      {
        path: playstormQuestionPath.pattern,
        element: <CreativePlaystormQuestionScreen />,
      },
      {
        path: playstormAffirmationPath.pattern,
        element: <CreativePlaystormAffirmationScreen />,
      },

      {
        path: playstormSurveyQuestionPath.pattern,
        element: <PlaystormSurveyQuestionScreen />,
      },
      {
        path: accountPath,
        element: <CreativeAccountScreen />,
      },
      {
        path: editPasswordPath,
        element: <CreativeEditPasswordScreen />,
      },
      {
        path: editAccountPath,
        element: <CreativeEditAccountScreen />,
      },
    ],
  },
]

const router = createBrowserRouter([
  {
    element: <Layout />,
    errorElement: <ErrorBoundary />,
    children: [
      ...publicRoutes,
      {
        element: <AuthenticatedRoute />,
        children: authenticatedRoutes,
      },
    ],
  },
])

export default router
