import clsx from "clsx"

const PlaystormOverviewAnswerHeading = ({
  children,
}: {
  children: React.ReactNode
}) => (
  <div className={clsx("text-xl font-bold leading-8")} children={children} />
)

export default PlaystormOverviewAnswerHeading
