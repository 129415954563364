import {useMutation} from "@apollo/client"
import invariant from "tiny-invariant"
import {gql} from "~/__generated__"

export const QUESTION_DELETE_MUTATION = gql(`
  mutation QuestionDelete($input: QuestionDeleteInput!) {
    questionDelete(input: $input) {
      question {
        id
      }
    }
  }
`)

const useQuestionDelete = () => {
  return useMutation(QUESTION_DELETE_MUTATION, {
    update(cache, {data}) {
      invariant(data?.questionDelete, "Missing question delete response")
      const {id, __typename} = data.questionDelete.question
      const normalizedId = cache.identify({id, __typename})
      cache.evict({id: normalizedId})
      cache.gc()
    },
  })
}

export default useQuestionDelete
