import useQuestionQuery from "~/hooks/useQuestionQuery"
import AdminQuestionUpdateForm from "./AdminQuestionUpdateForm"
import Loading from "./ui/Loading"
import Modal from "./ui/Modal"

const ModalContent = ({
  questionId,
  close,
}: {
  questionId: string
  close: () => void
}) => {
  const {question, loading, error} = useQuestionQuery(questionId)
  if (loading) return <Loading />
  if (error || !question) throw error

  return <AdminQuestionUpdateForm question={question} close={close} />
}

const UpdateAdminQuestionModal = ({
  questionId,
  close,
}: {
  questionId: string
  close: () => void
}) => {
  return (
    <Modal title="Add Question" onClose={close} narrow={true}>
      <ModalContent questionId={questionId} close={close} />
    </Modal>
  )
}

export default UpdateAdminQuestionModal
