import clsx from "clsx"
import {useField} from "formik"
import {useEffect, useRef} from "react"
import TextareaAutosize from "react-autosize-textarea"
import {FormikFieldType} from "./FormikField"

const SIZE_CLASSES = {
  md: "rounded-md p-2.5 text-sm",
  lg: "rounded-lg p-6 text-base font-semibold",
}

export const getInputFieldClassName = ({
  size = "md",
  disabled,
  light,
  inputClassName,
}: {
  size?: FormikFieldType["size"]
  disabled?: boolean
  light?: boolean
  inputClassName?: string
}) =>
  clsx(
    "block w-full font-sans focus:outline-1 ",
    SIZE_CLASSES[size],
    light
      ? "border border-gray-200 shadow-input-light"
      : "bg-white text-black focus:outline-black/20",
    inputClassName,
    {
      "bg-gray-200": disabled,
    }
  )

const FormikInputField: FormikFieldType = ({
  light,
  inputClassName,
  focusTrigger,
  ...props
}) => {
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (focusTrigger) {
      inputRef.current?.focus()
    }
  }, [focusTrigger])

  const [field] = useField(props)
  const className = getInputFieldClassName({light, inputClassName, ...props})

  if (props.as === "textarea") {
    return (
      <TextareaAutosize
        {...field}
        {...props}
        ref={inputRef}
        className={className}
      />
    )
  }

  return <input {...field} {...props} className={className} ref={inputRef} />
}

export default FormikInputField
