import {useMutation} from "@apollo/client"
import {gql} from "~/__generated__"

export const SURVEY_ANSWER_UPDATE_MUTATION = gql(`
  mutation SurveyAnswerUpdate($input: SurveyAnswerUpdateInput!) {
    surveyAnswerUpdate(input: $input) {
      surveyAnswer {
        ...SurveyAnswerFragment
      }
    }
  }
`)

const useSurveyAnswerUpdate = () => {
  return useMutation(SURVEY_ANSWER_UPDATE_MUTATION)
}

export default useSurveyAnswerUpdate
