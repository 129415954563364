import {useLayoutEffect} from "react"
import {useLocation} from "react-router-dom"

const ScrollToHash = () => {
  const location = useLocation()
  useLayoutEffect(() => {
    const hash = location.hash.slice(1)
    if (hash) {
      document.getElementById(hash)?.scrollIntoView()
    }
  }, [location.hash])

  return null
}

export default ScrollToHash
