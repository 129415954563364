import {useLocation} from "react-router-dom"
import {
  adminOrganizationsPath,
  adminPlaystormsPath,
  adminTemplatesPath,
  adminUsersPath,
} from "~/paths"
import {shouldHideMainMenu} from "./CreativeMenu"
import {
  SidebarMenuContainer,
  SidebarMenuItem,
  SidebarMenuItems,
} from "./SidebarMenu"
import ChatIcon from "./icons/chat-icon.svg"
import HomeIcon from "./icons/home-icon.svg"
import LayersIcon from "./icons/layers-icon.svg"
import UsersIcon from "./icons/users-icon.svg"
import BoxIcon from "./icons/box-icon.svg"

const AdminMenu = () => {
  const {pathname} = useLocation()

  if (shouldHideMainMenu(pathname)) {
    return null
  }

  return (
    <SidebarMenuContainer>
      <SidebarMenuItems>
        <SidebarMenuItem to={"/"}>
          <HomeIcon />
          Dashboard
        </SidebarMenuItem>
        <SidebarMenuItem to={adminTemplatesPath}>
          <LayersIcon />
          Templates
        </SidebarMenuItem>
        <SidebarMenuItem to={adminPlaystormsPath}>
          <ChatIcon />
          Playstorms
        </SidebarMenuItem>
        <SidebarMenuItem to={adminUsersPath}>
          <UsersIcon />
          Users
        </SidebarMenuItem>
        <SidebarMenuItem to={adminOrganizationsPath}>
          <BoxIcon />
          Organizations
        </SidebarMenuItem>
      </SidebarMenuItems>
    </SidebarMenuContainer>
  )
}

export default AdminMenu
