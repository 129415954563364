import {LinkProps, Link as ReactRouterLink} from "react-router-dom"
import {cn} from "~/lib/utils"

const MIN_WIDTH_CLASSES = {
  xs: "",
  sm: "",
  md: "min-w-[200px]",
  lg: "min-w-[225px]",
}

const SIZE_CLASSES = {
  xs: "text-[11px] py-1.5 px-4 font-normal",
  sm: "text-[13px] py-2 px-4",
  md: "text-[13px] font-semibold py-2.5 px-6",
  lg: "text-[13px] font-semibold py-3.5 px-6",
}

const COLORS = {
  primary:
    "bg-brand-blue text-white border border-brand-blue disabled:bg-brand-blue/20 disabled:border-brand-blue/5 disabled:text-white/20",
  outline: "bg-transparent text-white border border-white disabled:opacity-75",
  outlineHeavy:
    "bg-transparent text-white border border-white border-2 disabled:opacity-75",
  outlineDark: "bg-white text-gray9 border border-black disabled:opacity-75",
  black: "bg-gray-800 text-white border border-black disabled:opacity-75",
  ghost: "border-none disabled:opacity-75",
}

const getClassName = ({
  color,
  size = "md",
  inline,
  rounding = "lg",
  minWidth = true,
  disabled,
}: ButtonType) =>
  cn(
    "items-center justify-center gap-2 text-center font-medium",
    "hover:opacity-80",
    SIZE_CLASSES[size],
    minWidth ? MIN_WIDTH_CLASSES[size] : "",
    COLORS[color || "primary"],
    inline ? "inline-flex" : "flex w-full",
    {
      "rounded-full": rounding === "full",
      "rounded-lg": rounding === "lg",
      "rounded-md": rounding === "md",
      rounded: rounding === "default",
      "pointer-events-none opacity-75": disabled,
    }
  )

export type ButtonType = {
  color?: keyof typeof COLORS
  size?: keyof typeof SIZE_CLASSES
  inline?: boolean
  rounding?: "full" | "lg" | "default" | "md"
  disabled?: boolean
  minWidth?: boolean
  className?: string
}

export const ButtonLink = ({
  color,
  size,
  inline,
  rounding,
  disabled,
  className,
  minWidth,
  ...props
}: LinkProps & {
  external?: boolean
} & React.RefAttributes<HTMLAnchorElement> &
  ButtonType) => {
  const classes = cn(
    getClassName({color, size, inline, rounding, minWidth, disabled}),
    className
  )
  return <ReactRouterLink {...props} className={classes} />
}

const Button = ({
  color,
  size,
  inline,
  rounding,
  disabled,
  className,
  minWidth,
  ...props
}: React.ButtonHTMLAttributes<HTMLButtonElement> & ButtonType) => {
  return (
    <button
      {...props}
      className={cn(
        getClassName({color, size, inline, rounding, minWidth, disabled}),
        className
      )}
    />
  )
}

export default Button
