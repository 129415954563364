import {Helmet} from "react-helmet"
import ScreenTitle from "~/components/ScreenTitle"
import Container from "~/components/ui/Container"
import DashboardPanel from "~/components/ui/DashboardPanel"
import useTrackPage from "~/hooks/useTrackPage"
import useViewer from "~/hooks/useViewer"
import {editAccountPath, editPasswordPath} from "~/paths"

const Details = ({children}: {children: React.ReactNode}) => (
  <div className="flex flex-col gap-2" children={children} />
)

const DetailsRow = ({children}: {children: React.ReactNode}) => (
  <div className="flex items-center gap-2 text-sm" children={children} />
)

const DetailsTitle = ({children}: {children: React.ReactNode}) => (
  <div className="min-w-[150px] font-semibold" children={children} />
)

const CreativeAccountScreen = () => {
  useTrackPage("Creative Account")
  const viewer = useViewer()
  return (
    <Container size="full" centered={false} className="flex flex-1 flex-col">
      <Helmet>
        <title>My Account</title>
      </Helmet>
      <ScreenTitle>My Account</ScreenTitle>
      <div className="mt-10 flex flex-col gap-8">
        <DashboardPanel
          title="Account Details"
          action={{title: "Edit Details", path: editAccountPath}}
          color="white"
        >
          <Details>
            <DetailsRow>
              <DetailsTitle>First Name</DetailsTitle>
              <div>{viewer.firstName}</div>
            </DetailsRow>
            <DetailsRow>
              <DetailsTitle>Email</DetailsTitle>
              <div>{viewer.email}</div>
            </DetailsRow>
            <DetailsRow>
              <DetailsTitle>Organization</DetailsTitle>
              <div>{viewer.organization}</div>
            </DetailsRow>
            <DetailsRow>
              <DetailsTitle>Role</DetailsTitle>
              <div>{viewer.organizationRole}</div>
            </DetailsRow>
          </Details>
        </DashboardPanel>
        <DashboardPanel
          title="Password"
          action={{title: "Edit Password", path: editPasswordPath}}
          color="white"
        >
          <Details>
            <DetailsRow>
              <DetailsTitle>Password</DetailsTitle>
              <div>••••••••••••••••••••</div>
            </DetailsRow>
          </Details>
        </DashboardPanel>
      </div>
    </Container>
  )
}

export default CreativeAccountScreen
