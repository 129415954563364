import clsx from "clsx"

const FieldGroup = ({
  row = false,
  hidden = false,
  margin = true,
  children,
}: {
  row?: boolean
  hidden?: boolean
  margin?: boolean
  children: React.ReactNode
}) => {
  return (
    <div
      className={clsx("flex w-full gap-4", "sm:max-w-[400px]", {
        "flex-col": !row,
        hidden: hidden,
        "mt-4": margin,
      })}
    >
      {children}
    </div>
  )
}

export default FieldGroup
