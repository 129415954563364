import {Form, Formik, FormikHelpers} from "formik"
import {useNavigate} from "react-router-dom"
import invariant from "tiny-invariant"
import {
  SurveyQuestionFragmentFragment,
  SurveyQuestionTypeEnum,
} from "~/__generated__/graphql"
import useSurveyAnswerCreate from "~/hooks/useSurveyAnswerCreate"
import useViewerContext from "~/hooks/useViewerContext"
import {trackEvent} from "~/util/analytics"
import {displayErrors} from "~/util/validations"
import SurveyAnswerFields, {
  SurveyAnswerFormValues,
  getAnsweredAnswers,
  getNextSurveyQuestionPath,
} from "./SurveyAnswerFields"

const SurveyAnswerCreateForm = ({
  surveyQuestion,
  playstormId,
  playstormTemplateId,
}: {
  surveyQuestion: SurveyQuestionFragmentFragment
  playstormId?: string
  playstormTemplateId?: string
}) => {
  const navigate = useNavigate()
  const [mutate] = useSurveyAnswerCreate()
  const {refetchViewer} = useViewerContext()

  const initialAnswers =
    surveyQuestion.surveyQuestionType === SurveyQuestionTypeEnum.Value
      ? surveyQuestion.surveyOptions.nodes.map(surveyOption => ({
          id: null,
          value: 10,
          surveyOptionId: surveyOption.id,
          playstormId: null,
        }))
      : [
          {
            id: null,
            value: null,
            surveyOptionId: null,
            playstormId: null,
          },
        ]

  const initialValues: SurveyAnswerFormValues = {
    answers: initialAnswers,
  }

  const createSurveyAnswers = async (
    values: SurveyAnswerFormValues,
    {setFieldError}: FormikHelpers<SurveyAnswerFormValues>
  ) => {
    try {
      trackEvent("Submit Survey Question Form", {
        position: surveyQuestion.position?.toString() || "",
        question: surveyQuestion.title || "",
        questionId: surveyQuestion.id || "",
        surveyLocation: surveyQuestion.surveyLocation,
        playstormId: playstormId || "",
        playstormTemplateId: playstormTemplateId || "",
        type: "create",
      })
      const answeredAnswers = getAnsweredAnswers(values, surveyQuestion)
      await Promise.all(
        answeredAnswers.map(async surveyAnswer => {
          invariant(surveyAnswer.surveyOptionId, "Expected surveyOptionId")
          await mutate({
            variables: {
              input: {
                surveyAnswerInput: {
                  value: surveyAnswer.value,
                  surveyOptionId: surveyAnswer.surveyOptionId,
                  playstormId: playstormId,
                },
                surveyQuestionId: surveyQuestion.id,
              },
            },
          })
        })
      )
      const nextPath = getNextSurveyQuestionPath(surveyQuestion)
      if (nextPath === "/") {
        refetchViewer()
      }
      navigate(getNextSurveyQuestionPath(surveyQuestion))
    } catch (error: any) {
      console.error(error)
      displayErrors(error?.graphQLErrors, setFieldError)
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validateOnBlur={false}
      onSubmit={createSurveyAnswers}
    >
      <Form>
        <SurveyAnswerFields
          surveyQuestion={surveyQuestion}
          playstormId={playstormId}
          playstormTemplateId={playstormTemplateId}
        />
      </Form>
    </Formik>
  )
}

export default SurveyAnswerCreateForm
