import {Link} from "react-router-dom"
import {
  AdminPlaystormFragmentFragment,
  TemplateStatus,
} from "~/__generated__/graphql"
import {adminEditTemplatePath, adminPlaystormPath} from "~/paths"
import {dateTimeShort} from "~/util/dates"
import EmptyTableMessage from "./EmptyTableMessage"
import Button from "./ui/Button"
import {TD, TH, Table} from "./ui/Table"

const playstormStatus = (completedAt?: string) =>
  completedAt ? "Complete" : "Incomplete"

const AdminPlaystormsTable = ({
  playstorms,
  fetchNextPage,
  hasNextPage,
  loading,
}: {
  playstorms: AdminPlaystormFragmentFragment[]
  fetchNextPage: () => void
  hasNextPage: boolean
  loading: boolean
}) => {
  return (
    <div className="flex w-full flex-1 flex-col">
      <Table>
        <thead>
          <tr>
            <TH>User</TH>
            <TH>Template Title</TH>
            <TH>Playstorm Title</TH>
            <TH>Date Completed</TH>
            <TH align="right">Status</TH>
          </tr>
        </thead>
        <tbody>
          {playstorms.map((playstorm, index) => {
            const first = index === 0
            const path = adminPlaystormPath({id: playstorm.id})
            return (
              <tr key={playstorm.id} data-test-id={playstorm.id}>
                <TD first={first} className="min-w-[120px]">
                  {playstorm.user.name}
                </TD>
                <TD first={first} className="min-w-[145px]">
                  <Link to={adminEditTemplatePath({id: playstorm.templateId})}>
                    {playstorm.template.title}
                    {playstorm.template.status === TemplateStatus.Draft && (
                      <span className="ml-1 text-2xs uppercase opacity-50">
                        Draft
                      </span>
                    )}
                  </Link>
                </TD>
                <TD first={first} className="min-w-[145px]">
                  <Link to={path}>{playstorm.title}</Link>
                </TD>
                <TD first={first} className="min-w-[145px]">
                  {playstorm.completedAt ? (
                    <Link to={path}>
                      {dateTimeShort(new Date(playstorm.completedAt))}
                    </Link>
                  ) : (
                    "-"
                  )}
                </TD>
                <TD align="right" first={first}>
                  <Link to={path}>
                    {playstormStatus(playstorm.completedAt)}
                  </Link>
                </TD>
              </tr>
            )
          })}
        </tbody>
      </Table>
      {hasNextPage && (
        <div className="mt-6 flex justify-center">
          <Button
            onClick={fetchNextPage}
            color="ghost"
            inline={true}
            disabled={loading}
          >
            Load more
          </Button>
        </div>
      )}
      {!playstorms.length && <EmptyTableMessage type="playstorms" />}
    </div>
  )
}

export default AdminPlaystormsTable
