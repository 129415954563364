import clsx from "clsx"
import {FormikFieldType} from "./FormikField"

const SIZE_CLASSES = {
  md: "uppercase tracking-tight-px mb-1.5 text-2xs font-bold",
  lg: "text-sm mb-2.5",
}

export const LabelName = ({
  size = "md",
  children,
}: {
  size?: FormikFieldType["size"]
  children: React.ReactNode
}) => <div className={clsx(SIZE_CLASSES[size])}>{children}</div>

const FieldLabelContainer = ({
  label,
  skipLabelNesting,
  size,
  description,
  children,
}: {
  label: string
  skipLabelNesting?: boolean
  size?: FormikFieldType["size"]
  description?: string
  children: React.ReactNode
}) => {
  if (skipLabelNesting)
    return (
      <div className="relative block">
        <div className="flex items-center justify-between">
          <LabelName>{label}</LabelName>
          {!!description && (
            <div className="mb-1.5 ml-auto text-xs">{description}</div>
          )}
        </div>
        {children}
      </div>
    )

  return (
    <label className="relative block">
      <LabelName size={size}>{label}</LabelName>
      {children}
    </label>
  )
}

export default FieldLabelContainer
