import {useState} from "react"
import useQuestionsQuery from "~/hooks/useQuestionsQuery"
import AdminQuestionTable from "./AdminQuestionTable"
import CreateAdminQuestionModal from "./CreateAdminQuestionModal"
import UpdateAdminQuestionModal from "./UpdateAdminQuestionModal"
import Button from "./ui/Button"
import Error from "./ui/Error"
import FieldContainer from "./ui/FieldContainer"
import Loading from "./ui/Loading"

const QuestionList = ({
  templateId,
  onEditQuestion,
}: {
  templateId: string
  onEditQuestion: (id: string) => void
}) => {
  const {questions, loading, error} = useQuestionsQuery(templateId)

  if (loading) return <Loading />
  if (error || !questions) return <Error />

  return (
    <AdminQuestionTable questions={questions} onEditQuestion={onEditQuestion} />
  )
}

const TemplateQuestions = ({templateId}: {templateId?: string}) => {
  const [showCreateAdminQuestionModal, setShowCreateAdminQuestionModal] =
    useState(false)
  const [editQuestionId, setEditQuestionId] = useState<string | null>(null)

  return (
    <div>
      <FieldContainer>
        <Button
          rounding="full"
          onClick={() => setShowCreateAdminQuestionModal(true)}
        >
          Add Question
        </Button>
      </FieldContainer>

      {templateId ? (
        <QuestionList
          templateId={templateId}
          onEditQuestion={setEditQuestionId}
        />
      ) : (
        <AdminQuestionTable />
      )}

      {editQuestionId && (
        <UpdateAdminQuestionModal
          questionId={editQuestionId}
          close={() => setEditQuestionId(null)}
        />
      )}
      {!!templateId && showCreateAdminQuestionModal && (
        <CreateAdminQuestionModal
          templateId={templateId}
          close={() => setShowCreateAdminQuestionModal(false)}
        />
      )}
    </div>
  )
}

export default TemplateQuestions
