import clsx from "clsx"
import toast from "react-hot-toast"
import {QuestionFragmentFragment} from "~/__generated__/graphql"
import useQuestionDelete from "~/hooks/useQuestionDelete"
import {questionTypeDisplay} from "~/util/questionDisplay"
import DeleteIcon from "./icons/delete-icon.svg"
import EditIcon from "./icons/edit-icon.svg"
import {TD, TDType, TH, THType, Table} from "./ui/Table"

const QuestionTH = ({className, ...props}: THType) => {
  return <TH className={clsx(className, "first:pl-8 last:pr-8")} {...props} />
}

const QuestionTD = ({className, ...props}: TDType) => {
  return <TD className={clsx(className, "first:pl-8 last:pr-8")} {...props} />
}

const AdminQuestionTable = ({
  questions,
  onEditQuestion,
}: {
  questions?: QuestionFragmentFragment[]
  onEditQuestion?: (id: string) => void
}) => {
  const [mutateDelete] = useQuestionDelete()

  const deleteQuestion = async (id: string) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this question?"
    )
    if (confirmed) {
      try {
        await mutateDelete({variables: {input: {id}}})
        toast.success("Question deleted successfully!")
      } catch (error: any) {
        toast.error(
          error?.message || "Deleting the question failed. Please try again."
        )
      }
    }
  }

  return (
    <div
      className="mt-8 min-h-[230px] rounded-4xl bg-white/20 pb-10"
      data-test="questions-table"
    >
      <Table>
        <thead>
          <tr>
            <QuestionTH className="min-w-[150px]">Type</QuestionTH>
            <QuestionTH className="min-w-[200px]">Question Text</QuestionTH>
            <QuestionTH align="right" className="min-w-[100px]">
              Actions
            </QuestionTH>
          </tr>
        </thead>
        <tbody>
          {questions?.map(
            question =>
              !!question && (
                <tr key={question.id}>
                  <QuestionTD>
                    {questionTypeDisplay(question.questionType)}
                  </QuestionTD>
                  <QuestionTD>{question?.text}</QuestionTD>
                  <QuestionTD align="right" className="py-0 align-middle">
                    <div className="flex flex-row-reverse gap-1">
                      <button
                        onClick={() => deleteQuestion(question.id)}
                        className="hover:opacity-75"
                        title="Delete"
                        data-test="delete-question"
                      >
                        <DeleteIcon />
                      </button>
                      <button
                        onClick={() => {
                          if (onEditQuestion) onEditQuestion(question.id)
                        }}
                        className="hover:opacity-75"
                        title="Edit"
                        data-test="edit-question"
                      >
                        <EditIcon />
                      </button>
                    </div>
                  </QuestionTD>
                </tr>
              )
          )}
        </tbody>
      </Table>
    </div>
  )
}

export default AdminQuestionTable
