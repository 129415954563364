import {useQuery} from "@apollo/client"
import {getFragmentData, gql} from "~/__generated__"

export const ANSWER_FAVORITE_FRAGMENT = gql(`
  fragment AnswerFavoriteFragment on AnswerFavorite {
    id
    answerId
    updatedAt
    answer {
      id
      response
      playstormId
      questionType
      strengtheningMessages {
        nodes {
          text
        }
      }
      playstorm {
        title
      }
    }
  }
`)

const GET_ANSWER_FAVORITES_QUERY = gql(`
  query AnswerFavorites($after: String) {
    answerFavorites(after: $after, first: 21) {
      edges {
        node {
          ...AnswerFavoriteFragment
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`)

const useAnswerFavoritesQuery = () => {
  const {data, loading, error, fetchMore} = useQuery(GET_ANSWER_FAVORITES_QUERY)

  const pageInfo = data?.answerFavorites.pageInfo
  const endCursor = pageInfo?.endCursor
  const hasNextPage = pageInfo?.hasNextPage

  const fetchNextPage = () => {
    fetchMore({
      variables: {
        after: endCursor,
      },
    })
  }

  const answerFavorites =
    data?.answerFavorites?.edges.map(edge =>
      getFragmentData(ANSWER_FAVORITE_FRAGMENT, edge.node)
    ) || []

  return {
    answerFavorites,
    data,
    loading,
    error,
    hasNextPage,
    endCursor,
    fetchNextPage,
  }
}

export default useAnswerFavoritesQuery
