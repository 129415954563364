import {CopyToClipboard} from "react-copy-to-clipboard"
import toast from "react-hot-toast"
import LogoLg from "~/images/logo-lg.svg"
import {trackEvent} from "~/util/analytics"
import Button from "./ui/Button"
import Container from "./ui/Container"
import Modal from "./ui/Modal"

const SharePlaystormModal = ({close}: {close: () => void}) => {
  const url = window.location.href
  return (
    <>
      <Modal onClose={close} size="xl">
        <Container size="md">
          <div className="w-full">
            <div className="-mt-20 flex scale-[0.6] justify-center">
              <LogoLg />
            </div>
            <div className="mb-16 mt-2 text-center text-xl font-bold tracking-wide">
              Share Your Playstorm!
            </div>
            <div className="mb-2 pl-3 font-semibold">
              Copy URL and paste to share:
            </div>
            <div className="relative mb-10">
              <input
                value={url}
                className="w-full text-ellipsis rounded-2xl bg-latteo-gray p-6 pr-36 text-sm text-black"
              />
              <div className="absolute right-3 top-0 flex h-full items-center">
                <CopyToClipboard
                  text={url}
                  onCopy={() => toast.success("Copied link to clipboard")}
                >
                  <Button
                    rounding="full"
                    onClick={() => {
                      trackEvent("Copy Playstorm Share URL")
                    }}
                    className="uppercase"
                    inline={true}
                    minWidth={false}
                  >
                    Copy
                  </Button>
                </CopyToClipboard>
              </div>
            </div>
          </div>
        </Container>
      </Modal>
    </>
  )
}

export default SharePlaystormModal
