import {useQuery} from "@apollo/client"
import {getFragmentData, gql} from "~/__generated__"
import {ADMIN_USER_FRAGMENT} from "./useAdminUserQuery"

const GET_ADMIN_USERS_QUERY = gql(`
  query AdminUsers($after: String) {
    users(after: $after, first: 21) {
      edges {
        node {
          ...AdminUserFragment
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`)

const useAdminUsersQuery = () => {
  const {data, loading, error, fetchMore} = useQuery(GET_ADMIN_USERS_QUERY)

  const pageInfo = data?.users.pageInfo
  const endCursor = pageInfo?.endCursor
  const hasNextPage = pageInfo?.hasNextPage

  const fetchNextPage = () => {
    fetchMore({
      variables: {
        after: endCursor,
      },
    })
  }

  const users =
    data?.users.edges.map(edge =>
      getFragmentData(ADMIN_USER_FRAGMENT, edge.node)
    ) || []

  return {
    users,
    data,
    loading,
    error,
    hasNextPage,
    endCursor,
    fetchNextPage,
  }
}

export default useAdminUsersQuery
