import {useEffect} from "react"
import {Outlet, useNavigate} from "react-router-dom"
import useViewerContext from "~/hooks/useViewerContext"
import {logInPath} from "~/paths"

const AuthenticatedRoute = () => {
  const {viewer, loading} = useViewerContext()
  const navigate = useNavigate()

  useEffect(() => {
    if (!viewer && !loading) {
      navigate(logInPath)
    }
  }, [viewer, loading, navigate])

  return viewer ? <Outlet /> : null
}

export default AuthenticatedRoute
