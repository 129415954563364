import {GraphQLErrors} from "@apollo/client/errors"
import {camelCase, capitalize} from "lodash"
import {toast} from "react-hot-toast"

export type SetFieldErrorType = (
  field: string,
  message: string | undefined
) => void

const attributeDisplayName = (name: string) =>
  name.split("_").map(capitalize).join(" ")
const showFieldErrors = (
  key: string,
  errors: string[],
  setFieldError: SetFieldErrorType
) => {
  const errorMessages = errors?.map(
    error => `${attributeDisplayName(key)} ${error}`
  )
  if (errorMessages?.length) {
    setFieldError(camelCase(key), `${errorMessages.join(". ")}.`)
  }
}

// Displays a toast and sets formik field errors based on ActiveRecord errors
// Example errors object:
// "errors": [
//   {
//       "extensions": {
//           "country": [
//               "can't be blank"
//           ],
//           "code": "VALIDATION_ERROR"
//       }
//   }
// ]
export const displayErrors = (
  errors?: GraphQLErrors,
  setFieldError?: SetFieldErrorType
) => {
  const validationErrors = errors?.find(
    error => error.extensions?.code === "VALIDATION_ERROR"
  )

  toast.error(
    validationErrors?.message || "An error has occurred, please try again"
  )

  if (validationErrors) {
    if (setFieldError) {
      const fields = Object.keys(validationErrors.extensions).filter(
        key => key !== "code"
      )
      fields.forEach(key => {
        const errors = validationErrors.extensions[key] as string[]
        showFieldErrors(key, errors, setFieldError)
      })
    }
  }
}

export const displayActiveRecordErrors = (
  errors: Record<string, string[]>,
  setFieldError: SetFieldErrorType
) => {
  Object.keys(errors).forEach(key => {
    showFieldErrors(key, errors[key], setFieldError)
  })
}
