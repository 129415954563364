import clsx from "clsx"
import {Helmet} from "react-helmet"
import {useParams} from "react-router-dom"
import invariant from "tiny-invariant"
import PlaystormCreateForm from "~/components/PlaystormCreateForm"
import PlaystormHeader from "~/components/PlaystormHeader"
import Container from "~/components/ui/Container"
import DashboardPanel from "~/components/ui/DashboardPanel"
import Error from "~/components/ui/Error"
import {H1} from "~/components/ui/Headings"
import Loading from "~/components/ui/Loading"
import useTemplateQuery from "~/hooks/useTemplateQuery"
import useTrackPage from "~/hooks/useTrackPage"
import background from "~/images/backgrounds/creative-new-playstorm-bg.jpg"

const CreativeNewPlaystormScreen = () => {
  const {templateId} = useParams()
  invariant(templateId, "Expected templateId to be defined")
  useTrackPage("Creative New Playstorm")
  const {template, error, loading} = useTemplateQuery(templateId)

  if (loading) return <Loading />
  if (error || !template) return <Error message="Error loading template" />

  return (
    <div
      className="bottom-page-bg pb-[220px]"
      style={{
        backgroundImage: `url(${background})`,
      }}
    >
      <Container>
        <Helmet>
          <title>New Playstorm</title>
        </Helmet>
        <PlaystormHeader confirmClose={false} />
        <div
          className={clsx("mx-auto max-w-[680px] px-6 text-center", "md:px-0")}
        >
          <div className="mb-20">
            <H1 size="lg">
              Welcome to Playstorming, your next-level brainstorming tool.
            </H1>
          </div>

          <DashboardPanel>
            <div
              className={clsx(
                "mx-auto mb-10 mt-5 max-w-[550px] text-xs font-semibold leading-5",
                "md:text-sm md:leading-6"
              )}
            >
              Please enter a title that accurately reflect the purpose and
              content of your Playstorm, making it easy to find and reference on
              your dashboard later.
            </div>
            <div className="pb-4">
              <PlaystormCreateForm
                templateId={templateId}
                templateTitle={template.title}
              />
            </div>
          </DashboardPanel>
        </div>
      </Container>
    </div>
  )
}

export default CreativeNewPlaystormScreen
