import {Formik, FormikHelpers} from "formik"
import toast from "react-hot-toast"
import invariant from "tiny-invariant"
import {getFragmentData} from "~/__generated__"
import env from "~/env"
import useQuestionCreate from "~/hooks/useQuestionCreate"
import {QUESTION_FRAGMENT} from "~/hooks/useQuestionQuery"
import useTTSUpload from "~/hooks/useTTSUpload"
import {displayErrors} from "~/util/validations"
import AdminQuestionFields, {
  QuestionFormValues,
  gptModelOptions,
  questionValidationSchema,
} from "./AdminQuestionFields"

const AdminQuestionCreateForm = ({
  templateId,
  close,
}: {
  templateId: string
  close: () => void
}) => {
  const [mutate] = useQuestionCreate()
  const ttsUpload = useTTSUpload()
  const {uploadAudio} = ttsUpload

  const initialValues = {
    text: "",
    affirmationPrompt: "",
    grandRevealPrompt: "",
    questionType: "",
    gptModel: gptModelOptions[0].value,
    audio: "",
  }

  const createQuestion = async (
    values: QuestionFormValues,
    {setFieldError}: FormikHelpers<QuestionFormValues>
  ) => {
    try {
      invariant(templateId, "Expected template id")

      let signedAudioId = undefined
      // TODO: Mock uploads instead of skipping in test env
      if (!!values.text && env.ENVIRONMENT !== "test") {
        console.log("aaa")
        console.log(env.ENVIRONMENT)
        signedAudioId = await uploadAudio(values.text)
      }
      console.log(signedAudioId)
      const resp = await mutate({
        variables: {
          input: {
            templateId,
            questionInput: {
              text: values.text,
              affirmationPrompt: values.affirmationPrompt,
              grandRevealPrompt: values.grandRevealPrompt,
              questionType: values.questionType,
              gptModel: values.gptModel,
              audio: signedAudioId,
            },
          },
        },
      })
      const createdQuestion = getFragmentData(
        QUESTION_FRAGMENT,
        resp.data?.questionCreate.question
      )
      const id = createdQuestion?.id
      invariant(id, "Expected question id")
      toast.success("Question created successfully!")
      close()
    } catch (error: any) {
      displayErrors(error?.graphQLErrors, setFieldError)
    }
  }

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={questionValidationSchema}
        validateOnBlur={false}
        onSubmit={createQuestion}
      >
        <AdminQuestionFields close={close} ttsUpload={ttsUpload} />
      </Formik>
    </div>
  )
}

export default AdminQuestionCreateForm
