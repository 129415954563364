import clsx from "clsx"

const SIZE_CLASSES = {
  sm: "w-full max-w-[476px]",
  md: "w-full max-w-[640px]",
  lg: "w-full max-w-[1024px]",
  full: "w-full",
}

const Container = ({
  size = "full",
  className,
  centered = true,
  children,
}: {
  size?: keyof typeof SIZE_CLASSES
  className?: string
  centered?: boolean
  children: React.ReactNode
}) => {
  return (
    <div
      className={clsx(
        "mx-auto flex flex-col px-4 py-6",
        "md:px-8",
        SIZE_CLASSES[size],
        className,
        {
          "items-center": centered,
        }
      )}
    >
      {children}
    </div>
  )
}

export default Container
