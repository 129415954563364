import clsx from "clsx"
import {Link} from "react-router-dom"

const DashboardPanel = ({
  title,
  action,
  className,
  color = "gray",
  children,
}: {
  title?: string
  action?: {title: string; path: string}
  color?: "white" | "gray"
  className?: string
  children: React.ReactNode
}) => {
  return (
    <div
      className={clsx(
        "w-full overflow-hidden rounded-2xl p-6",
        {
          "pt-0": !!title,
        },
        color === "white" ? "bg-white" : "bg-latteo-gray",
        className
      )}
    >
      {!!title && (
        <div className="-mx-6 mb-6 flex items-center justify-between border-b border-black/5 px-6 py-2.5">
          <div className="text-lg font-semibold tracking-wide">{title}</div>
          {action && (
            <Link to={action.path} className="text-sm font-semibold">
              {action.title}
            </Link>
          )}
        </div>
      )}

      {children}
    </div>
  )
}

export default DashboardPanel
