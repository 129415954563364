import {TemplateFragmentFragment} from "~/__generated__/graphql"
import TemplateQuestions from "~/components/TemplateQuestions"
import HR from "~/components/ui/HR"
import TemplateCreateForm from "./TemplateCreateForm"
import TemplateUpdateForm from "./TemplateUpdateForm"

const SubHeading = ({children}: {children: React.ReactNode}) => {
  return (
    <div className="my-8">
      <div>{children}</div>
      <HR />
    </div>
  )
}

const TemplateScreenContent = ({
  ...props
}: {
  template?: TemplateFragmentFragment
}) => {
  return (
    <div className="flex flex-col gap-8">
      <div>
        <SubHeading>Template Details</SubHeading>
        {props.template ? (
          <TemplateUpdateForm template={props.template} />
        ) : (
          <TemplateCreateForm />
        )}
      </div>
      <div className={props.template ? "" : "pointer-events-none opacity-40"}>
        <SubHeading>Template Questions</SubHeading>
        <TemplateQuestions templateId={props.template?.id} />
      </div>
    </div>
  )
}

export default TemplateScreenContent
