import {Link} from "react-router-dom"
import Logo from "./Logo"

const FormLogoHeader = () => {
  return (
    <div className="mb-12 flex justify-center">
      <Link to="/">
        <Logo />
      </Link>
    </div>
  )
}

export default FormLogoHeader
