import {setUserId} from "@amplitude/analytics-browser"
import {Form, Formik, FormikHelpers, FormikProps} from "formik"
import {toast} from "react-hot-toast"
import * as Yup from "yup"
import Button from "~/components/ui/Button"
import FormikField from "~/components/ui/FormikField"
import {H1} from "~/components/ui/Headings"
import Link from "~/components/ui/Link"
import {accountSurveyQuestionPath, logInPath, signUpPostPath} from "~/paths"
import {trackEvent} from "~/util/analytics"
import fetchWrapper from "~/util/fetchWrapper"
import {displayActiveRecordErrors} from "~/util/validations"
import Logo from "./Logo"
import FieldGroup from "./ui/FieldGroup"
import Panel from "./ui/Panel"

type Values = {
  firstName: string
  lastName: string
  email: string
  organization: string
  organizationRole: string
  password: string
  passwordConfirmation: string
}

const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  organization: "",
  organizationRole: "",
  password: "",
  passwordConfirmation: "",
}

const validationSchema = Yup.object({
  email: Yup.string().email("Email is invalid").required("Email is required"),
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  organization: Yup.string().required("Organization is required"),
  organizationRole: Yup.string().required("Role is required"),
  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .required("A password is required"),
  passwordConfirmation: Yup.string()
    .required("Password confirmation is required")
    .oneOf([Yup.ref("password"), ""], "Passwords must match"),
})

const CreateAccountForm = ({
  nextSurveyQuestionId,
  email,
}: {
  nextSurveyQuestionId: string
  email?: string
}) => {
  const onSubmit = async (
    values: Values,
    {setFieldError}: FormikHelpers<Values>
  ) => {
    trackEvent("Submit Create Account Form")

    const params = {
      user: {
        first_name: values.firstName,
        last_name: values.lastName,
        email: values.email,
        organization: values.organization,
        organization_role: values.organizationRole,
        password: values.password,
        password_confirmation: values.passwordConfirmation,
      },
    }
    try {
      const response = await fetchWrapper(signUpPostPath, {
        method: "POST",
        body: JSON.stringify(params),
      })
      const data = await response.json()
      if (data?.userId) {
        const nextPath = nextSurveyQuestionId
          ? accountSurveyQuestionPath({id: nextSurveyQuestionId})
          : "/"
        setUserId(data.userId)
        trackEvent("Created Account")
        window.location.replace(nextPath)
      } else {
        const validationErrors = data?.errors
        if (validationErrors) {
          displayActiveRecordErrors(validationErrors, setFieldError)
        } else {
          const message =
            data?.response?.data?.error || "Sign up failed, please try again"
          toast.error(message)
        }
      }
    } catch (error) {
      console.error(error)
      const message = "Sign up failed, please try again"
      toast.error(message)
    }
  }

  return (
    <div className="flex w-full flex-col items-center">
      <div className="mb-12 flex justify-center">
        <Link to="/">
          <Logo />
        </Link>
      </div>

      <Panel size="sm">
        <Formik
          initialValues={{...initialValues, email: email || ""}}
          validationSchema={validationSchema}
          validateOnBlur={false}
          onSubmit={onSubmit}
        >
          {({isSubmitting}: FormikProps<Values>) => (
            <Form>
              <div className="flex flex-col gap-10">
                <div>
                  <H1 center={true} id="sign-in">
                    Create an account
                  </H1>
                  <div className="mt-4 flex flex-col gap-2">
                    <FieldGroup row={true}>
                      <FormikField
                        name="firstName"
                        type="text"
                        label="First Name"
                        autoFocus={true}
                      />
                      <FormikField
                        name="lastName"
                        type="text"
                        label="Last Name"
                      />
                    </FieldGroup>
                    <FormikField
                      name="email"
                      type="email"
                      label="Email Address"
                    />
                    <FormikField
                      name="organization"
                      type="text"
                      label="Organization"
                    />
                    <FormikField
                      name="organizationRole"
                      type="text"
                      label="Role"
                    />

                    <div className="mb-2 mt-3 flex items-center gap-4">
                      <div className="flex-1 border-t border-brand-blue" />
                      <div className="flex text-2xs font-semibold uppercase">
                        Password
                      </div>
                      <div className="flex-1 border-t border-brand-blue" />
                    </div>

                    <FormikField
                      name="password"
                      type="password"
                      label="Create Password"
                    />
                    <FormikField
                      name="passwordConfirmation"
                      type="password"
                      label="Confirm Password"
                    />
                  </div>
                </div>
                <Button type="submit" disabled={isSubmitting}>
                  Create Account
                </Button>
                <div className="mb-1 mt-2 flex flex-col items-center justify-center sm:flex-row sm:gap-5">
                  <div>Already have an account?</div>
                  <Link to={logInPath}>Log in</Link>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Panel>
    </div>
  )
}

export default CreateAccountForm
