import {useEffect} from "react"
import {Helmet} from "react-helmet"
import {useNavigate, useParams} from "react-router-dom"
import invariant from "tiny-invariant"
import CreativePlaystormOverview from "~/components/CreativePlaystormOverview"
import PlaystormHeader from "~/components/PlaystormHeader"
import ScrollToHash from "~/components/ScrollToHash"
import Container from "~/components/ui/Container"
import Error from "~/components/ui/Error"
import Loading from "~/components/ui/Loading"
import usePlaystormOverviewQuery from "~/hooks/usePlaystormOverviewQuery"
import useTrackPage from "~/hooks/useTrackPage"
import {playstormQuestionPath} from "~/paths"

const CreativePlaystormScreen = () => {
  const {id} = useParams()
  invariant(id, "Expected playstorm id")
  useTrackPage("Playstorm Grand Reveal")
  const {playstormOverview, loading, error} = usePlaystormOverviewQuery(id)
  const navigate = useNavigate()

  useEffect(() => {
    if (!!playstormOverview?.playstorm?.nextQuestionId) {
      navigate(
        playstormQuestionPath({
          id,
          questionId: playstormOverview?.playstorm?.nextQuestionId,
        }),
        {replace: true}
      )
    }
  }, [id, navigate, playstormOverview.answers, playstormOverview.playstorm])

  if (loading && !playstormOverview.playstorm) return <Loading />
  if (error || !playstormOverview.playstorm || !playstormOverview.answers)
    return <Error />

  const {playstorm, answers} = playstormOverview

  return (
    <Container size="lg">
      <Helmet>
        <title>{playstorm.title}</title>
      </Helmet>
      <ScrollToHash />
      <PlaystormHeader canClose={false} />
      <CreativePlaystormOverview playstorm={playstorm} answers={answers} />
    </Container>
  )
}

export default CreativePlaystormScreen
