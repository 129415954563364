import {useQuery} from "@apollo/client"
import {getFragmentData, gql} from "~/__generated__"

export const CREATIVE_PLAYSTORM_INDEX_FRAGMENT = gql(`
  fragment CreativePlaystormIndexFragment on Playstorm {
    id
    templateTitle
    createdAt
    updatedAt
    completedAt
    title
  }
`)

const GET_CREATIVE_PLAYSTORMS_QUERY = gql(`
  query CreativePlaystorms($after: String) {
    creativePlaystorms(after: $after, first: 21) {
      edges {
        node {
          ...CreativePlaystormIndexFragment
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`)

const useCreativePlaystormsQuery = () => {
  const {data, loading, error, fetchMore} = useQuery(
    GET_CREATIVE_PLAYSTORMS_QUERY
  )

  const pageInfo = data?.creativePlaystorms.pageInfo
  const endCursor = pageInfo?.endCursor
  const hasNextPage = pageInfo?.hasNextPage

  const fetchNextPage = () => {
    fetchMore({
      variables: {
        after: endCursor,
      },
    })
  }

  const playstorms =
    data?.creativePlaystorms.edges.map(edge =>
      getFragmentData(CREATIVE_PLAYSTORM_INDEX_FRAGMENT, edge.node)
    ) || []

  return {
    playstorms,
    data,
    loading,
    error,
    hasNextPage,
    endCursor,
    fetchNextPage,
  }
}

export default useCreativePlaystormsQuery
