import {Position} from "~/__generated__/graphql"

const PlaystormProgress = ({position}: {position: Position}) => {
  const {total, index} = position
  const number = index + 1
  const percent = (number / total) * 100
  return (
    <div className="flex w-full flex-col items-center gap-3">
      <div className="relative flex h-[4px] w-full max-w-[330px] bg-brand-blue/40">
        <div className="h-full bg-brand-blue" style={{width: `${percent}%`}} />
        <div
          className="absolute -ml-[6px] -mt-[4px] h-[12px] w-[12px] rounded-full bg-black"
          style={{boxShadow: "0px 2px 1px 0px #00000026", left: `${percent}%`}}
        />
      </div>

      <div className="text-2xs font-bold">
        {number} of {total}
      </div>
    </div>
  )
}

export default PlaystormProgress
