import toast from "react-hot-toast"
import {createMachine} from "xstate"

// Invoke actors are provided in the machine hook.

// TODO: add xstate codegen once available for v5

const recordingMachine = createMachine({
  id: "machine",
  initial: "idle",
  context: {},
  states: {
    idle: {
      on: {
        RECORD: {target: "recording"},
      },
    },
    recording: {
      initial: "starting",
      states: {
        starting: {
          invoke: {
            src: "startRecording",
            onDone: {
              target: "started",
            },
            onError: {
              target: "#machine",
              actions: () => {
                toast.error("Error starting recording")
              },
            },
          },
        },
        started: {
          on: {
            PAUSE: {target: "#machine.paused"},
          },
        },
      },
    },
    paused: {
      on: {
        RESUME: {target: "recording"},
        STOP: {target: "recorded"},
        RESET: {target: "idle", actions: ["reset"]},
      },
      invoke: {
        src: "pauseRecording",
        onError: {
          target: "recording",
          actions: () => {
            toast.error("Error pausing recording")
          },
        },
      },
    },
    recorded: {
      initial: "stopping",
      states: {
        stopping: {
          invoke: {
            src: "stopRecording",
            onDone: {
              target: "stopped",
            },
            onError: {
              actions: () => {
                toast.error("Error stopping recording")
              },
            },
          },
        },
        stopped: {
          on: {
            SUBMIT: {target: "#machine.submitting"},
          },
        },
      },
    },
    submitting: {
      invoke: {
        src: "submitRecording",
        onDone: {
          target: "submitted",
        },
        onError: {
          actions: () => {
            toast.error("Error saving answer")
          },
        },
      },
    },
    submitted: {
      on: {
        ERROR: {target: "paused"},
      },
    },
  },
})

export default recordingMachine
