import {Form, Formik, FormikHelpers, FormikProps} from "formik"
import {toast} from "react-hot-toast"
import {useSearchParams} from "react-router-dom"
import * as Yup from "yup"
import Button from "~/components/ui/Button"
import FormikField from "~/components/ui/FormikField"
import {H1} from "~/components/ui/Headings"
import Link from "~/components/ui/Link"
import {createAccountPath, logInPath, resetPasswordPostPath} from "~/paths"
import fetchWrapper from "~/util/fetchWrapper"
import {displayActiveRecordErrors} from "~/util/validations"
import Logo from "./Logo"
import Panel from "./ui/Panel"

type Values = {
  password: string
  passwordConfirmation: string
}

const initialValues = {
  password: "",
  passwordConfirmation: "",
}

const validationSchema = Yup.object({
  password: Yup.string()
    .min(6, "New password must be at least 6 characters")
    .required("A password is required"),
  passwordConfirmation: Yup.string()
    .required("A password confirmation is required")
    .oneOf([Yup.ref("password"), ""], "Passwords must match"),
})

const errorMessage = "Password update failed, please try again"

const ResetPasswordForm = () => {
  const [search] = useSearchParams()

  const onSubmit = async (
    values: Values,
    {setFieldError}: FormikHelpers<Values>
  ) => {
    const params = {
      user: {
        password: values.password,
        password_confirmation: values.passwordConfirmation,
        reset_password_token: search.get("reset_password_token"),
      },
    }
    try {
      const response = await fetchWrapper(resetPasswordPostPath, {
        method: "PUT",
        body: JSON.stringify(params),
      })
      if (response.ok) {
        window.location.replace("/")
      } else {
        toast.error(errorMessage)
      }
    } catch (error: any) {
      const validationErrors = error?.response?.data?.errors
      if (
        validationErrors &&
        (validationErrors["password"] ||
          validationErrors["password_confirmation"])
      ) {
        displayActiveRecordErrors(validationErrors, setFieldError)
      } else {
        const message = error?.response?.data?.error || errorMessage
        toast.error(message)
      }
    }
  }

  return (
    <div>
      <div className="mb-12 flex justify-center">
        <Link to="/">
          <Logo />
        </Link>
      </div>
      <Panel size="sm">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnBlur={false}
          onSubmit={onSubmit}
        >
          {({isSubmitting}: FormikProps<Values>) => (
            <Form>
              <div className="flex flex-col gap-10">
                <H1 center={true} id="reset-password">
                  Reset Your Password
                </H1>
                <div className="flex flex-col gap-2">
                  <FormikField
                    name="password"
                    type="password"
                    label="Create New Password"
                    autoFocus={true}
                  />
                  <FormikField
                    name="passwordConfirmation"
                    type="password"
                    label="Confirm New Password"
                  />
                </div>
                <div className="flex justify-center">
                  <Button type="submit" disabled={isSubmitting} inline={true}>
                    Update Password
                  </Button>
                </div>
                <div className="mb-1 mt-2 flex flex-col items-center justify-between px-6 sm:flex-row sm:gap-5">
                  <Link to={logInPath}>Log In</Link>
                  <Link to={createAccountPath}>Create an account</Link>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Panel>
    </div>
  )
}

export default ResetPasswordForm
