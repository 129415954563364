import {useLocation} from "react-router-dom"
import {accountPath, favoritesPath, playstormsPath} from "~/paths"
import {
  SidebarMenuContainer,
  SidebarMenuItem,
  SidebarMenuItems,
} from "./SidebarMenu"
import ChatIcon from "./icons/chat-icon.svg"
import HomeIcon from "./icons/home-icon.svg"
import StarIcon from "./icons/star-icon.svg"
import UserIcon from "./icons/user-icon.svg"

export const shouldHideMainMenu = (pathname: string) => {
  return (
    (pathname !== playstormsPath && pathname.startsWith("/playstorm")) ||
    pathname.startsWith("/account/questions")
  )
}

const CreativeMenu = ({mobile}: {mobile?: boolean}) => {
  const {pathname} = useLocation()

  if (!mobile && shouldHideMainMenu(pathname)) {
    return null
  }

  return (
    <SidebarMenuContainer>
      <SidebarMenuItems>
        <SidebarMenuItem to={"/"}>
          <HomeIcon />
          Dashboard
        </SidebarMenuItem>
        <SidebarMenuItem to={playstormsPath}>
          <ChatIcon />
          Playstorms
        </SidebarMenuItem>
        <SidebarMenuItem to={favoritesPath}>
          <StarIcon />
          Favorite Ideas
        </SidebarMenuItem>
        <SidebarMenuItem to={accountPath}>
          <UserIcon />
          Account
        </SidebarMenuItem>
      </SidebarMenuItems>
    </SidebarMenuContainer>
  )
}

export default CreativeMenu
