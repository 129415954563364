import {useQuery} from "@apollo/client"
import {getFragmentData, gql} from "~/__generated__"

export const TEMPLATE_FRAGMENT = gql(`
  fragment TemplateFragment on Template {
    id
    title
    description
    duration
    status
    heroImageUrl
    heroImageListItemUrl
    questionsCount
    visibility
    organizationsCount
    organizations {
      id
      name
    }
  }
`)

const GET_TEMPLATE_QUERY = gql(`
  query Template($id: ID!) {
    template(id: $id) {
      ...TemplateFragment
    }
  }
`)

const useTemplateQuery = (id: string) => {
  const {data, loading, error} = useQuery(GET_TEMPLATE_QUERY, {
    variables: {
      id,
    },
  })

  const template = getFragmentData(TEMPLATE_FRAGMENT, data?.template)

  return {template, data, loading, error}
}

export default useTemplateQuery
