import {
  AnswerTypeEnum,
  PlaystormOverviewAnswerFragmentFragment,
} from "~/__generated__/graphql"
import PlaystormOverviewTabContent from "./PlaystormOverviewTabContent"

export const playstormOverviewTranscriptTitle = (answerType: AnswerTypeEnum) =>
  answerType === AnswerTypeEnum.Audio ? "Transcript" : "Original Writing"

const PlaystormOverviewTranscript = ({
  answer,
}: {
  answer: PlaystormOverviewAnswerFragmentFragment
}) => {
  return (
    <PlaystormOverviewTabContent className="whitespace-pre-line px-10 py-8">
      <div>
        <div className="mb-4 text-lg font-semibold">
          {playstormOverviewTranscriptTitle(answer.answerType)}
        </div>
        {answer.answerType === AnswerTypeEnum.Audio
          ? answer.transcription
          : answer.text}
      </div>
    </PlaystormOverviewTabContent>
  )
}

export default PlaystormOverviewTranscript
