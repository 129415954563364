import {Form, Formik, FormikHelpers} from "formik"
import {useNavigate} from "react-router-dom"
import invariant from "tiny-invariant"
import {
  SurveyAnswerFragmentFragment,
  SurveyQuestionFragmentFragment,
  SurveyQuestionTypeEnum,
} from "~/__generated__/graphql"
import useSurveyAnswerUpdate from "~/hooks/useSurveyAnswerUpdate"
import {trackEvent} from "~/util/analytics"
import {displayErrors} from "~/util/validations"
import SurveyAnswerFields, {
  SurveyAnswerFormValues,
  getAnsweredAnswers,
  getNextSurveyQuestionPath,
} from "./SurveyAnswerFields"

const SurveyAnswerUpdateForm = ({
  surveyQuestion,
  surveyAnswers,
  playstormId,
  playstormTemplateId,
}: {
  surveyQuestion: SurveyQuestionFragmentFragment
  surveyAnswers: SurveyAnswerFragmentFragment[]
  playstormId?: string
  playstormTemplateId?: string
}) => {
  const navigate = useNavigate()
  const [mutate] = useSurveyAnswerUpdate()

  const initialAnswers =
    surveyQuestion.surveyQuestionType === SurveyQuestionTypeEnum.Value
      ? surveyQuestion.surveyOptions.nodes.map(surveyOption => {
          const surveyAnswer = surveyAnswers.find(
            sa => sa.surveyOptionId === surveyOption.id
          )
          invariant(surveyAnswer, "Expected surveyAnswer")
          return {
            id: surveyAnswer.id,
            value: surveyAnswer.value || null,
            surveyOptionId: surveyAnswer.surveyOptionId,
            playstormId: null,
          }
        })
      : surveyAnswers.map(surveyAnswer => ({
          id: surveyAnswer.id,
          value: null,
          surveyOptionId: surveyAnswer.surveyOptionId,
          playstormId: null,
        }))

  const initialValues: SurveyAnswerFormValues = {
    answers: initialAnswers,
  }

  const updateAnswers = async (
    values: SurveyAnswerFormValues,
    {setFieldError}: FormikHelpers<SurveyAnswerFormValues>
  ) => {
    try {
      trackEvent("Submit Survey Question Form", {
        position: surveyQuestion.position?.toString() || "",
        question: surveyQuestion.title || "",
        questionId: surveyQuestion.id || "",
        surveyLocation: surveyQuestion.surveyLocation,
        playstormId: playstormId || "",
        playstormTemplateId: playstormTemplateId || "",
        type: "update",
      })
      const answeredAnswers = getAnsweredAnswers(values, surveyQuestion)
      await Promise.all(
        answeredAnswers.map(async surveyAnswer => {
          invariant(surveyAnswer.id, "Expected surveyAnswer.id")
          invariant(surveyAnswer.surveyOptionId, "Expected surveyAnswer.id")
          await mutate({
            variables: {
              input: {
                surveyAnswerInput: {
                  value: surveyAnswer.value,
                  surveyOptionId: surveyAnswer.surveyOptionId,
                },
                id: surveyAnswer.id,
              },
            },
          })
        })
      )
      navigate(getNextSurveyQuestionPath(surveyQuestion))
    } catch (error: any) {
      console.error(error)
      displayErrors(error?.graphQLErrors, setFieldError)
    }
  }

  return (
    <Formik initialValues={initialValues} onSubmit={updateAnswers}>
      <Form>
        <SurveyAnswerFields
          surveyQuestion={surveyQuestion}
          playstormId={playstormId}
          playstormTemplateId={playstormTemplateId}
        />
      </Form>
    </Formik>
  )
}

export default SurveyAnswerUpdateForm
