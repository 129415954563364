import {useField} from "formik"
import {FormikFieldType, OptionType} from "./FormikField"
import SelectField from "./SelectField"

const getDefaultValue = (
  options: OptionType[],
  value: string | Array<string> | undefined,
  isMulti?: boolean
) => {
  if (isMulti && Array.isArray(value)) {
    return options.filter(option => value.includes(option.value))
  }
  return options.find(option => option.value === value)
}

const FormikSelectField: FormikFieldType = ({
  options = [],
  defaultValue,
  beforeChange,
  isMulti,
  light,
  ...props
}) => {
  const [field, _meta, {setValue}] = useField(props)
  return (
    <SelectField
      options={options}
      name={field.name}
      defaultValue={
        defaultValue || getDefaultValue(options, field.value, isMulti)
      }
      value={
        field.value
          ? isMulti
            ? options.filter((option: OptionType) =>
                field.value.includes(option.value)
              )
            : options.find((option: OptionType) => option.value === field.value)
          : undefined
      }
      onChange={(option: OptionType) => {
        if (beforeChange) beforeChange()
        const optionArray = Array.isArray(option) ? option : [option]
        if (isMulti) {
          setValue(optionArray.map(o => o.value))
        } else {
          setValue(optionArray[0].value)
        }
      }}
      onBlur={field.onBlur}
      isMulti={isMulti}
      placeholder=""
      light={light}
      {...props}
    />
  )
}

export default FormikSelectField
