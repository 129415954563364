import clsx from "clsx"
import {JobStatusEnum, MessageFragmentFragment} from "~/__generated__/graphql"
import useMessageUpdatedSubscription from "~/hooks/useMessageUpdatedSubscription"
import {PlaystormOverviewMessageText} from "./CreativePlaystormOverview/PlaystormOverviewText"

const Message = ({
  message,
  editable,
}: {
  message: MessageFragmentFragment
  editable?: boolean
}) => {
  useMessageUpdatedSubscription(message)
  return (
    <div
      key={message.id}
      className={clsx("py-10", "first:pt-0 last:pb-0", {
        "animate-pulse": !message.text,
      })}
    >
      <PlaystormOverviewMessageText
        id={message.id}
        text={message.text || "..."}
        initialText={message.initialText || ""}
        loading={message.jobStatus !== JobStatusEnum.Complete}
        editable={editable}
        backgroundColor="green"
      />
    </div>
  )
}

const StrengtheningMessages = ({
  messages,
  editable,
}: {
  messages: MessageFragmentFragment[]
  editable?: boolean
}) => {
  return (
    <div className="divide-y divide-black/30 whitespace-pre-line">
      {messages.map(message => (
        <Message message={message} key={message.id} editable={editable} />
      ))}
    </div>
  )
}

export default StrengtheningMessages
