// https://gist.github.com/elliott-king/77cf0809c6abae892eb7c911692d87f4

import CryptoJS from "crypto-js"

// Note that for larger files, you may want to hash them incrementally.
// Taken from https://stackoverflow.com/questions/768268/
const md5FromFile = (file: File) => {
  // FileReader is event driven, does not return promise
  // Wrap with promise api so we can call w/ async await
  // https://stackoverflow.com/questions/34495796
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = fileEvent => {
      // @ts-ignore
      const binary = CryptoJS.lib.WordArray.create(fileEvent?.target?.result)
      const md5 = CryptoJS.MD5(binary)
      resolve(md5)
    }
    reader.onerror = () => {
      reject("oops, something went wrong with the file reader.")
    }
    // For some reason, readAsBinaryString(file) does not work correctly,
    // so we will handle it as a word array
    reader.readAsArrayBuffer(file)
  })
}

export const fileChecksum = async (file: File) => {
  const md5 = await md5FromFile(file)
  // @ts-ignore
  const checksum = md5.toString(CryptoJS.enc.Base64)
  return checksum
}
