import {ApolloError, useQuery} from "@apollo/client"
import * as Sentry from "@sentry/react"
import {createContext, useEffect} from "react"
import toast from "react-hot-toast"
import {getFragmentData, gql} from "~/__generated__"
import {ViewerFragmentFragment} from "~/__generated__/graphql"
import {identify} from "~/util/analytics"

export const VIEWER_FRAGMENT = gql(`
  fragment ViewerFragment on User {
    id
    email
    name
    firstName
    lastName
    organization
    organizationRole
    admin
    completedPlaystormsCount
    pendingPlaystormsCount
    hasAnsweredOnboardingSurvey
    createdAt
    structuredOrganization {
      id
      name
    }
  }
`)

const GET_VIEWER = gql(`
  query Viewer {
    viewer {
      ...ViewerFragment
    }
  }
`)

type ViewerContextType = {
  viewer: ViewerFragmentFragment | null | undefined
  loading: boolean
  error: ApolloError | undefined
  refetchViewer: () => void
}

export const ViewerContext = createContext<ViewerContextType>({
  viewer: null,
  loading: true,
  error: undefined,
  refetchViewer: () => {},
})

export const ViewerProvider = ({children}: {children: React.ReactNode}) => {
  const {data, loading, error, refetch} = useQuery(GET_VIEWER, {
    onError: () => {
      toast.error("Failed to fetch user, please reload the page and try again.")
    },
  })

  useEffect(() => {
    if (data?.viewer) {
      const viewer = getFragmentData(VIEWER_FRAGMENT, data?.viewer)
      Sentry.setUser({id: viewer.id})
      identify(viewer)
    } else {
      Sentry.setUser(null)
    }
  }, [data?.viewer])

  const value = {
    viewer: getFragmentData(VIEWER_FRAGMENT, data?.viewer),
    loading,
    error,
    refetchViewer: refetch,
  }

  return (
    <ViewerContext.Provider value={value}>{children}</ViewerContext.Provider>
  )
}
