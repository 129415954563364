import {useField} from "formik"
import {useRef} from "react"
import RadioChecked from "../icons/radio-checked.svg"
import RadioUnhecked from "../icons/radio-unchecked.svg"
import {FormikFieldType} from "./FormikField"

const FormikRadioField: FormikFieldType = ({...props}) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [field] = useField(props)
  return (
    <div className="flex items-center justify-center">
      {field.checked ? <RadioChecked /> : <RadioUnhecked />}
      <input {...field} {...props} className="hidden" ref={inputRef} />
    </div>
  )
}

export default FormikRadioField
