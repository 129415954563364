import {Helmet} from "react-helmet"
import TemplateScreenContent from "~/components/TemplateScreenContent"
import Container from "~/components/ui/Container"
import {H1} from "~/components/ui/Headings"

const TITLE = "Create New Playstorm Template"

const AdminNewTemplateScreen = () => {
  return (
    <Container size="full" centered={false}>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <div className="my-6">
        <H1>{TITLE}</H1>
      </div>
      <TemplateScreenContent />
    </Container>
  )
}

export default AdminNewTemplateScreen
