import {
  defaultDataIdFromObject,
  useApolloClient,
  useSubscription,
} from "@apollo/client"
import toast from "react-hot-toast"
import invariant from "tiny-invariant"
import {gql} from "~/__generated__"
import {JobStatusEnum, MessageFragmentFragment} from "~/__generated__/graphql"
import {isJobComplete} from "~/util/jobs"

export const MESSAGE_FRAGMENT = gql(`
  fragment MessageFragment on Message {
    id
    text
    initialText
    jobStatus
    ttsJobStatus
    answerId
    messageType
  }
`)

export const MESSAGE_UPDATED_FRAGMENT = gql(`
  fragment MessageUpdatedFragment on Message {
    text
    jobStatus
  }
`)

export const MESSAGE_UPDATED_SUBSCRIPTION = gql(`
  subscription MessageUpdated($messageId: ID!) {
    messageUpdated(messageId: $messageId) {
      message {
        id
        jobStatus
        text
      }
    }
  }
`)

const useMessageUpdatedSubscription = (message: MessageFragmentFragment) => {
  const client = useApolloClient()

  const {data, loading, error} = useSubscription(MESSAGE_UPDATED_SUBSCRIPTION, {
    variables: {messageId: message.id!},
    skip: !message.id || isJobComplete(message.jobStatus),
    onData: ({data: {data}}) => {
      const updatedMessage = data?.messageUpdated.message
      invariant(updatedMessage, "expected message to exist")
      client.cache.writeFragment({
        id: defaultDataIdFromObject(message),
        fragment: MESSAGE_UPDATED_FRAGMENT,
        data: {
          jobStatus: updatedMessage.jobStatus,
          text: updatedMessage.text,
        },
      })
      if (updatedMessage.jobStatus === JobStatusEnum.Error) {
        toast.error("Reponse failed, please try again")
      }
    },
    onError: e => {
      console.error(e)
      toast.error("Reponse failed, please try again")
    },
  })

  return {data, loading, error}
}

export default useMessageUpdatedSubscription
