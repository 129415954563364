import {useQuery} from "@apollo/client"
import {getFragmentData, gql} from "~/__generated__"

export const SURVEY_ANSWER_FRAGMENT = gql(`
  fragment SurveyAnswerFragment on SurveyAnswer {
    id
    value
    surveyOptionId
    surveyQuestionId
  }
`)

const GET_SURVEY_ANSWERS_QUERY = gql(`
  query SurveyAnswers($surveyQuestionId: ID!, $playstormId: ID) {
    surveyAnswers(surveyQuestionId: $surveyQuestionId, playstormId: $playstormId, first: 500) {
      nodes {
        ...SurveyAnswerFragment
      }
    }
  }
`)

const useSurveyAnswersQuery = (
  surveyQuestionId: string,
  playstormId?: string
) => {
  const {data, loading, error} = useQuery(GET_SURVEY_ANSWERS_QUERY, {
    variables: {
      surveyQuestionId,
      playstormId,
    },
  })

  const surveyAnswers =
    data?.surveyAnswers?.nodes.map(node =>
      getFragmentData(SURVEY_ANSWER_FRAGMENT, node)
    ) || []

  return {
    surveyAnswers,
    data,
    loading,
    error,
  }
}

export default useSurveyAnswersQuery
