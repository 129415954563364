import {useQuery} from "@apollo/client"
import {Helmet} from "react-helmet"
import {useParams} from "react-router-dom"
import invariant from "tiny-invariant"
import {gql} from "~/__generated__"
import {OrganizationScreenContent} from "~/components/OrganizationForms"
import Container from "~/components/ui/Container"
import Error from "~/components/ui/Error"
import {H1} from "~/components/ui/Headings"
import Loading from "~/components/ui/Loading"

const TITLE = "Organization Details"

const query = gql(/* GraphQL */ `
  query AdminOrganizationScreenQuery($id: ID!) {
    organization(id: $id) {
      id
      ...OrganizationForm
    }
  }
`)

const AdminOrganizationScreen = () => {
  const params = useParams<{id: string}>()
  invariant(params.id, "Organization id is required")

  const result = useQuery(query, {variables: {id: params.id}})
  if (result.loading) return <Loading />
  if (result.error) return <Error message="Error loading organization" />

  invariant(result.data?.organization, "Organization not found")

  return (
    <Container size="full" centered={false}>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <div className="my-6">
        <H1>{TITLE}</H1>
      </div>
      <OrganizationScreenContent
        organization={result.data.organization}
        onRefresh={result.refetch}
      />
    </Container>
  )
}

export default AdminOrganizationScreen
