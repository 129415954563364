import {Helmet} from "react-helmet"
import AdminPlaystormsTable from "~/components/AdminPlaystormsTable"
import ScreenTitle from "~/components/ScreenTitle"
import Container from "~/components/ui/Container"
import Error from "~/components/ui/Error"
import Loading from "~/components/ui/Loading"
import useAdminPlaystormsQuery from "~/hooks/useAdminPlaystormsQuery"

const TITLE = "Playstorms"

const AdminPlaystormsScreen = () => {
  const {playstorms, loading, error, hasNextPage, fetchNextPage} =
    useAdminPlaystormsQuery({})

  if (loading && playstorms.length === 0) return <Loading />
  if (error || playstorms.length === 0)
    return <Error message="Error loading playstorms" />

  return (
    <Container size="full" centered={false}>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <ScreenTitle>{TITLE}</ScreenTitle>
      <AdminPlaystormsTable
        playstorms={playstorms}
        hasNextPage={hasNextPage!!}
        fetchNextPage={fetchNextPage}
        loading={loading}
      />
    </Container>
  )
}

export default AdminPlaystormsScreen
