import CreativeAnswerFavoritesList from "~/components/CreativeAnswerFavoritesList"
import ScreenTitle from "~/components/ScreenTitle"
import Container from "~/components/ui/Container"
import Error from "~/components/ui/Error"
import Loading from "~/components/ui/Loading"
import useAnswerFavoritesQuery from "~/hooks/useAnswerFavoritesQuery"
import useTrackPage from "~/hooks/useTrackPage"
import background from "~/images/backgrounds/creative-favorites-bg.jpg"

const CreativeFavoritesScreen = () => {
  useTrackPage("Creative Favorites")

  const {answerFavorites, loading, error, hasNextPage, fetchNextPage} =
    useAnswerFavoritesQuery()

  if (loading) return <Loading />
  if (error) return <Error message="Error loading dashboard" />

  return (
    <div className="flex flex-1 flex-col">
      <Container size="full" centered={false}>
        <ScreenTitle>Favorite Ideas</ScreenTitle>
        <div className="flex flex-col gap-4">
          <CreativeAnswerFavoritesList
            answerFavorites={answerFavorites}
            fetchNextPage={fetchNextPage}
            hasNextPage={hasNextPage!!}
            loading={loading}
          />
        </div>
      </Container>
      <div className="mt-auto pt-[105px]">
        <img src={background} alt="Background" className="h-auto w-full" />
      </div>
    </div>
  )
}

export default CreativeFavoritesScreen
