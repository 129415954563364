import {useQuery} from "@apollo/client"
import {getFragmentData, gql} from "~/__generated__"

export const PLAYSTORM_FRAGMENT = gql(`
  fragment PlaystormFragment on Playstorm {
    id
    title
    answerCount
    isOwner
    templateId
  }
`)

const GET_PLAYSTORM_QUERY = gql(`
  query Playstorm($id: ID!) {
    playstorm(id: $id) {
      ...PlaystormFragment
    }
  }
`)

const usePlaystormQuery = (id: string) => {
  const {data, loading, error} = useQuery(GET_PLAYSTORM_QUERY, {
    variables: {
      id,
    },
  })

  const playstorm = getFragmentData(PLAYSTORM_FRAGMENT, data?.playstorm)

  return {playstorm, data, loading, error}
}

export default usePlaystormQuery
