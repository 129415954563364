import clsx from "clsx"
import {Form, useFormikContext} from "formik"
import {useEffect, useLayoutEffect, useRef} from "react"
import invariant from "tiny-invariant"
import * as Yup from "yup"
import {getFragmentData} from "~/__generated__"
import {
  AnswerTypeEnum,
  PlaystormStepAnswerFragmentFragment,
  PlaystormStepQuestionFragmentFragment,
} from "~/__generated__/graphql"
import {PLAYSTORM_STEP_QUESTION_FRAGMENT} from "~/hooks/usePlaystormStepQuery"
import useResetKey from "~/hooks/useResetKey"
import {formatMsDuration} from "~/util/dates"
import Soundwave from "../images/soundwave.mp4"
import AnswerRecorder from "./AnswerRecorder"
import AnswerTextControls from "./AnswerTextControls"
import PlaystormProgress from "./PlaystormProgress"
import QuestionBubble from "./QuestionBubble"
import {RecordingMachineProvider, useRecordingMachine} from "./RecordingMachine"
import Container from "./ui/Container"
import FieldGroup from "./ui/FieldGroup"
import FormikField from "./ui/FormikField"

export enum AnswerType {
  Text,
  Audio,
}

export type AnswerFormValues = {
  text: string
  transcriptionSummary?: string
  answerType: AnswerTypeEnum
  recording: string
  recordingBlob?: Blob
  duration: number
}

const MIN_AUDIO_DURATION = 1000 * 5
export const MAX_AUDIO_DURATION = 1000 * 60 * 30 // 30 minutes

export const answerValidationSchema = Yup.object({
  text: Yup.string().when("$answerType", ([other], schema) =>
    other === AnswerTypeEnum.Text
      ? schema
          .required("An answer is required")
          .min(20, "Answer must be at least 20 characters")
      : schema
  ),
  duration: Yup.number().when("$answerType", ([other], schema) =>
    other === AnswerTypeEnum.Audio
      ? schema
          .min(MIN_AUDIO_DURATION, "Answer must be at least 5 seconds")
          .max(MAX_AUDIO_DURATION)
      : schema
  ),
})

const Content = ({
  answer,
  question,
}: {
  answer: PlaystormStepAnswerFragmentFragment
  question: PlaystormStepQuestionFragmentFragment
}) => {
  const {machine} = useRecordingMachine()
  const [machineState, send] = machine
  const {values, setFieldValue, errors} = useFormikContext<AnswerFormValues>()
  const {answerType} = values
  const videoRef = useRef<HTMLVideoElement | null>(null)

  useLayoutEffect(() => {
    if (machineState.matches("recording.started")) {
      videoRef?.current?.play()
    } else {
      videoRef?.current?.pause()
    }
  }, [machineState])

  useEffect(() => {
    if (Object.keys(errors).length > 0 && machineState.matches("submitted")) {
      send({type: "ERROR"})
    }
  }, [errors, machineState, send])

  const toggleAnswerType = () =>
    setFieldValue(
      "answerType",
      answerType === AnswerTypeEnum.Text
        ? AnswerTypeEnum.Audio
        : AnswerTypeEnum.Text
    )

  return (
    <Form>
      <Container size="sm" className="!p-0">
        <div className="-mt-2 w-full">
          <QuestionBubble text={question.text} />
          {answerType === AnswerTypeEnum.Audio && (
            <div
              className={clsx(
                "relative -mb-4 -mt-10 flex h-[100px] items-center justify-center",
                {
                  "opacity-0":
                    !machineState.matches("recording.started") &&
                    values.duration === 0,
                }
              )}
            >
              <video
                loop={true}
                src={Soundwave}
                width="250"
                ref={videoRef}
                className="mix-blend-multiply"
                playsInline={true}
              />
              <div
                className={clsx(
                  "absolute right-0 text-2xs font-semibold",
                  "sm:right-9"
                )}
              >
                {formatMsDuration(values.duration)}
              </div>
            </div>
          )}
        </div>
        <div className="z-10">
          {answerType === AnswerTypeEnum.Text && (
            <FieldGroup>
              <FormikField
                name="text"
                autoFocus={true}
                as="textarea"
                rows={5}
                inputClassName="rounded-2xl p-6 !bg-brand-blue/20"
              />
            </FieldGroup>
          )}

          <div className="text-center">
            <FormikField
              name="duration"
              value={values.duration}
              inputClassName="hidden"
            />
          </div>

          <div
            className={clsx({
              hidden: answerType !== AnswerTypeEnum.Audio,
            })}
          >
            {Object.keys(machine).length > 0 && (
              <AnswerRecorder
                answer={answer}
                toggleAnswerType={toggleAnswerType}
                machine={machine}
              />
            )}
          </div>
          {answerType === AnswerTypeEnum.Text && (
            <AnswerTextControls
              answer={answer}
              toggleAnswerType={toggleAnswerType}
            />
          )}
          <div className="mt-10 flex w-full justify-center">
            <PlaystormProgress position={question.position} />
          </div>
        </div>
      </Container>
    </Form>
  )
}

const AnswerFields = ({
  answer,
}: {
  answer: PlaystormStepAnswerFragmentFragment
}) => {
  const questionAudioRef = useRef<HTMLAudioElement>(null)

  // Resets the recording machine. Using a key is easier than resetting useWhisper
  const {resetKey, reset} = useResetKey()

  const stopAudio = () => {
    questionAudioRef?.current?.pause()
  }

  const question = getFragmentData(
    PLAYSTORM_STEP_QUESTION_FRAGMENT,
    answer.question
  )
  invariant(question)

  return (
    <div>
      {question?.audioUrl && (
        <audio src={question.audioUrl} autoPlay={true} ref={questionAudioRef} />
      )}
      <div key={resetKey}>
        <RecordingMachineProvider stopAudio={stopAudio} reset={reset}>
          <Content answer={answer} question={question} />
        </RecordingMachineProvider>
      </div>
    </div>
  )
}

export default AnswerFields
